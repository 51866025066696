/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { Modal } from "react-bootstrap";
import { useSelector } from "react-redux";

import { UPLOAD_API_URL } from "../../constants";

const ImagePreview = (props) => {
  const { handleImagePreviewClose } = props;
  const { imagePreviewOpen, imagePreviewData } = useSelector(
    (state) => state.chat,
  );

  return (
    <>
      <Modal
        show={imagePreviewOpen}
        backdrop="static"
        size="xl"
        className="img-preview"
      >
        <Modal.Header>
          <Modal.Title></Modal.Title>
          <a onClick={handleImagePreviewClose} className="icon_container">
            <svg width="13" viewBox="6.29 6.34 11.31 11.31">
              <path
                fill="#425b76"
                d="m16.192 6.344-4.243 4.242-4.242-4.242-1.414 1.414L10.535 12l-4.242 4.242 1.414 1.414 4.242-4.242 4.243 4.242 1.414-1.414L13.364 12l4.242-4.242z"
              ></path>
            </svg>
          </a>
        </Modal.Header>
        <Modal.Body className="d-flex align-items-center justify-content-center">
          <div className="row height-100 d-flex align-items-center justify-content-center">
            <img
              className="max-height-100"
              src={`${UPLOAD_API_URL}chat/download?id=${imagePreviewData.attachmentId}&filename=${imagePreviewData.attachmentName}`}
              alt="logo"
            />
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ImagePreview;
