/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { memo, useState } from "react";
import { useEffect } from "react";
import { Modal, Button } from "react-bootstrap";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import swal from "sweetalert";
import { toast } from "react-toastify";

import { callSoundDetail } from "../util/sounds";
import { chatSoundDetail } from "../util/sounds";
import {
  getAuthSession,
  saveCamera,
  setSpeakerHandler,
  showMic,
  toDataURL,
} from "../Helpers/common";
import { _userUpdate } from "../../SocketIo";
import { DropdownIndicator } from "../Helpers/common";
import { CALL_RECORDINGS_LINK, SCHEDULED_CALLS_LINK } from "../../constants";
import { globalStrings } from "../util/translation/languages";
import { clearUnreadCountHandler } from "../Helpers/chatCommon";
import { alertMsg } from "../util/alertMsg";
import VideoInputPreview from "./videoInputPreview";
import {
  createAudioTracks,
  createVideoTracks,
  localVideoTrackAdd,
  replaceLocalAudioTrack,
  replaceLocalVideoTrack,
  speakerDeviceChange,
  toggleBackgroundEffect,
} from "../Helpers/Lib";
import {
  addLocalAudioTrack,
  setHasVideoPermission,
  setVirtualBackgroundOptions,
} from "../Helpers/Lib/libSlice";
import VirtualBackground from "./virtualBackground";

const UserSettingModal = (props) => {
  const { show, handleClose, selectedLanguage } = props;
  const { allAttendeeList } = useSelector((state) => state.call);
  const { authSessionUser } = useSelector((state) => state.authUserDetail);
  const {
    microphoneDevice,
    cameraDevice,
    speakerDevice,
    microphoneDeviceList,
    cameraDeviceList,
    speakerDeviceList,
    hasAudioPermission,
    hasVideoPermission,
    virtualBackgroundOptions,
    localVideoTrack,
    localAudioTrack,
  } = useSelector((state) => state.lib);

  const { user_unique_key } = getAuthSession();
  const [callSoundOptions, setCallSoundOptions] = useState([]);
  const [chatSoundOptions, setChatSoundOptions] = useState([]);
  const [callSound, setCallSound] = useState("");
  const [chatSound, setChatSound] = useState("");
  const [callPlayDisable, setCallPlayDisable] = useState(false);
  const [chatPlayDisable, setChatPlayDisable] = useState(false);
  const [mic, setMic] = useState("");
  const [speaker, setSpeaker] = useState("");
  const [camera, setCamera] = useState("");
  const [camerFailed, setCamerFailed] = useState(false);
  const [videoPreviewTracks, setVideoPreviewTracks] = useState(null);
  const [videoPreviewLoader, setVideoPreviewLoader] = useState(true);

  const isElectron = navigator.userAgent.includes("Electron");

  const dispatch = useDispatch();

  useEffect(() => {
    if (Object.keys(virtualBackgroundOptions).length > 0) {
      toggleBackgroundEffect(
        virtualBackgroundOptions,
        videoPreviewTracks?.track,
      );
    }
  }, [virtualBackgroundOptions, videoPreviewTracks]);

  useEffect(() => {
    if (!hasAudioPermission) {
      setMic({
        label: "Permission not granted",
        value: "Permission not granted",
      });
      setSpeaker({
        label: "Default",
        value: "Default",
      });
    } else {
      if (speakerDeviceList.length === 0) {
        setSpeaker({
          label: "Default",
          value: "Default",
        });
      }
    }
  }, []);

  // useEffect(() => {
  //   if(){

  //   }
  // }, [camera]);

  useEffect(() => {
    if (hasAudioPermission) {
      if (microphoneDeviceList.length > 0) {
        if (microphoneDevice == "" || microphoneDevice === undefined) {
          const microphoneDeviceId = microphoneDeviceList
            ? microphoneDeviceList[0].deviceId
            : "";
          setMic(microphoneDeviceList[0]);
          showMic(microphoneDeviceId);
        } else {
          const selectedMicrophoneDetail = microphoneDeviceList.find(
            (findItem) => findItem.deviceId === microphoneDevice,
          );
          setMic({
            ...selectedMicrophoneDetail,
            value: selectedMicrophoneDetail?.deviceId,
          });
        }
      }
      if (speakerDeviceList.length > 0) {
        if (speakerDevice == "" || speakerDevice === undefined) {
          const speakerDeviceId = speakerDeviceList
            ? speakerDeviceList[0].deviceId
            : "";
          setSpeaker(speakerDeviceList[0]);
          setSpeakerHandler(speakerDeviceId);
        } else {
          const selectedSpeakerDetail = speakerDeviceList.find(
            (findItem) => findItem.deviceId === speakerDevice,
          );

          setSpeaker({
            ...selectedSpeakerDetail,
            value: selectedSpeakerDetail?.deviceId,
          });
        }
      }

      if (cameraDeviceList.length > 0) {
        if (cameraDevice == "" || cameraDevice === undefined) {
          const cameraDeviceId = cameraDeviceList
            ? cameraDeviceList[0].deviceId
            : "";
          setCamera(cameraDeviceList[0]);
          saveCamera(cameraDeviceId);
          setDefaultVideo(cameraDeviceList[0]);
        } else {
          const selectedCameraDetail = cameraDeviceList.find(
            (findItem) => findItem.deviceId === cameraDevice,
          );
          setCamera({
            ...selectedCameraDetail,
            value: selectedCameraDetail?.deviceId,
          });
          setDefaultVideo(selectedCameraDetail);
        }
      }
    }
  }, []);

  useEffect(() => {
    if (!hasVideoPermission) {
      setCamera({
        label: "Permission not granted",
        value: "Permission not granted",
      });
    }
  }, []);

  useEffect(() => {
    setChatSoundOptions(chatSoundDetail);
    setCallSoundOptions(callSoundDetail);
  }, []);

  const initialStateLoad = () => {
    let loggedInUser = allAttendeeList.find(
      (obj) => obj.user_id === user_unique_key,
    );
    // set call sound data
    if (loggedInUser?.call_sound !== undefined) {
      const callSoundData = callSoundOptions.filter(
        (filterItem) => filterItem.name === loggedInUser?.call_sound,
      );
      if (callSoundData.length > 0) {
        setCallSound(callSoundData[0]);
      } else {
        setCallSound(callSoundOptions[0]);
      }
    } else {
      setCallSound(callSoundOptions[0]);
    }

    // set chat sound data
    if (loggedInUser?.chat_sound !== undefined) {
      const chatSoundData = chatSoundOptions.filter(
        (filterItem) => filterItem.name === loggedInUser?.chat_sound,
      );
      if (chatSoundData.length > 0) {
        setChatSound(chatSoundData[0]);
      } else {
        setChatSound(chatSoundOptions[0]);
      }
    } else {
      setChatSound(chatSoundOptions[0]);
    }
  };

  const saveSettingHandler = () => {
    let loggedInUser = allAttendeeList.find(
      (obj) => obj.user_id === user_unique_key,
    );
    const userData = { ...loggedInUser };
    userData["call_sound"] = callSound.name;
    userData["chat_sound"] = chatSound.name;
    _userUpdate(userData);
    handleClose();
  };

  const handleChangeCallSound = (option) => {
    setCallSound(option);
  };

  const handleChangeChatSound = (option) => {
    setChatSound(option);
  };

  // av settings
  const handleChangeMicrophone = (option) => {
    setMic(option);
  };

  const handleChangeSpeaker = (option) => {
    setSpeaker(option);
  };

  // const handleChangeCamera = (option) => {
  //   console.log("handleChangeCamera option", option);
  //   setCamera(option);
  // };

  const handleChangeCamera = (option) => {
    setVideoPreviewLoader(true);
    setCamera(option);
    setDefaultVideo(option);
  };

  const setDefaultVideo = (device) => {
    disposeLocalVideoTrack().then(() => {
      createVideoTracks(device?.deviceId)
        .then((track) => {
          setVideoPreviewTracks({ track: track[0], device: device });
          setVideoPreviewLoader(false);
          const videodevice = cameraDeviceList.filter((device) => {
            return device.deviceId === track[0].deviceId;
          });
          // dispatch(setCameraDevice(track[0].deviceId));
          // dispatch(sethasVideoEnable(true));
          setCamera(videodevice[0]);
          setCamerFailed(false);
          dispatch(setHasVideoPermission(true));
        })
        .catch((error) => {
          if (error.name === "gum.not_found") {
            dispatch(setHasVideoPermission(true));
          }
          setCamerFailed(true);

          setCamera(device);
          setVideoPreviewTracks(null);
          setVideoPreviewLoader(false);
          // dispatch(setCameraDevice(null));
          // dispatch(sethasVideoEnable(false));
        });
    });
  };

  const disposeLocalVideoTrack = () => {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve) => {
      if (videoPreviewTracks) {
        await videoPreviewTracks.track.dispose();
        setVideoPreviewTracks(null);
        resolve();
      } else {
        resolve();
      }
    });
  };

  const playSound = (type) => {
    type === "call" ? setCallPlayDisable(true) : setChatPlayDisable(true);

    const audio = new Audio(
      type === "call" ? callSound?.value : chatSound?.value,
    );
    if (audio) {
      audio?.play();
    }
    setTimeout(() => {
      type === "call" ? setCallPlayDisable(false) : setChatPlayDisable(false);
    }, 900);
  };

  const customStyles = {
    option: (base, state) => ({
      ...base,
      backgroundColor: state.isSelected ? "#425b76" : "inherit",
      "&:hover": {
        backgroundColor: state.isSelected ? "#425b76" : "rgb(222, 235, 255)",
      },
    }),
    singleValue: (provided, state) => {
      const opacity = state.isDisabled ? 0.5 : 1;
      const transition = "opacity 300ms";

      return { ...provided, opacity, transition };
    },
  };

  const ringtoneSettingHandler = () => {
    initialStateLoad();
  };

  const saveAvSetting = () => {
    handleClose();
    hasAudioPermission && mic !== "" && showMic(mic?.deviceId);
    hasAudioPermission &&
      speaker !== "" &&
      setSpeakerHandler(speaker?.deviceId);

    hasVideoPermission && camera != "" && saveCamera(camera?.deviceId);
    if (authSessionUser?.onCall) {
      if (videoPreviewTracks?.track) {
        const oldTrack = localVideoTrack;
        const trackData = {
          track: videoPreviewTracks.track,
          device: camera,
        };
        console.log("localVideoTrack sainineha ==>", localVideoTrack);
        console.log("trackData sainineha ==>", trackData);
        replaceLocalVideoTrack(oldTrack?.jitsiTrack, trackData.track).then(
          () => {
            localVideoTrackAdd(trackData);
          },
        );
      }

      if (
        hasAudioPermission &&
        mic !== "" &&
        microphoneDevice !== mic?.deviceId
      ) {
        createAudioTracks(mic?.deviceId).then((track) => {
          console.log("localAudioTrack sainineha ==>", localAudioTrack);
          const oldTrack = localAudioTrack;
          const newTrack = track[0];
          const trackData = {
            track: newTrack,
            device: mic?.deviceId,
          };
          replaceLocalAudioTrack(oldTrack?.jitsiTrack, trackData.track).then(
            () => {
              dispatch(addLocalAudioTrack(trackData));
            },
          );
        });
      }

      if (
        hasAudioPermission &&
        speaker !== "" &&
        speakerDevice !== speaker?.deviceId
      ) {
        speakerDeviceChange(speaker);
      }
    }
  };

  const scheduleCallList = () => {
    handleClose();
    window.open(SCHEDULED_CALLS_LINK, "_blank", "noreferrer");
  };

  const callRecordingList = () => {
    handleClose();
    window.open(CALL_RECORDINGS_LINK, "_blank", "noreferrer");
  };

  const handleClearCount = async () => {
    swal({
      text: alertMsg.clearCount,
      dangerMode: true,
      buttons: ["No", "Yes"],
      closeOnClickOutside: false,
    }).then((willDelete) => {
      toast.dismiss();
      if (willDelete) {
        clearUnreadCountHandler();
      }
    });
  };

  const virtualBackgroundEnabled = (e) => {
    if (e.target.checked) {
      const data = {
        src: "https://s3.us-west-1.wasabisys.com/c2perform/virtualBackgroundImg-1.png",
        uuid: "b740118e-cb4f-4b8d-8fda-ec27764761e3",
      };
      applyVirtualBackground(data);
    } else {
      removeBackground();
    }
  };

  const applyVirtualBackground = (img) => {
    toDataURL(img.src)
      .then((src) => {
        const options = {
          backgroundEffectEnabled: true,
          selectedThumbnail: img.uuid,
          backgroundType: "image",
          blurValue: null,
          virtualSource: src,
        };
        updateVirtualOption(options);
      })
      .catch((err) => {
        console.error("VirtualBackground Fetch image error.", err);
      });
  };

  const updateVirtualOption = (options) => {
    dispatch(setVirtualBackgroundOptions(options));
    const updatedAuthSessionUser = { ...authSessionUser };
    updatedAuthSessionUser["virtual_background"] = options;
    _userUpdate(updatedAuthSessionUser);
  };

  const handleBlurEffect = async (e) => {
    if (e.target.checked) {
      const blurValue = e.target.name === "isFullBlurEnabled" ? 8 : 4;
      enableBlur(blurValue, "blur");
    } else {
      removeBackground();
    }
  };

  const enableBlur = async (blurValue) => {
    const options = {
      backgroundEffectEnabled: true,
      selectedThumbnail: "slight-blur",
      backgroundType: "blur",
      blurValue: blurValue,
      virtualSource: null,
    };
    updateVirtualOption(options);
  };

  const removeBackground = async () => {
    const options = {
      backgroundEffectEnabled: false,
      selectedThumbnail: "none",
      backgroundType: null,
      blurValue: null,
      virtualSource: null,
    };
    updateVirtualOption(options);
  };

  const isVirtualBackgroundEnabled =
    Object.keys(virtualBackgroundOptions).length > 0
      ? virtualBackgroundOptions.backgroundEffectEnabled &&
        virtualBackgroundOptions.selectedThumbnail !== "slight-blur"
        ? true
        : false
      : false;

  const isFullBlurEnabled =
    Object.keys(virtualBackgroundOptions).length > 0
      ? virtualBackgroundOptions.backgroundEffectEnabled &&
        virtualBackgroundOptions.selectedThumbnail == "slight-blur" &&
        virtualBackgroundOptions.blurValue === 8
        ? true
        : false
      : false;

  const isPartialBlurEnabled =
    Object.keys(virtualBackgroundOptions).length > 0
      ? virtualBackgroundOptions.backgroundEffectEnabled &&
        virtualBackgroundOptions.selectedThumbnail == "slight-blur" &&
        virtualBackgroundOptions.blurValue === 4
        ? true
        : false
      : false;
  return (
    <>
      <Modal show={show} onHide={handleClose} className="user-setting">
        <Modal.Header>
          <a onClick={handleClose} className="icon-close top-10">
            <svg
              width="15.556"
              height="15.556"
              viewBox="2240.448 1762.444 15.556 15.556"
            >
              <path
                d="m2250.727 1766.055-2.5 2.5-2.5-2.5a.786.786 0 0 0-1.112 0l-.555.556a.786.786 0 0 0 0 1.11l2.5 2.5-2.5 2.501a.786.786 0 0 0 0 1.111l.555.556a.786.786 0 0 0 1.111 0l2.5-2.5 2.5 2.5a.786.786 0 0 0 1.112 0l.555-.556a.786.786 0 0 0 0-1.11l-2.5-2.501 2.5-2.5a.786.786 0 0 0 0-1.111l-.555-.556a.786.786 0 0 0-1.111 0Z"
                fill="#2d343f"
              />
            </svg>
          </a>
        </Modal.Header>
        <Modal.Body>
          {
            <>
              <div className="nav-tabs-column">
                <ul
                  className="nav nav-tabs flex-column"
                  id="userSettingTab"
                  role="tablist"
                >
                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link active d-flex align-items-center"
                      id="av-tab"
                      data-toggle="tab"
                      data-target="#avTab"
                      type="button"
                      role="tab"
                      aria-controls="av"
                      aria-selected="true"
                    >
                      <span className="nav-tabs-column-img-container d-inline-flex align-items-center">
                        <svg
                          width="13.077"
                          height="12.867"
                          viewBox="1759.35 1814.501 13.077 8.818"
                        >
                          <path
                            d="M1761.895 1823.319a2.549 2.549 0 0 1-2.545-2.546v-3.727a2.548 2.548 0 0 1 2.545-2.545h5.325c1.314 0 2.398 1 2.531 2.279l.988-.995c.2-.151.428-.232.661-.232a1.03 1.03 0 0 1 1.027 1.035v4.613c0 .396-.219.75-.572.927a1.032 1.032 0 0 1-1.086-.097l-.016-.014-1-.996a2.549 2.549 0 0 1-2.533 2.298h-5.325Zm-1.712-6.273v3.727c0 .944.768 1.712 1.712 1.712h5.325c.945 0 1.713-.768 1.713-1.712v-3.727c0-.945-.768-1.714-1.713-1.714h-5.325c-.944 0-1.712.769-1.712 1.714Zm11.093-.627-1.511 1.528v1.91l1.536 1.535a.164.164 0 0 0 .078.017.23.23 0 0 0 .102-.025.198.198 0 0 0 .114-.183v-4.628a.197.197 0 0 0-.113-.168.203.203 0 0 0-.206.013Z"
                            fill="#2d343f"
                          />
                        </svg>
                      </span>
                      {globalStrings[selectedLanguage]?.$audioVideo}
                    </button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link d-flex align-items-center"
                      id="ringtone-tab"
                      data-toggle="tab"
                      data-target="#ringtoneTab"
                      type="button"
                      role="tab"
                      aria-controls="ringtone"
                      aria-selected="false"
                      onClick={() => ringtoneSettingHandler()}
                    >
                      <span className="nav-tabs-column-img-container d-inline-flex align-items-center">
                        <svg
                          width="10.087"
                          height="9.935"
                          viewBox="1760.624 1844.18 10.087 9.935"
                        >
                          <g data-name="volume (1)">
                            <path
                              d="M1769.37 1845.906a.42.42 0 0 0-.594.595 3.747 3.747 0 0 1 0 5.293.42.42 0 1 0 .595.595 4.589 4.589 0 0 0 0-6.483Z"
                              fill="#2d343f"
                            />
                            <path
                              d="M1768.233 1847.168a.421.421 0 1 0-.597.595 1.96 1.96 0 0 1 0 2.77.421.421 0 1 0 .597.594 2.802 2.802 0 0 0 0-3.959Z"
                              fill="#2d343f"
                            />
                            <path
                              d="M1766.434 1844.187a5.067 5.067 0 0 0-3.176 2.018h-.532c-1.16.002-2.1.942-2.102 2.102v1.681c.002 1.16.942 2.1 2.102 2.102h.532a5.07 5.07 0 0 0 3.176 2.018.42.42 0 0 0 .496-.413v-9.093a.42.42 0 0 0-.496-.415Zm-.345 8.969a4.239 4.239 0 0 1-2.258-1.715.42.42 0 0 0-.352-.191h-.753a1.261 1.261 0 0 1-1.261-1.262v-1.681c0-.697.564-1.261 1.26-1.261h.758a.42.42 0 0 0 .351-.19 4.237 4.237 0 0 1 2.255-1.717v8.017Z"
                              fill="#2d343f"
                            />
                          </g>
                        </svg>
                      </span>
                      {globalStrings[selectedLanguage]?.$ringtone}
                    </button>
                  </li>
                  {isElectron && (
                    <li className="nav-item" role="presentation">
                      <button
                        className="nav-link d-flex align-items-center"
                        id="count-tab"
                        data-toggle="tab"
                        data-target="#countTab"
                        type="button"
                        role="tab"
                        aria-controls="count"
                        aria-selected="false"
                      >
                        <span className="nav-tabs-column-img-container d-inline-flex align-items-center">
                          <svg
                            width="10.087"
                            height="9.935"
                            viewBox="1760.624 1844.18 10.087 9.935"
                          >
                            <g data-name="volume (1)">
                              <path
                                d="M1769.37 1845.906a.42.42 0 0 0-.594.595 3.747 3.747 0 0 1 0 5.293.42.42 0 1 0 .595.595 4.589 4.589 0 0 0 0-6.483Z"
                                fill="#2d343f"
                              />
                              <path
                                d="M1768.233 1847.168a.421.421 0 1 0-.597.595 1.96 1.96 0 0 1 0 2.77.421.421 0 1 0 .597.594 2.802 2.802 0 0 0 0-3.959Z"
                                fill="#2d343f"
                              />
                              <path
                                d="M1766.434 1844.187a5.067 5.067 0 0 0-3.176 2.018h-.532c-1.16.002-2.1.942-2.102 2.102v1.681c.002 1.16.942 2.1 2.102 2.102h.532a5.07 5.07 0 0 0 3.176 2.018.42.42 0 0 0 .496-.413v-9.093a.42.42 0 0 0-.496-.415Zm-.345 8.969a4.239 4.239 0 0 1-2.258-1.715.42.42 0 0 0-.352-.191h-.753a1.261 1.261 0 0 1-1.261-1.262v-1.681c0-.697.564-1.261 1.26-1.261h.758a.42.42 0 0 0 .351-.19 4.237 4.237 0 0 1 2.255-1.717v8.017Z"
                                fill="#2d343f"
                              />
                            </g>
                          </svg>
                        </span>
                        {globalStrings[selectedLanguage]?.$desktopAlert}
                      </button>
                    </li>
                  )}
                  {authSessionUser.role !== "Agent" && (
                    <>
                      {SCHEDULED_CALLS_LINK && (
                        <li className="nav-item" role="presentation">
                          <button
                            className="nav-link d-flex align-items-center"
                            id="schedulecalls-tab"
                            data-toggle="tab"
                            data-target="#schedulecallsTab"
                            type="button"
                            role="tab"
                            aria-controls="schedulecalls"
                            aria-selected="false"
                          >
                            <span className="nav-tabs-column-img-container d-inline-flex align-items-center">
                              <svg
                                width="10.737"
                                height="10.737"
                                viewBox="1760.049 1873.927 10.737 10.737"
                              >
                                <path
                                  d="M1762.846 1873.926c.22 0 .4.18.4.4v.4h3.595v-.4a.4.4 0 0 1 .8 0v.4h.399a2 2 0 0 1 1.997 1.997v.849c0 .174-.123.349-.4.35h-8.788v3.995a1.2 1.2 0 0 0 1.198 1.198h1.648a.4.4 0 0 1 0 .8h-1.648a2 2 0 0 1-1.998-1.998v-5.194a2 2 0 0 1 1.998-1.998h.4v-.399c0-.22.179-.4.399-.4Zm6.392 3.196v-.399a1.2 1.2 0 0 0-1.198-1.198h-5.993a1.2 1.2 0 0 0-1.198 1.198v.4h8.389Zm-1.248 1.964a2.792 2.792 0 0 1 2.797 2.781 2.8 2.8 0 0 1-2.797 2.797 2.792 2.792 0 0 1-2.797-2.781 2.8 2.8 0 0 1 2.797-2.797Zm0 4.778a2 2 0 0 0 1.997-1.997 1.992 1.992 0 0 0-1.997-1.982 2 2 0 0 0-1.997 1.998c0 1.092.896 1.981 1.997 1.981Zm0-3.196c.22 0 .4.18.4.4v.633l.282.283a.4.4 0 0 1-.565.566l-.4-.4a.397.397 0 0 1-.117-.283v-.8c0-.22.18-.4.4-.4Z"
                                  fill="#2d343f"
                                />
                              </svg>
                            </span>
                            {globalStrings[selectedLanguage]?.$scheduledCalls}
                          </button>
                        </li>
                      )}
                      {CALL_RECORDINGS_LINK && (
                        <li className="nav-item" role="presentation">
                          <button
                            className="nav-link d-flex align-items-center"
                            id="callrecordings-tab"
                            data-toggle="tab"
                            data-target="#callrecordingsTab"
                            type="button"
                            role="tab"
                            aria-controls="callrecordings"
                            aria-selected="false"
                          >
                            <span className="nav-tabs-column-img-container d-inline-flex align-items-center">
                              <svg
                                width="13.077"
                                height="12.867"
                                viewBox="1759.35 1904.475 13.077 8.818"
                              >
                                <path
                                  d="M1761.895 1913.293a2.549 2.549 0 0 1-2.545-2.545v-3.727a2.549 2.549 0 0 1 2.545-2.546h5.325c1.314 0 2.398 1 2.531 2.279l.988-.994c.2-.152.428-.232.661-.232a1.03 1.03 0 0 1 1.027 1.034v4.614c0 .395-.219.75-.572.927a1.03 1.03 0 0 1-1.086-.098l-.016-.013-1-.997a2.549 2.549 0 0 1-2.533 2.298h-5.325Zm-1.713-6.273v3.727c0 .945.77 1.714 1.713 1.714h5.325c.945 0 1.713-.769 1.713-1.714v-3.727c0-.945-.768-1.714-1.713-1.714h-5.325c-.944 0-1.713.77-1.713 1.714Zm11.094-.627-1.511 1.528v1.91l1.536 1.535a.164.164 0 0 0 .077.017.23.23 0 0 0 .103-.024.198.198 0 0 0 .113-.183v-4.63a.205.205 0 0 0-.319-.154ZM1763 1908.9a1.5 1.5 0 1 1 3-.001 1.5 1.5 0 0 1-3 .001Z"
                                  fill="#2d343f"
                                />
                              </svg>
                            </span>
                            {globalStrings[selectedLanguage]?.$previousMeetings}
                          </button>
                        </li>
                      )}
                    </>
                  )}
                </ul>
                <div className="tab-content">
                  <div
                    className="tab-pane fade active h-100 flex-column justify-content-between"
                    id="avTab"
                    role="tabpanel"
                    aria-labelledby="av-tab"
                  >
                    <div className="d-flex gap-50">
                      <div className="tab-content-height">
                        <div className="form-group">
                          <label className="text-label">
                            {globalStrings[selectedLanguage]?.$microphone}
                          </label>
                          <div className="d-flex align-items-center justify-content-between">
                            <Select
                              className="form-control user-setting-select"
                              value={mic}
                              onChange={(e) => handleChangeMicrophone(e)}
                              options={
                                hasAudioPermission
                                  ? microphoneDeviceList
                                    ? microphoneDeviceList.map((_value) => {
                                        return {
                                          ..._value,
                                          value: _value.deviceId,
                                        };
                                      })
                                    : []
                                  : [
                                      {
                                        label: "Permission not granted",
                                        value: "Permission not granted",
                                      },
                                    ]
                              }
                              styles={customStyles}
                              isDisabled={!hasAudioPermission}
                              classNamePrefix="select-menu-custom"
                              components={{
                                DropdownIndicator,
                              }}
                            />
                          </div>
                        </div>

                        <div className="form-group">
                          <label className="text-label">
                            {globalStrings[selectedLanguage]?.$speaker}
                          </label>
                          <div className="d-flex align-items-center justify-content-between">
                            <Select
                              className="form-control user-setting-select"
                              value={speaker}
                              onChange={(e) => handleChangeSpeaker(e)}
                              options={
                                hasAudioPermission
                                  ? speakerDeviceList
                                    ? speakerDeviceList.map((_value) => {
                                        return {
                                          ..._value,
                                          value: _value.deviceId,
                                        };
                                      })
                                    : [
                                        {
                                          label: "Default",
                                          value: "Default",
                                        },
                                      ]
                                  : [
                                      {
                                        label: "Default",
                                        value: "Default",
                                      },
                                    ]
                              }
                              styles={customStyles}
                              isDisabled={
                                !hasAudioPermission ||
                                (hasAudioPermission &&
                                  speakerDeviceList.length === 0)
                                  ? true
                                  : false
                              }
                              classNamePrefix="select-menu-custom"
                              components={{
                                DropdownIndicator,
                              }}
                            />
                          </div>
                        </div>

                        <div className="form-group">
                          <label className="text-label">
                            {globalStrings[selectedLanguage]?.$camera}
                          </label>
                          <div className="d-flex align-items-center justify-content-between">
                            <Select
                              className="form-control user-setting-select"
                              value={camera}
                              onChange={(e) => handleChangeCamera(e)}
                              options={
                                hasVideoPermission
                                  ? cameraDeviceList
                                    ? cameraDeviceList.map((_value) => {
                                        return {
                                          ..._value,
                                          value: _value.deviceId,
                                        };
                                      })
                                    : []
                                  : [
                                      {
                                        label: "Permission not granted",
                                        value: "Permission not granted",
                                      },
                                    ]
                              }
                              styles={customStyles}
                              isDisabled={!hasVideoPermission}
                              classNamePrefix="select-menu-custom"
                              components={{
                                DropdownIndicator,
                              }}
                            />
                          </div>
                        </div>
                      </div>

                      <div className="video-preview d-flex align-items-center justify-content-center text-center text-white">
                        <div className="video-preview-inner w-100 h-100">
                          {!videoPreviewLoader ? (
                            videoPreviewTracks ? (
                              <VideoInputPreview
                                tracks={videoPreviewTracks.track}
                              />
                            ) : camerFailed ? (
                              <div className="video-preview-error w-100 h-100 d-flex align-items-center justify-content-center flex-column">
                                <b className="mb-2 d-flex">Camera Failed.</b>
                                Is camera already in use.
                              </div>
                            ) : (
                              <div className="video-preview-error w-100 h-100 d-flex align-items-center justify-content-center flex-column">
                                <b className="mb-2 d-flex">
                                  Preview Unavailable.
                                </b>
                                Did you give permission? <br /> Is camera
                                already in use? <br /> Is camera on?
                              </div>
                            )
                          ) : (
                            <div className="video-input-preview-error w-100 h-100 d-flex align-items-center justify-content-center">
                              <div className="spin"></div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>

                    <div className="form-group mt-5 d-flex justify-content-between">
                      <div className="d-flex">
                        <label className="modal-switch">
                          <input
                            spellCheck="false"
                            type="checkbox"
                            checked={isVirtualBackgroundEnabled}
                            onChange={(e) => virtualBackgroundEnabled(e)}
                          />
                          <span className="slider round"></span>
                          <span className="slider-text">
                            {
                              globalStrings[selectedLanguage]
                                ?.$virtualBackground
                            }
                          </span>
                        </label>
                        <label className="modal-switch">
                          <input
                            spellCheck="false"
                            type="checkbox"
                            checked={isFullBlurEnabled}
                            name="isFullBlurEnabled"
                            onChange={(e) => handleBlurEffect(e)}
                          />
                          <span className="slider round"></span>
                          <span className="slider-text">
                            {
                              globalStrings[selectedLanguage]
                                ?.$fullBlurBackground
                            }
                          </span>
                        </label>

                        <label className="modal-switch">
                          <input
                            spellCheck="false"
                            type="checkbox"
                            name="isPartialBlurEnabled"
                            checked={isPartialBlurEnabled}
                            onChange={(e) => handleBlurEffect(e)}
                          />
                          <span className="slider round"></span>
                          <span className="slider-text">
                            {
                              globalStrings[selectedLanguage]
                                ?.$partialBlurBackground
                            }
                          </span>
                        </label>
                      </div>
                      <div className="d-flex">
                        <button
                          type="submit"
                          id="submit"
                          className="btn btn-green btn btn-primary"
                          onClick={(e) => saveAvSetting(e)}
                        >
                          <span className="flex-1">
                            {globalStrings[selectedLanguage]?.$saveSettings}
                          </span>
                        </button>
                      </div>
                    </div>
                    {isVirtualBackgroundEnabled && (
                      <div className="form-group mt-2 d-flex justify-content-between">
                        <VirtualBackground
                          applyVirtualBackground={applyVirtualBackground}
                          virtualBackgroundOptions={virtualBackgroundOptions}
                        />
                      </div>
                    )}
                  </div>

                  <div
                    className="tab-pane fade h-100 flex-column justify-content-between"
                    id="ringtoneTab"
                    role="tabpanel"
                    aria-labelledby="ringtone-tab"
                  >
                    <div className="tab-content-height">
                      <div className="form-group">
                        <label className="text-label">
                          {globalStrings[selectedLanguage]?.$callSound}
                        </label>
                        <div className="d-flex align-items-center justify-content-between">
                          <Select
                            className="form-control user-setting-select user-setting-select-sm"
                            value={callSound}
                            onChange={(e) => handleChangeCallSound(e)}
                            options={callSoundOptions}
                            styles={customStyles}
                            classNamePrefix="select-menu-custom"
                            components={{
                              DropdownIndicator,
                            }}
                          />
                          <Button
                            className="btn btn-light2 w-fit-content"
                            type="submit"
                            id="submit"
                            variant="primary"
                            onClick={() => {
                              playSound("call");
                            }}
                            disabled={callPlayDisable}
                          >
                            {`${
                              callPlayDisable === false
                                ? globalStrings[selectedLanguage]?.$playSound
                                : globalStrings[selectedLanguage]?.$playing
                            }`}
                          </Button>
                        </div>
                      </div>

                      <div className="form-group">
                        <label className="text-label">
                          {globalStrings[selectedLanguage]?.$chatSound}
                        </label>
                        <div className="d-flex align-items-center justify-content-between">
                          <Select
                            className="form-control user-setting-select user-setting-select-sm"
                            value={chatSound}
                            onChange={(e) => handleChangeChatSound(e)}
                            options={chatSoundOptions}
                            styles={customStyles}
                            classNamePrefix="select-menu-custom"
                            components={{
                              DropdownIndicator,
                            }}
                          />
                          <Button
                            className="btn btn-light2 w-fit-content"
                            type="submit"
                            id="submit"
                            variant="primary"
                            onClick={() => {
                              playSound("chat");
                            }}
                            disabled={chatPlayDisable}
                          >
                            {`${
                              chatPlayDisable === false
                                ? globalStrings[selectedLanguage]?.$playSound
                                : globalStrings[selectedLanguage]?.$playing
                            }`}
                          </Button>
                        </div>
                      </div>
                    </div>
                    <div className="form-group mt-5 d-flex justify-content-end">
                      <button
                        type="submit"
                        id="submit"
                        className="btn btn-green btn btn-primary"
                        onClick={(e) => saveSettingHandler(e)}
                      >
                        <span className="flex-1">
                          {globalStrings[selectedLanguage]?.$saveSettings}
                        </span>
                      </button>
                    </div>
                  </div>

                  <div
                    className="tab-pane fade"
                    id="countTab"
                    role="tabpanel"
                    aria-labelledby="count-tab"
                  >
                    <div className="tab-content-height">
                      <div className="form-group">
                        <div className="d-flex align-items-center justify-content-between">
                          <p className="text-thin">
                            {globalStrings[selectedLanguage].$desktopAlertMsg}
                            &nbsp;
                            <button
                              type="submit"
                              id="submit"
                              className="btn-transparent"
                              onClick={() => handleClearCount()}
                            >
                              {globalStrings[selectedLanguage].$clearCount}
                            </button>
                            &nbsp;
                            {globalStrings[selectedLanguage].$desktopAlertMsg1}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  {authSessionUser.role !== "Agent" && (
                    <>
                      {SCHEDULED_CALLS_LINK && (
                        <div
                          className="tab-pane fade"
                          id="schedulecallsTab"
                          role="tabpanel"
                          aria-labelledby="schedulecalls-tab"
                        >
                          <div className="tab-content-height-lg">
                            <span className="lineH-20">
                              {
                                globalStrings[selectedLanguage]
                                  .$spanScheduleCall
                              }
                              <br />
                              <a
                                href="javascript:void(0);"
                                className="text-green d-flex align-items-center"
                                onClick={() => scheduleCallList()}
                              >
                                {
                                  globalStrings[selectedLanguage]
                                    .$clickToProceed
                                }
                                <svg
                                  className="rotate-317"
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                >
                                  <path
                                    fill="#2bc18d"
                                    d="m11.293 17.293 1.414 1.414L19.414 12l-6.707-6.707-1.414 1.414L15.586 11H6v2h9.586z"
                                  ></path>
                                </svg>
                              </a>
                            </span>
                          </div>
                        </div>
                      )}
                      {CALL_RECORDINGS_LINK && (
                        <div
                          className="tab-pane fade"
                          id="callrecordingsTab"
                          role="tabpanel"
                          aria-labelledby="callrecordings-tab"
                        >
                          <div className="tab-content-height-lg">
                            <span className="lineH-20">
                              {
                                globalStrings[selectedLanguage]
                                  .$spanRecordingCall
                              }
                              <br />
                              <a
                                href="javascript:void(0);"
                                className="text-green d-flex align-items-center"
                                onClick={() => callRecordingList()}
                              >
                                {
                                  globalStrings[selectedLanguage]
                                    .$clickToProceed
                                }
                                <svg
                                  className="rotate-317"
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                >
                                  <path
                                    fill="#2bc18d"
                                    d="m11.293 17.293 1.414 1.414L19.414 12l-6.707-6.707-1.414 1.414L15.586 11H6v2h9.586z"
                                  ></path>
                                </svg>
                              </a>
                            </span>
                          </div>
                        </div>
                      )}
                    </>
                  )}
                </div>
              </div>
            </>
          }
        </Modal.Body>
      </Modal>
    </>
  );
};

export default memo(UserSettingModal);
