import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import Sidebar from "./Sidebar";
import ChatBoard from "./chatboard";
import MinimizePopUp from "./models/MinimizePopUp";
import { getAuthSession } from "./Helpers/common";
import { _updatedCallDetail } from "../SocketIo";
import { setSessionThemeData } from "./redux/authUserDetail";
import AlertModal from "./models/AlertModal";
import { socket } from "../SocketIo";
import { getUserListExceptLoggedInUser } from "./Helpers/chatCommon";
import { globalStrings } from "./util/translation/languages";

const BelowHeader = () => {
  const dispatch = useDispatch();
  const { allAttendeeList, minimizePopUpShow } = useSelector(
    (state) => state.call,
  );
  const { user_unique_key: senderID } = getAuthSession();
  const authSessionUser = useSelector(
    (state) => state.authUserDetail.authSessionUser,
  );
  const { selectedLanguage } = useSelector((state) => state.chat);
  const [openAlert, setOpenAlert] = useState(false);
  const [alertType, setAlertType] = useState("");
  const [isOpenSidebar, setIsOpenSidebar] = useState(false);

  useEffect(() => {
    _updatedCallDetail();
  }, []);

  useEffect(async () => {
    sessionTheme();
  });

  const sessionTheme = () => {
    if (window.matchMedia) {
      if (window.matchMedia("(prefers-color-scheme: dark)").matches) {
        dispatch(setSessionThemeData(true));
      } else {
        dispatch(setSessionThemeData(false));
      }
    }
  };

  useEffect(async () => {
    window
      .matchMedia("(prefers-color-scheme: dark)")
      .addEventListener("change", sessionTheme);
  }, [senderID]);

  useEffect(async () => {
    getUserListExceptLoggedInUser();
  }, [allAttendeeList]);

  useEffect(() => {
    if (Object.keys(authSessionUser).length > 0) {
      if (authSessionUser?.user_active) {
        setAlertType("");
        setOpenAlert(false);
      } else {
        setAlertType("user");
        setOpenAlert(true);
        socket.disconnect();
      }
    }
  }, [authSessionUser]);

  const openSidebar = () => {
    setIsOpenSidebar(!isOpenSidebar);
  };

  return (
    <>
      <div className="chat_sec d-flex">
        <button
          className="open_sidebar"
          id="open-sidebar-id"
          onClick={() => openSidebar()}
        >
          <svg viewBox="2 3 20 18">
            <path
              fill="#ffffff"
              d="M20 3H4c-1.103 0-2 .897-2 2v14c0 1.103.897 2 2 2h16c1.103 0 2-.897 2-2V5c0-1.103-.897-2-2-2zM4 19V7h6v12H4zm8 0V7h8V5l.002 14H12z"
            ></path>
            <path fill="#ffffff" d="M6 10h2v2H6zm0 4h2v2H6z"></path>
          </svg>
        </button>

        <Sidebar isOpenSidebar={isOpenSidebar} />
        <ChatBoard />
        <div
          style={{
            position: "absolute",
            zIndex: "9",
            bottom: "17px",
            right: "17px",
          }}
        >
          {minimizePopUpShow && <MinimizePopUp />}
        </div>
      </div>
      {openAlert && (
        <AlertModal
          show={openAlert}
          closeModal={() => setOpenAlert(false)}
          msg={globalStrings[selectedLanguage]?.$belowHeaderError}
          alertType={alertType}
          selectedLanguage={selectedLanguage}
        />
      )}
    </>
  );
};

export default React.memo(BelowHeader);
