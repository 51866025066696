export const CLIENT_URL = "https://eu.c2perform.com/";
export const SOCKET_URL = "wss://as-eu-chat-api.c2perform.com/";
export const API_BASE_URL = "https://as-eu-chat-api.c2perform.com/api/";
export const UPLOAD_API_URL = "https://as-eu-chat-api.c2perform.com/api/";
export const MEDIA_URL = "as-eu-chat-api.c2perform.com";
export const DOMAIN = "as-eu-fra-wss.c2perform.com";
export const ENVIRONMENT = "prod";
export const RECORDING_ENABLE = true;
export const API_EVENT_BASE_URL = "https://as-eu-chat-api.c2perform.com/api/";
export const LOGOUT_CODE_PARAM = "100";
export const DYNAMIC_GROUP_URL = "https://eu.c2perform.com/window_app/managed-groups.php";
export const SCHEDULED_CALLS_LINK = "";
export const CALL_RECORDINGS_LINK = "";
