import React from "react";
import { useDispatch, useSelector } from "react-redux";

import { DirectOutBound } from "../Helpers/CallCommon";
import {
  clearMentionedUserProfileStates,
  openUserChat,
} from "../Helpers/chatCommon";
import { setIsCursorOnUserProfile } from "../redux/chatSlice";
import { globalStrings } from "../util/translation/languages";

const UserProfilePopOver = ({
  userInfo,
  popupClass,
  isOpenModal,
  closeModal,
  selectedLanguage,
}) => {
  const dispatch = useDispatch();
  const authSessionUser = useSelector(
    (state) => state.authUserDetail.authSessionUser,
  );
  const { userProfilePopPosition, msgMentionUser } = useSelector(
    (state) => state.chat,
  );

  const style = {
    position: "absolute",
    left: userProfilePopPosition.x,
    zIndex: 9999,
  };

  const mouseLeaveHandler = () => {
    clearMentionedUserProfileStates();
    dispatch(setIsCursorOnUserProfile(false));
  };

  return (
    <>
      <div
        className={
          isOpenModal
            ? "dropdown-custom position-relative w-100"
            : `dropdown-custom w-300 ${popupClass}`
        }
        style={msgMentionUser !== "" ? style : undefined}
        onMouseEnter={() =>
          msgMentionUser !== "" ? dispatch(setIsCursorOnUserProfile(true)) : ""
        }
        onMouseLeave={() =>
          msgMentionUser !== "" ? mouseLeaveHandler() : undefined
        }
      >
        <div className="dropdown-inner">
          <div className="px-10 d-flex align-items-center">
            <div
              className={
                isOpenModal
                  ? "border-radius overflow-hidden w-15 h-70"
                  : "border-radius overflow-hidden w-30 h-80"
              }
            >
              {userInfo?.user_profile_image ? (
                <img
                  src={userInfo?.user_profile_image}
                  alt="Profile pic"
                  className="w-100 h-100 profile-pic"
                />
              ) : (
                <svg viewBox="3 2 18 19">
                  <path
                    fill="#425b76"
                    d="M7.5 6.5C7.5 8.981 9.519 11 12 11s4.5-2.019 4.5-4.5S14.481 2 12 2 7.5 4.019 7.5 6.5zM20 21h1v-1c0-3.859-3.141-7-7-7h-4c-3.86 0-7 3.141-7 7v1h17z"
                  ></path>
                </svg>
              )}
            </div>
            <div className="w-70 pl-2">
              <div className="user-name d-flex align-items-center">
                {userInfo?.name}
                {authSessionUser?.user_id === userInfo?.user_id && "(You)"}
                <span
                  className={`status ${
                    userInfo?.online === true && "online"
                  } ml-2 border-transparent`}
                ></span>
              </div>
              <div className="user-role">{userInfo?.role}</div>
            </div>
          </div>
          <hr />
          <div className="px-10">
            {userInfo.user_id !== authSessionUser?.user_id && (
              <div
                className={
                  isOpenModal
                    ? "d-flex align-items-center justify-content-center"
                    : "d-flex align-items-center "
                }
              >
                <button
                  className={
                    isOpenModal
                      ? "btn btn-white border-light mr-1 flex-1"
                      : "btn btn-white border-light flex-1 mr-1"
                  }
                  onClick={() => {
                    openUserChat(userInfo);
                    msgMentionUser !== "" && clearMentionedUserProfileStates();
                    isOpenModal && closeModal();
                  }}
                >
                  <svg className="mr-2" width="15.826" viewBox="2 2 20 20">
                    <path
                      fill="#425a75"
                      d="M12 2C6.486 2 2 5.589 2 10c0 2.908 1.898 5.516 5 6.934V22l5.34-4.005C17.697 17.852 22 14.32 22 10c0-4.411-4.486-8-10-8zm0 14h-.333L9 18v-2.417l-.641-.247C5.67 14.301 4 12.256 4 10c0-3.309 3.589-6 8-6s8 2.691 8 6-3.589 6-8 6z"
                    ></path>
                  </svg>
                  {globalStrings[selectedLanguage]?.$message}
                </button>

                <button
                  className="btn btn-white border-light ml-1 flex-1"
                  onClick={() => {
                    DirectOutBound(userInfo);
                    msgMentionUser !== "" && clearMentionedUserProfileStates();
                    isOpenModal && closeModal();
                  }}
                >
                  <svg className="mr-2" width="15.826" viewBox="2 2 20 18">
                    <path
                      fill="#425a75"
                      d="M20 12v-1.707c0-4.442-3.479-8.161-7.755-8.29-2.204-.051-4.251.736-5.816 2.256A7.933 7.933 0 0 0 4 10v2c-1.103 0-2 .897-2 2v4c0 1.103.897 2 2 2h2V10a5.95 5.95 0 0 1 1.821-4.306 5.977 5.977 0 0 1 4.363-1.691C15.392 4.099 18 6.921 18 10.293V20h2c1.103 0 2-.897 2-2v-4c0-1.103-.897-2-2-2z"
                    ></path>
                    <path fill="#425a75" d="M7 12h2v8H7zm8 0h2v8h-2z"></path>
                  </svg>
                  {globalStrings[selectedLanguage]?.$huddle}
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default UserProfilePopOver;
