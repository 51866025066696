import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  callDetail: {},
  incoming_tone: "",
  callInvitationShow: false,
  scheduledGroup: {},
  allAttendeeList: [],
  isNetworkConnected: false,
  userOnCall: false,
  callStartUserDetail: {},
  callRoom: "",
  addPeoplePopUp: false,
  screenSpyStatus: false,
  minimizePopUpShow: false,
  minimizePopUpInnerData: {},
  outBoundCallPopUpVisibility: false,
  inBoundCallPopUpVisibility: false,
  outBoundCallPopUpShow: false,
  inBoundCallPopUpShow: false,
  recordingConsentPopUpEnable: false,
  recordingConsentUserIds: [],
  isOpenWhiteBoard: false,
  whiteBoardData: {},
  canvasSize: { height: 0, width: 0 },
  scheduledCallList: [],
  scheduledCallListForHost: [],
  sharedFileData: {},
  sharedFileProgressBar: "",
  sketchBoardPosition: [],
  recordingID: "",
  electronAppVersion: "",
  monitorScreens: {
    // screen: [
    //   {
    //     appIcon: null,
    //     display_id: "2528732444",
    //     id: "screen:0:0",
    //     name: "Screen 1",
    //   },
    //   {
    //     appIcon: null,
    //     display_id: "2779098405",
    //     id: "screen:1:0",
    //     name: "Screen 2",
    //   },
    // ],
  },
  monitorUser: {},
  numPages: 1,
  currrentPage: 1,
  maxVideos: 9,
};
const callSlice = createSlice({
  name: "call",
  initialState,
  reducers: {
    setSketchBoardPosition: (state, action) => {
      const id = action.payload.id;
      let tmpSketchBoardPosition = { ...state.sketchBoardPosition };
      tmpSketchBoardPosition[id] = action.payload;
      state.sketchBoardPosition = tmpSketchBoardPosition;
    },
    setCallDetail: (state, action) => {
      state.callDetail = action.payload;
    },
    setIncomingTone: (state, action) => {
      state.incoming_tone = action.payload;
    },
    setCallInvitationShow: (state, action) => {
      state.callInvitationShow = action.payload;
    },
    setScheduledGroup: (state, action) => {
      state.scheduledGroup = action.payload;
    },
    setAllAttendeeList: (state, action) => {
      state.allAttendeeList = action.payload;
    },
    setNetworkConnected: (state, action) => {
      state.isNetworkConnected = action.payload;
    },
    setUseronCall: (state, action) => {
      state.userOnCall = action.payload;
    },
    setCallStartUserDetail: (state, action) => {
      state.callStartUserDetail = action.payload;
    },
    setCallRoom: (state, action) => {
      state.callRoom = action.payload;
    },
    setAddPeoplePopUp: (state, action) => {
      state.addPeoplePopUp = action.payload;
    },
    setScreenSpyStatus: (state, action) => {
      state.screenSpyStatus = action.payload;
    },
    setMinimizePopUpShow: (state, action) => {
      state.minimizePopUpShow = action.payload;
    },
    setMinimizePopUpInnerData: (state, action) => {
      state.minimizePopUpInnerData = action.payload;
    },
    setOutBoundCallPopUpVisibility: (state, action) => {
      state.outBoundCallPopUpVisibility = action.payload;
    },
    setInBoundCallPopUpVisibility: (state, action) => {
      state.inBoundCallPopUpVisibility = action.payload;
    },
    setOutBoundCallPopUpShow: (state, action) => {
      state.outBoundCallPopUpShow = action.payload;
    },
    setInBoundCallPopUpShow: (state, action) => {
      state.inBoundCallPopUpShow = action.payload;
    },
    setRecordingConsentPopUpEnable: (state, action) => {
      state.recordingConsentPopUpEnable = action.payload;
    },
    setRecordingConsentUserIds: (state, action) => {
      state.recordingConsentUserIds = action.payload;
    },
    setIsOpenWhiteBoard: (state, action) => {
      state.isOpenWhiteBoard = action.payload;
    },
    setWhiteBoardData: (state, action) => {
      let whiteBoardList = {};
      if (Array.isArray(action.payload) && action.payload.length > 0) {
        action.payload.forEach((val) => {
          whiteBoardList[val.uuid] = val;
        });
      }
      state.whiteBoardData = whiteBoardList;
    },
    addWhiteBoardData: (state, action) => {
      const val = action.payload;
      let tmpWhiteBoardList = { ...state.whiteBoardData };
      tmpWhiteBoardList[val.uuid] = val;
      state.whiteBoardData = tmpWhiteBoardList;
    },
    setCanvasSize: (state, action) => {
      state.canvasSize = action.payload;
    },
    addScheduledCall: (state, action) => {
      let data = action.payload;
      const tmpScheduleCallList = [...state.scheduledCallList];
      tmpScheduleCallList.push(data);
      state.scheduledCallList = tmpScheduleCallList;
    },
    removeScheduledCall: (state, action) => {
      let data = action.payload;
      const updatedScheduleCallList = state.scheduledCallList.filter(
        (item) => item.scheduleId !== data.scheduleId,
      );
      state.scheduledCallList = updatedScheduleCallList;
    },
    addScheduledCallForHost: (state, action) => {
      let data = action.payload;
      const tmpScheduleCallListForHost = [...state.scheduledCallListForHost];
      tmpScheduleCallListForHost.push(data);
      state.scheduledCallListForHost = tmpScheduleCallListForHost;
    },
    removeScheduledCallForHost: (state, action) => {
      let data = action.payload;
      const updatedScheduleCallListForHost =
        state.scheduledCallListForHost.filter(
          (item) => item.scheduleId !== data.scheduleId,
        );
      state.scheduledCallListForHost = updatedScheduleCallListForHost;
    },
    setSharedFileData: (state, action) => {
      state.sharedFileData = action.payload;
    },
    setSharedFileProgressBar: (state, action) => {
      state.sharedFileProgressBar = action.payload;
    },
    setRecordingID: (state, action) => {
      state.recordingID = action.payload;
    },
    setElectronAppVersion: (state, action) => {
      state.electronAppVersion = action.payload;
    },
    setMonitorScreeens: (state, action) => {
      state.monitorScreens = action.payload;
    },
    setMonitorUser: (state, action) => {
      state.monitorUser = action.payload;
    },
    setMaxVideos: (state, action) => {
      state.maxVideos = action.payload;
    },
    setCurrentPage: (state, action) => {
      state.currrentPage = action.payload;
    },
    setNumPages: (state, action) => {
      state.numPages = action.payload;
    },
  },
});

export default callSlice.reducer;
export const {
  setSketchBoardPosition,
  setCallDetail,
  setIncomingTone,
  setCallInvitationShow,
  setScheduledGroup,
  setAllAttendeeList,
  setNetworkConnected,
  setUseronCall,
  setCallStartUserDetail,
  setCallRoom,
  setAddPeoplePopUp,
  setScreenSpyStatus,
  setMinimizePopUpShow,
  setMinimizePopUpInnerData,
  setOutBoundCallPopUpVisibility,
  setInBoundCallPopUpVisibility,
  setOutBoundCallPopUpShow,
  setInBoundCallPopUpShow,
  setRecordingConsentPopUpEnable,
  setRecordingConsentUserIds,
  setIsOpenWhiteBoard,
  setWhiteBoardData,
  addWhiteBoardData,
  setCanvasSize,
  setScheduleCallList,
  addScheduledCall,
  removeScheduledCall,
  addScheduledCallForHost,
  removeScheduledCallForHost,
  setSharedFileData,
  setSharedFileProgressBar,
  setRecordingID,
  setElectronAppVersion,
  setMonitorScreeens,
  setMonitorUser,
  setMaxVideos,
  setCurrentPage,
  setNumPages,
} = callSlice.actions;
