import React from "react";
import { useSelector } from "react-redux";

import VideoContainerHtml from "../Helpers/Lib/videocontainer";

const Iframe = (props) => {
  const iframeLoading = useSelector((state) => state.lib.iframeLoading);
  const sessionThemeData = useSelector(
    (state) => state.authUserDetail.sessionThemeData,
  );
  return (
    <>
      {iframeLoading && (
        <div className="loader_spinner video-container__loader">
          <div className="loader_spinner__box">
            {sessionThemeData ? (
              <img src="/assets/images/logo.svg" alt="logo" />
            ) : (
              <img src="/assets/images/logo-dark.svg" alt="Logo" />
            )}
            <div className="spin"></div>
          </div>
        </div>
      )}
      <VideoContainerHtml {...props} />
    </>
  );
};
export default Iframe;
