/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useSelector } from "react-redux";

const ImagePreviewModal = ({ show, closeModal, attachmentItem }) => {
  const { chatMessagesImages } = useSelector((state) => state.counter);
  const [imagePreviewLoader, setImagePreviewLoader] = useState(true);
  const [imageData, setImageData] = useState({});

  useEffect(() => {
    if (imagePreviewLoader && Object.keys(imageData).length === 0) {
      let id = attachmentItem?.attachmentName?.split(".")[0];
      const chatImageData = chatMessagesImages.find((item) => item.id === id);
      if (chatImageData && Object.keys(chatImageData).length > 0) {
        setImageData(chatImageData);
        setImagePreviewLoader(false);
      }
    }
  }, [attachmentItem, imagePreviewLoader, chatMessagesImages]);

  return (
    <Modal show={show} onHide={() => closeModal()} size="xl">
      <Modal.Header>
        <a className="icon-close top-10" onClick={() => closeModal()}>
          <svg
            width="15.556"
            height="15.556"
            viewBox="2240.448 1762.444 15.556 15.556"
          >
            <path
              d="m2250.727 1766.055-2.5 2.5-2.5-2.5a.786.786 0 0 0-1.112 0l-.555.556a.786.786 0 0 0 0 1.11l2.5 2.5-2.5 2.501a.786.786 0 0 0 0 1.111l.555.556a.786.786 0 0 0 1.111 0l2.5-2.5 2.5 2.5a.786.786 0 0 0 1.112 0l.555-.556a.786.786 0 0 0 0-1.11l-2.5-2.501 2.5-2.5a.786.786 0 0 0 0-1.111l-.555-.556a.786.786 0 0 0-1.111 0Z"
              fill="#2d343f"
            ></path>
          </svg>
        </a>
      </Modal.Header>
      <Modal.Body className="d-flex align-items-center justify-content-center">
        {imagePreviewLoader ? (
          <div className="loader_spinner__box__sm">
            <div className="spin"></div>
          </div>
        ) : !imageData?.isExpired ? (
          <img
            className="border-radius-0 cursor-zoomIn w-100 h-100 object-contain"
            src={imageData?.image}
            alt="logo"
          />
        ) : (
          <h3 className="text-red">Attachment has expired</h3>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default ImagePreviewModal;
