import React from "react";

import {
  _userUpdate,
  _updateCall,
  _groupUpdate,
  _sendEvent,
  _sendRoom,
  _getRoomDetail,
  whiteBoardElemets,
  clearWhiteBoardData,
} from "../../SocketIo";
import store from "../../store";
import {
  removeScheduledCallForHost,
  setCallRoom,
  setCallStartUserDetail,
  setCanvasSize,
  setInBoundCallPopUpShow,
  setMonitorUser,
  setOutBoundCallPopUpShow,
  setRecordingConsentUserIds,
  setScreenSpyStatus,
  setUseronCall,
} from "../redux/callSlice";

import {
  SCHEDULE_CALL_TITLE,
  getAuthSession,
  notifyDesktopApp,
  outgoing_tone,
  privateChatID,
} from "./common";
import { alertMsg } from "../util/alertMsg";
import { toast } from "react-toastify";
import PushNotification from "../util/PushNotification";
import swal from "sweetalert";
import { globalStrings } from "../util/translation/languages";
import { HangupScreenSpyCall } from "./Lib/screenspy";
import { RECORDING_ENABLE } from "../../constants";
export const hangupOutBoundCall = async (
  callDetailsData,
  type,
  allAttendeeList,
  user_unique_key,
  groupList,
) => {
  if (callDetailsData) {
    const { receiverID, senderID, participantID } = callDetailsData;
    const callDetails = { ...callDetailsData };
    if (callDetails?.type !== "group") {
      if (callDetails?.user_added === true) {
        AnotherUserAddedPrivateCallHangup(
          callDetails,
          user_unique_key,
          allAttendeeList,
          type,
        );
      } else {
        callDetails["calling_status"] = type;
        callDetails["recordingTimestamp"] = null;
        callDetails["hand_raise"] = false;
        _updateCall(callDetails);
        let senderUser = allAttendeeList.find(
          (obj) => obj?.user_id === senderID,
        );
        const senderUserData = { ...senderUser };
        senderUserData["onCall"] = false;
        senderUserData["roomId"] = "";

        _userUpdate(senderUserData);
        if (type === "ended") {
          clearWhiteBoardData();
        }
      }
    }

    if (callDetails?.type === "group") {
      let participant = [...participantID];
      if (user_unique_key === senderID) {
        participantID.forEach((item, index) => {
          if (item?.status === "initiated" || item?.status === "accepted") {
            participant[index] = {
              status: "ended",
              id: item.id,
              hand_raise: false,
              audio: false,
            };
          }
        });
        callDetails["calling_status"] = "ended";
        callDetails["entireScreenSharedIds"] = [];
        const GroupData = groupList.find((obj) => obj.groupId === receiverID);
        const UpdatedGroupData = { ...GroupData };
        UpdatedGroupData["groupCall"] = false;
        _groupUpdate(UpdatedGroupData);
        clearWhiteBoardData();
      } else {
        let index = participantID.findIndex((v) => v.id === user_unique_key);
        const particularObject = { ...participant[index] };
        particularObject.status = type;
        particularObject.hand_raise = false;
        particularObject.audio = false;
        participant[index] = particularObject;
      }
      callDetails["participantID"] = participant;
      _updateCall(callDetails);
      let User = allAttendeeList.find(
        (obj) => obj?.user_id === user_unique_key,
      );
      const UpdatedUser = { ...User };
      UpdatedUser["onCall"] = false;
      UpdatedUser["roomId"] = "";
      _userUpdate(UpdatedUser);
    }
    outgoing_tone.pause();
  }
};

export const hangupInboundCall = async (type) => {
  const state = store.getState();
  const { callDetail, allAttendeeList } = state.call;
  const { groupList } = state.chat;
  const { user_unique_key } = getAuthSession();
  if (callDetail) {
    const { receiverID, senderID, participantID } = callDetail;
    const callDetails = { ...callDetail };
    if (callDetails?.type === "private") {
      if (callDetails?.user_added === true) {
        AnotherUserAddedPrivateCallHangup(
          callDetails,
          user_unique_key,
          allAttendeeList,
          type,
        );
      } else {
        callDetails["calling_status"] = type;
        callDetails["hand_raise"] = false;
        _updateCall(callDetails);
        let senderUser = allAttendeeList.find(
          (obj) => obj?.user_id === user_unique_key,
        );
        const UpdatedSenderUser = { ...senderUser };
        UpdatedSenderUser["onCall"] = false;
        UpdatedSenderUser["roomId"] = "";

        _userUpdate(UpdatedSenderUser);
        if (type === "ended") {
          clearWhiteBoardData();
        }
      }
    } else if (callDetails?.type === "group") {
      let index = participantID.findIndex((v) => v.id === user_unique_key);
      let participant = [...participantID];
      const particularObject = { ...participant[index] };
      particularObject.status = type;
      particularObject.hand_raise = false;
      particularObject.audio = false;
      participant[index] = particularObject;
      callDetails["participantID"] = participant;
      const JoinedParticipant = participant.filter(
        (participantItem) => participantItem.status === "accepted",
      );
      if (JoinedParticipant.length == 0) {
        callDetails["calling_status"] = "ended";
        callDetails["recordingTimestamp"] = null;
        callDetails["entireScreenSharedIds"] = [];
        const GroupData = groupList.find((obj) => obj.groupId === receiverID);
        const UpdatedGroupData = { ...GroupData };
        UpdatedGroupData["groupCall"] = false;
        _groupUpdate(UpdatedGroupData);
        clearWhiteBoardData();
      }
      _updateCall(callDetails);
      let User = allAttendeeList.find(
        (obj) => obj?.user_id === user_unique_key,
      );
      const UpdatedUserData = { ...User };
      UpdatedUserData["onCall"] = false;
      UpdatedUserData["roomId"] = "";
      _userUpdate(UpdatedUserData);
    } else if (callDetails?.type === "schedule") {
      let participant = [...participantID];
      if (user_unique_key === senderID) {
        participantID.forEach((item, index) => {
          if (item?.status === "initiated" || item?.status === "accepted") {
            participant[index] = {
              status: "ended",
              id: item.id,
              hand_raise: false,
              audio: false,
            };
            let User = allAttendeeList.find((obj) => obj?.user_id === item.id);
            const UpdatedUser = { ...User };
            UpdatedUser["onCall"] = false;
            UpdatedUser["roomId"] = "";
            _userUpdate(UpdatedUser);
          }
        });
        callDetails["calling_status"] = "ended";
        callDetails["entireScreenSharedIds"] = [];
        const GroupData = groupList.find((obj) => obj.groupId === receiverID);
        const UpdatedGroupData = { ...GroupData };
        UpdatedGroupData["groupCall"] = false;
        _groupUpdate(UpdatedGroupData);
        clearWhiteBoardData();
      } else {
        let index = participantID.findIndex((v) => v.id === user_unique_key);
        const particularObject = { ...participant[index] };
        particularObject.status = type;
        particularObject.hand_raise = false;
        particularObject.audio = false;
        participant[index] = particularObject;
      }
      callDetails["participantID"] = participant;
      _updateCall(callDetails);
      let User = allAttendeeList.find(
        (obj) => obj?.user_id === user_unique_key,
      );
      const UpdatedUser = { ...User };
      UpdatedUser["onCall"] = false;
      UpdatedUser["roomId"] = "";
      _userUpdate(UpdatedUser);
    }
  }

  store.getState().call.incoming_tone.pause();
};
const AnotherUserAddedPrivateCallHangup = (
  callDetailsData,
  user_unique_key,
  allAttendeeList,
  type,
) => {
  const { participantID } = callDetailsData;
  const callDetails = { ...callDetailsData };

  let participant = [...participantID];
  if (user_unique_key === callDetailsData.senderID) {
    participantID.forEach((item, index) => {
      if (item?.status === "initiated" || item?.status === "accepted") {
        participant[index] = {
          status: "ended",
          id: item.id,
          hand_raise: false,
          audio: false,
        };
      }
    });
    let User = allAttendeeList.find((obj) => obj?.user_id === user_unique_key);
    const UpdatedUser = { ...User };
    UpdatedUser["onCall"] = false;
    UpdatedUser["roomId"] = "";
    _userUpdate(UpdatedUser);
    callDetails["calling_status"] = "ended";
    callDetails["recordingTimestamp"] = null;
    callDetails["entireScreenSharedIds"] = [];
    clearWhiteBoardData();
  } else {
    let index = participantID.findIndex((v) => v.id === user_unique_key);
    const particularObject = { ...participant[index] };
    particularObject.status = type;
    particularObject.hand_raise = false;
    particularObject.audio = false;
    participant[index] = particularObject;
    const JoinedParticipant = participant.filter(
      (participantItem) => participantItem.status === "accepted",
    );
    if (JoinedParticipant.length == 0) {
      callDetails["calling_status"] = "ended";
      callDetails["recordingTimestamp"] = null;
      callDetails["entireScreenSharedIds"] = [];
    }
    let User = allAttendeeList.find((obj) => obj?.user_id === user_unique_key);
    const UpdatedUserData = { ...User };
    UpdatedUserData["onCall"] = false;
    UpdatedUserData["roomId"] = "";
    _userUpdate(UpdatedUserData);
    clearWhiteBoardData();
  }
  callDetails["participantID"] = participant;
  _updateCall(callDetails);
};

export const hangupOutBoundMonitorCall = (callDetailsData, allAttendeeList) => {
  const { participantID, senderID } = callDetailsData;
  const callDetails = { ...callDetailsData };
  let participant = [...participantID];
  participantID.forEach((element, index) => {
    const particularObject = { ...participant[index] };
    particularObject.status = "ended";
    participant[index] = particularObject;
    // let participantUser = allAttendeeList.find(
    //   (obj) => obj.user_id === element.id,
    // );
    // const participantUserData = { ...participantUser };
    // participantUserData["monitor_status"] = false;
    // participantUserData["roomId"] = "";
    // _userUpdate(participantUserData);
  });
  callDetails["participantID"] = participant;
  callDetails["calling_status"] = "ended";
  callDetails["monitor_status"] = false;
  _updateCall(callDetails);
  let senderUser = allAttendeeList.find((obj) => obj.user_id === senderID);
  const senderUserData = { ...senderUser };
  senderUserData["monitor_status"] = false;
  senderUserData["roomId"] = "";

  _userUpdate(senderUserData);
};

export const RemoveUserFromCall = (user_id, callDetailsData) => {
  const { participantID } = callDetailsData;
  const callDetails = { ...callDetailsData };
  let index = participantID.findIndex((v) => v.id === user_id);
  let participant = [...participantID];
  const particularObject = { ...participant[index] };
  particularObject.status = "ended";
  particularObject.hand_raise = false;
  particularObject.audio = false;
  participant[index] = particularObject;
  callDetails["participantID"] = participant;
  _updateCall(callDetails);
};

export const DirectOutBound = async (data) => {
  const { dispatch } = store;
  const state = store.getState();
  const { allAttendeeList, callDetail } = state.call;
  const { user_unique_key: senderIDD } = getAuthSession();
  try {
    const { onCall, monitor_status } = data;
    let userLoggedInMonitorStatus =
      allAttendeeList.find((v) => v.user_id === senderIDD)?.monitor_status ??
      false;
    if (
      monitor_status === undefined ||
      (monitor_status === false && userLoggedInMonitorStatus === undefined) ||
      userLoggedInMonitorStatus === false
    ) {
      if (onCall === undefined || onCall === false) {
        callSetupHandler(data);
      } else {
        dispatch(setUseronCall(data?.user_id ?? ""));
      }
    } else {
      dispatch(setCallStartUserDetail(data));
      await hangupOutBoundMonitorCall(callDetail, allAttendeeList);
    }
  } catch (err) {
    console.log(err);
  }
};

export const callSetupHandler = (data) => {
  const { user_unique_key: senderIDD } = getAuthSession();
  setCallStartUserDetail({});
  const { dispatch } = store;
  const { company_id } = data;
  let room = privateChatID([senderIDD, data?.user_id]);
  dispatch(setCallRoom(room));
  StartCall(company_id, room, data);
  const ObjPayload = {
    senderID: senderIDD,
    event_type: "call-notification", // typing-start / typing-end / call-notification / msg-notification
    receiverID: data?.user_id, // receiverID or groupID
    type: "private", // private or group
    message: "call",
    company_id: company_id,
    timestamp: Date.now(),
    documentID: room,
  };
  _sendEvent(ObjPayload);
};

export const StartCall = async (company_id, room, data) => {
  console.log("StartCall");
  const { user_unique_key: senderIDD } = getAuthSession();
  const state = store.getState();
  const { allAttendeeList } = state.call;
  let host = senderIDD;

  let hostUserData = allAttendeeList?.find((obj) => obj.user_id === host);
  const UpdatedHostUserData = { ...hostUserData };

  UpdatedHostUserData["roomId"] = room;
  UpdatedHostUserData["onCall"] = true;

  const participantID = [
    {
      id: host,
      status: "accepted",
      hand_raise: false,
      audio: true,
    },
    {
      id: data?.user_id,
      status: "initiated",
      hand_raise: false,
      audio: false,
    },
  ];

  _userUpdate(UpdatedHostUserData);
  const callPayload = {
    calling_status: "initiated",
    senderID: host,
    timestamp: Date.now(),
    receiverID: data?.user_id,
    company_id: company_id,
    monitor_status: false,
    documentID: room, // room
    type: "private", // "private" : "group
    participantID: participantID,
    user_added: false,
    recordingTimestamp: null,
    hand_raise: false,
    scheduleId: data?.scheduleId ?? undefined,
    sharedFile: {},
    entireScreenSharedIds: [],
  };
  await _updateCall(callPayload);
  whiteBoardElemets({ documentID: room, company_id: company_id });
};

export const callDataChangeActions = () => {
  const { user_unique_key: senderID } = getAuthSession();
  const state = store.getState();
  const { callDetail, incoming_tone, allAttendeeList } = state.call;
  const { dispatch } = store;
  if (callDetail && Object.keys(callDetail).length > 0) {
    const {
      senderID: callSenderID,
      participantID,
      type,
      calling_status,
      receiverID,
      documentID,
      user_added,
    } = callDetail;

    // for group
    if (
      type === "group" &&
      calling_status &&
      calling_status !== "decline" &&
      calling_status !== "ended"
    ) {
      const particularID = participantID?.find((v) => v.id === senderID);
      if (particularID?.status === "accepted") {
        callSenderID === senderID
          ? dispatch(setOutBoundCallPopUpShow(true))
          : dispatch(setInBoundCallPopUpShow(true));
      }

      if (callSenderID !== senderID && particularID?.status === "initiated") {
        if (incoming_tone) {
          incoming_tone?.play();
        }
        dispatch(setCallRoom(receiverID));
        dispatch(setInBoundCallPopUpShow(true));
      }
    }

    // for one-to-one
    if (type === "private") {
      if (
        receiverID === senderID &&
        calling_status === "initiated" &&
        (callDetail?.monitor_status === undefined ||
          callDetail?.monitor_status === false)
      ) {
        if (incoming_tone) {
          incoming_tone?.play();
        }
        dispatch(setCallRoom(documentID));
        dispatch(setInBoundCallPopUpShow(true));
      }

      if (
        callDetail?.monitor_status === true &&
        calling_status === "initiated"
      ) {
        const particularID = participantID?.find((v) => v.id === senderID);
        if (
          particularID?.id !== receiverID &&
          particularID?.id !== callSenderID &&
          particularID?.status === "accepted"
        ) {
          dispatch(setCallRoom(documentID));
          dispatch(setInBoundCallPopUpShow(true));
        }
      }
      if (senderID === callSenderID && calling_status === "initiated") {
        dispatch(setOutBoundCallPopUpShow(true));
      }
      if (
        calling_status === "ended" &&
        (callDetail?.monitor_status === false ||
          callDetail?.monitor_status === undefined)
      ) {
        if (receiverID === senderID || callSenderID === senderID) {
          let loggedInUser = allAttendeeList.find(
            (obj) => obj.user_id === senderID,
          );
          if (loggedInUser?.onCall) {
            const loggedInUserData = { ...loggedInUser };
            loggedInUserData["onCall"] = false;
            loggedInUserData["roomId"] = "";
            _userUpdate(loggedInUserData);
          }
        }

        dispatch(setInBoundCallPopUpShow(false));
        dispatch(setOutBoundCallPopUpShow(false));
      }
      if (user_added === true) {
        // eslint-disable-next-line no-unsafe-optional-chaining
        const callUserDetail = callDetail?.participantID?.find(
          (v) => v?.id === senderID,
        );
        if (callUserDetail?.status === "initiated") {
          if (incoming_tone) {
            incoming_tone?.play();
          }
          dispatch(setCallRoom(documentID));
          dispatch(setInBoundCallPopUpShow(true));
        }
      }
    }
  }
};

export const CallMonitorStatusChangeAction = () => {
  const state = store.getState();
  const { callDetail, allAttendeeList } = state.call;
  const { dispatch } = store;
  const { user_unique_key: senderID } = getAuthSession();
  if (callDetail.monitor_status === true) {
    if (senderID == callDetail.senderID) {
      // eslint-disable-next-line no-unsafe-optional-chaining
      const callUserDetail = callDetail?.participantID?.find(
        (v) => v?.id === senderID,
      );
      if (callUserDetail?.status === "accepted") {
        dispatch(setCallRoom(callDetail?.documentID));
        dispatch(setOutBoundCallPopUpShow(true));
      }
    } else {
      // eslint-disable-next-line no-unsafe-optional-chaining
      const tmpCallUserDetail = callDetail?.participantID?.find(
        (v) => v?.id === senderID,
      );
      if (
        tmpCallUserDetail?.status === "accepted" &&
        tmpCallUserDetail?.id === callDetail?.receiverID
      ) {
        dispatch(setCallRoom(callDetail?.documentID));
        dispatch(setScreenSpyStatus(true));
        let loggedInUser = allAttendeeList.find(
          (obj) => obj.user_id === senderID,
        );

        const loggedInUserData = { ...loggedInUser };
        loggedInUserData["monitor_status"] = true;
        loggedInUserData["roomId"] = callDetail?.documentID;
        _userUpdate(loggedInUserData);
      }

      if (tmpCallUserDetail?.status === "ended") {
        HangupScreenSpyCall();
        let loggedInUser = allAttendeeList.find(
          (obj) => obj.user_id === senderID,
        );
        if (loggedInUser?.monitor_status) {
          const loggedInUserData = { ...loggedInUser };
          loggedInUserData["monitor_status"] = false;
          loggedInUserData["roomId"] = "";
          _userUpdate(loggedInUserData);
        }
      }
    }
  }
};

export const GroupOutBoundCall = async (groupData, isInstantCall = false) => {
  const { dispatch } = store;
  const { user_unique_key: senderID, company_unique_key: company_id } =
    getAuthSession();
  const selectedGroup = groupData;
  if (groupData.groupCall === undefined || groupData.groupCall === false) {
    dispatch(
      setCallRoom(isInstantCall ? groupData?.scheduleId : groupData?.groupId),
    );
    let receiverId = {};
    groupData?.attendeeId
      ?.filter((filterItem) => filterItem != senderID)
      .map((attendeeItem) => {
        receiverId[`${attendeeItem}`] = { notify: true };
      });
    let partparticipantID = [];
    groupData?.attendeeId?.map((v) => {
      if (v !== senderID) {
        partparticipantID.push({
          id: v,
        });
      }
    });
    const ObjPayload = {
      senderID: senderID,
      event_type: "call-notification", // typing-start / typing-end / call-notification / msg-notification
      receiverID: isInstantCall ? groupData?.scheduleId : groupData?.groupId, // receiverID or groupID
      type: isInstantCall ? "schedule" : "group", // private or group
      message: "call",
      company_id: company_id,
      timestamp: Date.now(),
      documentID: isInstantCall ? groupData?.scheduleId : groupData?.groupId,
      participantID: isInstantCall ? partparticipantID : undefined,
      scheduleName: isInstantCall ? groupData?.scheduleName : undefined,
    };
    _sendEvent(ObjPayload);
    StartGroupCall(
      company_id,
      isInstantCall ? groupData?.scheduleId : groupData?.groupId,
      senderID,
      groupData,
      "group",
      isInstantCall,
    );
    selectedGroup["groupCall"] = true;
    _groupUpdate(selectedGroup);
  } else {
    const existingGroupCallPayload = {
      company_id: company_id,
      documentID: groupData?.groupId,
      user_id: senderID,
    };
    await _sendRoom(existingGroupCallPayload);
    const ExistingGroupCall = await _getRoomDetail(false);
    JoinGroupCall(ExistingGroupCall);
  }
};

export const StartGroupCall = async (
  companyID,
  room,
  senderID,
  group,
  callType,
  isInstantCall,
) => {
  let host = senderID;
  let participant = [];
  const state = store.getState();
  const { allAttendeeList } = state.call;
  group?.attendeeId?.map((v) => {
    let particularAttendeeData = allAttendeeList?.find(
      (filterItem) => filterItem.user_id === v,
    );
    const onCallCheck = particularAttendeeData?.onCall ?? false;
    const UpdatedParticularAttendeeData = { ...particularAttendeeData };
    if (!onCallCheck) {
      if (v === host) {
        participant.push({
          id: v,
          status: callType === "group" ? "accepted" : "initiated",
          hand_raise: false,
          audio: true,
        });
        UpdatedParticularAttendeeData["onCall"] = true;
        UpdatedParticularAttendeeData["roomId"] = room;
        _userUpdate(UpdatedParticularAttendeeData);
      } else {
        participant.push({
          id: v,
          status: "initiated",
          hand_raise: false,
          audio: false,
        });
      }
    } else {
      participant.push({
        id: v,
        status: "ended",
        hand_raise: false,
        audio: false,
      });
    }
  });

  let participantID = [...participant];
  const callPayload = {
    calling_status: "initiated",
    senderID: isInstantCall ? group?.adminId : host,
    timestamp: Date.now(),
    receiverID: room,
    company_id: companyID,
    documentID: room, // room
    type: callType, // "private" , "group", schedule
    participantID,
    groupName: isInstantCall ? group?.scheduleName : group?.groupName,
    monitor_status: false,
    user_added: false,
    recordingTimestamp: null,
    scheduleId: group?.scheduleId ?? undefined,
    sharedFile: {},
    entireScreenSharedIds: [],
  };
  _updateCall(callPayload);
  whiteBoardElemets({ documentID: room, company_id: companyID });
};

export const JoinGroupCall = (ExistingGroupCall) => {
  const ExistingCallDetail = ExistingGroupCall;
  const { user_unique_key: senderID } = getAuthSession();
  const { dispatch } = store;
  const state = store.getState();
  const { allAttendeeList } = state.call;
  if (ExistingGroupCall) {
    const { participantID, documentID } = ExistingGroupCall;
    dispatch(setCallRoom(documentID));
    const participant = [...participantID];
    let index = participantID.findIndex((v) => v.id === senderID);
    participant[index].status = "accepted";
    participant[index].audio = true;
    ExistingCallDetail["participantID"] = participant;
    let particularAttendeeData = allAttendeeList?.find(
      (filterItem) => filterItem.user_id === senderID,
    );
    const updatedParticularAttendeeData = { ...particularAttendeeData };
    updatedParticularAttendeeData["onCall"] = true;
    updatedParticularAttendeeData["roomId"] = documentID;
    _userUpdate(updatedParticularAttendeeData);
    _updateCall(ExistingCallDetail);
  }
  _getRoomDetail(true);
};

export const recordingConsentCloseHandler = () => {
  const state = store.getState();
  const { recordingConsentUserIds } = state.call;
  const { user_unique_key } = getAuthSession();
  const { dispatch } = store;
  const updatedRecordingConsentUserIds = [...recordingConsentUserIds];
  const index = recordingConsentUserIds.findIndex(
    (item) => item === user_unique_key,
  );
  updatedRecordingConsentUserIds.splice(index, 1);
  dispatch(setRecordingConsentUserIds(updatedRecordingConsentUserIds));
};

export const RecordingContent = () => {
  const state = store.getState();
  const { recordingTime, isActiveRecording } = state.lib;
  const { callDetail } = state.call;
  const { user_unique_key } = getAuthSession();
  return (
    <>
      {isActiveRecording && (
        <h5 className="record-duration  position-absolute text-white">
          {user_unique_key === callDetail?.senderID
            ? new Date(recordingTime * 1000).toISOString()?.slice(11, 19)
            : alertMsg.recordingMsg}
        </h5>
      )}
    </>
  );
};

export const _canvas_credentials = () => {
  const { dispatch } = store;
  const _element = document.getElementById("outerStyleId");
  const height = _element?.clientHeight ?? 0;
  const width = _element?.clientWidth ?? 0;
  dispatch(setCanvasSize({ height, width }));
};

export const raiseHandNotify = (data) => {
  const state = store.getState();
  const { authSessionUser } = state.authUserDetail;
  const { roomId } = authSessionUser;
  const { documentID, name } = data;
  if (roomId === documentID) {
    toast(`${name} has raised a hand`, {
      position: toast.POSITION.BOTTOM_RIGHT,
      theme: "dark",
      autoClose: 3000,
    });
  }
};
const AVATAR_COLORS = [
  "232, 105, 156",
  "255, 198, 115",
  "69, 69, 119",
  "92, 167, 145",
  "234, 255, 128",
];
const AVATAR_OPACITY = 0.4;

export function getAvatarColor(initials) {
  let colorIndex = 0;

  if (initials) {
    let nameHash = 0;

    for (const s of initials) {
      nameHash += s.codePointAt(0);
    }

    colorIndex = nameHash % AVATAR_COLORS.length;
  }

  return `rgba(${AVATAR_COLORS[colorIndex]}, ${AVATAR_OPACITY})`;
}

export function _getAvatarStyle(color) {
  return {
    backgroundColor: color || undefined,
  };
}

export const joinScheduleCall = async (data) => {
  const state = store.getState();
  const { authSessionUser } = state.authUserDetail;
  const { selectedLanguage } = state.chat;
  if (!authSessionUser?.onCall) {
    const { scheduleId } = data;
    const { user_unique_key: senderID, company_unique_key: company_id } =
      getAuthSession();
    const existingScheduleCallPayload = {
      company_id: company_id,
      documentID: scheduleId,
      user_id: senderID,
    };
    await _sendRoom(existingScheduleCallPayload);
    const ExistingScheduleCall = await _getRoomDetail(false);
    JoinGroupCall(ExistingScheduleCall);
  } else {
    swal({
      text: globalStrings[selectedLanguage]?.$cannotPlaceCall,
      dangerMode: true,
      buttons: {
        cancel: true,
        confirm: false,
      },
      closeOnClickOutside: false,
    });
  }
};

export const scheduledCallReminder = (data) => {
  const { scheduleName } = data;
  const ScheduleBody = `You have scheduled a call with the name "${scheduleName}" in ${
    data?.reminder_minutes ?? 5
  } minutes.`;
  PushNotification.showScheduledCallReminderNotification(
    ScheduleBody,
    "assets/images/call-schedule.svg",
  );
  notifyDesktopApp(
    SCHEDULE_CALL_TITLE,
    ScheduleBody,
    "",
    "schedule-call-reminder",
    "assets/images/call-schedule.svg",
  );
};

export const startScheduledCall = (data) => {
  const { dispatch } = store;
  dispatch(removeScheduledCallForHost(data));
  const groupPayload = {
    groupId: data?.groupId,
    attendeeId: data?.attendeeId,
    adminId: data?.adminId,
    scheduleName: data?.scheduleName,
    scheduleId: data?.scheduleId,
    company_id: data?.company_id,
  };
  GroupOutBoundCall(groupPayload, true);
};

export const updateScreenshareTypeGlobally = (type, mode) => {
  const state = store.getState();
  const { callDetail } = state.call;
  const { authSessionUser } = state.authUserDetail;
  const tmpCallDetail = { ...callDetail };
  const tmpEntireScreenSharedIds =
    callDetail.entireScreenSharedIds !== undefined
      ? // eslint-disable-next-line no-unsafe-optional-chaining
        [...callDetail?.entireScreenSharedIds]
      : [];
  const index = tmpEntireScreenSharedIds.findIndex(
    (v) => v === authSessionUser?.user_id,
  );
  if (index > -1 && mode === "stop") {
    tmpEntireScreenSharedIds.splice(index, 1);
  } else {
    if (mode === "start" && type === "screen") {
      tmpEntireScreenSharedIds.push(authSessionUser?.user_id);
    }
  }
  tmpCallDetail["entireScreenSharedIds"] = tmpEntireScreenSharedIds;
  _updateCall(tmpCallDetail);
};

export const showRecordingButton = (props) => {
  let flag = false;
  if (RECORDING_ENABLE && props?.callDetails?.participantID) {
    if (props?.callDetails?.type === "private") {
      if (props.user_unique_key === props?.callDetails?.senderID) {
        flag = true;
      }
    } else {
      const state = store.getState();
      const { groupList } = state.chat;
      const particularGroupData = groupList.find(
        (item) => item.groupId === props?.callDetails?.documentID,
      );
      if (particularGroupData && Object.keys(particularGroupData).length > 0) {
        const hostID = particularGroupData?.hostId ?? [];
        if (hostID.includes(props.user_unique_key)) {
          flag = true;
        }
      }
    }
  }

  return flag;
};

export const monitorStart = async (data) => {
  const state = store.getState();
  const { dispatch } = store;
  const { monitorUser, allAttendeeList } = state.call;
  console.log("monitorUser", monitorUser);
  if (monitorUser?.onCall === false) {
    const { user_id, company_id, monitor_status, roomId } = monitorUser;
    const { user_unique_key: senderIDD } = getAuthSession();
    let host = senderIDD;
    let hostUserData = allAttendeeList?.find((obj) => obj.user_id === host);
    if (monitor_status === false || monitor_status === undefined) {
      let room = privateChatID([senderIDD, user_id]);
      // let hostUserData = allAttendeeList?.find((obj) => obj.user_id === host);
      const updatedHostUserData = { ...hostUserData };
      updatedHostUserData["roomId"] = room;
      updatedHostUserData["monitor_status"] = true;
      _userUpdate(updatedHostUserData);
      const callPayload = {
        calling_status: "initiated",
        senderID: host,
        timestamp: Date.now(),
        receiverID: user_id,
        company_id: company_id,
        documentID: room, // room
        type: "private", // "private" : "group
        monitor_status: true,
        participantID: [
          { id: host, status: "accepted" },
          { id: user_id, status: "accepted" },
        ],
        sharedFile: {},
        entireScreenSharedIds: [],
        selectedMonitorScreen: data,
      };
      await _updateCall(callPayload);
      dispatch(setMonitorUser({}));
    } else {
      const existingGroupCallPayload = {
        company_id: company_id,
        documentID: roomId,
        user_id: host,
      };
      console.log(
        "existingGroupCallPayload saini =>",
        existingGroupCallPayload,
      );
      await _sendRoom(existingGroupCallPayload);
      const ExistingMonitorCall = await _getRoomDetail(false);
      console.log("ExistingMonitorCall saini =>", ExistingMonitorCall);
      JoinMonitor(ExistingMonitorCall);
    }
  }
};

const JoinMonitor = (ExistingMonitorCall) => {
  const state = store.getState();
  const { monitorUser, allAttendeeList } = state.call;
  const { dispatch } = store;
  const { user_unique_key: senderIDD } = getAuthSession();
  const ExistingCallDetail = { ...ExistingMonitorCall };
  if (
    ExistingCallDetail.participantID == undefined ||
    ExistingCallDetail.participantID.length === 0
  ) {
    let participant = [];
    participant.push(
      { id: ExistingCallDetail.senderID, status: "accepted" },
      { id: ExistingCallDetail.receiverID, status: "accepted" },
      { id: monitorUser?.user_id, status: "accepted" },
    );
    ExistingCallDetail["participantID"] = participant;
  } else {
    const { participantID } = ExistingCallDetail;
    const participant = [...participantID];
    const isUserAlreadyExistIndex = participant.findIndex(
      (filterItem) => filterItem.id === senderIDD,
    );
    if (isUserAlreadyExistIndex === -1) {
      participant.push({ id: senderIDD, status: "accepted" });
    } else {
      const prevParticipantItem = participant[isUserAlreadyExistIndex];
      participant[isUserAlreadyExistIndex] = {
        id: prevParticipantItem.id,
        status: "accepted",
      };
    }
    ExistingCallDetail["participantID"] = participant;
  }
  let particularAttendeeData = allAttendeeList?.find(
    (filterItem) => filterItem?.user_id === senderIDD,
  );
  console.log("ExistingMonitorCall JoinMonitor saini =>", ExistingCallDetail);
  dispatch(setCallRoom(ExistingMonitorCall?.documentID));
  const updatedParticularAttendeeData = { ...particularAttendeeData };
  updatedParticularAttendeeData["monitor_status"] = true;
  updatedParticularAttendeeData["roomId"] = ExistingMonitorCall.documentID;
  _userUpdate(updatedParticularAttendeeData);
  _updateCall(ExistingCallDetail);
  _getRoomDetail(true);
  dispatch(setMonitorUser({}));
};

export const updateCallAudio = (value) => {
  const { user_unique_key } = getAuthSession();
  const state = store.getState();
  const { callDetail } = state.call;

  const tmpCallDetail = { ...callDetail };
  const { participantID } = callDetail;
  const tmpParticipantID = [...participantID];
  let index = participantID.findIndex((v) => v.id === user_unique_key);
  const particularPartcipiant = { ...participantID[index] };
  particularPartcipiant.audio = value;
  tmpParticipantID[index] = particularPartcipiant;
  tmpCallDetail["participantID"] = tmpParticipantID;
  _updateCall(tmpCallDetail);
};
