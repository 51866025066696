import React, { useEffect, useMemo, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Modal } from "react-bootstrap";
import { Resizable } from "re-resizable";

import Timer from "../Helpers/Lib/recordingTimer";
import {
  getAuthSession,
  updateLoggedInUserCallStatus,
} from "../Helpers/common";
import { MEDIA_URL } from "../../constants";
import IframeCall from "../sidebar/Iframe";
import { _userUpdate, _updateCall, whiteBoardElemets } from "../../SocketIo";
import {
  _canvas_credentials,
  hangupInboundCall,
  showRecordingButton,
} from "../Helpers/CallCommon";
// eslint-disable-next-line import/no-unresolved
import { HangupCall } from "../Helpers/Lib";
import { GroupAcceptCall } from "../util/helpers";
import {
  setCallInvitationShow,
  setInBoundCallPopUpShow,
  setInBoundCallPopUpVisibility,
  setIsOpenWhiteBoard,
  setMinimizePopUpInnerData,
  setMinimizePopUpShow,
} from "../redux/callSlice";
import RecordingConsent from "./RecordingConsent";
import { globalStrings } from "../util/translation/languages";

const InBoundCall = (props) => {
  const { selectedLanguage } = props;
  const dispatch = useDispatch();
  const { user_unique_key, login_token, company_unique_key } = getAuthSession();
  // eslint-disable-next-line no-unused-vars
  const [callStarted, setCallStarted] = useState(false);
  const [width, setWidth] = useState(60);
  const [height, setHeight] = useState(75);
  const [fullScreen, setFullScreen] = useState(true);
  const incoming_tone = useSelector((state) => state.call.incoming_tone);
  const {
    allAttendeeList,
    callInvitationShow,
    inBoundCallPopUpVisibility,
    callDetail,
    callRoom,

    recordingConsentPopUpEnable,
    isOpenWhiteBoard,
  } = useSelector((state) => state.call);
  const { isActiveRecording } = useSelector((state) => state.lib);
  const { groupList } = useSelector((state) => state.chat);
  const url = "https://" + MEDIA_URL + "/";

  const closeModal = () => {
    dispatch(setInBoundCallPopUpShow(false));
  };

  useEffect(() => {
    if (isOpenWhiteBoard === true) {
      setWidth(100);
      setHeight(100);
    }
  }, [isOpenWhiteBoard]);

  useEffect(() => {
    if (isOpenWhiteBoard) {
      _canvas_credentials();
    }
  }, [fullScreen]);

  useEffect(() => {
    if (callDetail) {
      const {
        calling_status,
        type,
        participantID,
        user_added,
        monitor_status,
      } = callDetail;
      if (type === "private") {
        if (calling_status === "ended" || calling_status === "decline") {
          incoming_tone.pause();
          closeModal();
          dispatch(setInBoundCallPopUpVisibility(false));
          HangupCall(callDetail);
          dispatch(setIsOpenWhiteBoard(false));
          const NewCallDetail = { ...callDetail };
          if (callDetail.user_added === true) {
            NewCallDetail["user_added"] = false;
            _updateCall(NewCallDetail);
          }
        } else {
          if (user_added === true) {
            const tmpCallUserDetail = participantID.find(
              (v) => v.id === user_unique_key,
            );
            if (
              tmpCallUserDetail?.status === "decline" ||
              tmpCallUserDetail?.status === "ended"
            ) {
              updateLoggedInUserCallStatus();

              incoming_tone.pause();
              closeModal();
              dispatch(setInBoundCallPopUpVisibility(false));
              HangupCall(callDetail);
              dispatch(setIsOpenWhiteBoard(false));
            }

            if (tmpCallUserDetail?.status === "accepted") {
              setCallStarted(true);
              whiteBoardElemets({
                documentID: callDetail?.documentID,
                company_id: company_unique_key,
              });
            }
          }

          if (monitor_status === true) {
            const tmpCallUserDetail = participantID.find(
              (v) => v.id === user_unique_key,
            );
            if (tmpCallUserDetail?.status === "accepted") {
              setCallStarted(true);
            }
          }
        }
      } else {
        if (calling_status === "ended") {
          incoming_tone.pause();
          updateLoggedInUserCallStatus();
          closeModal();
          dispatch(setInBoundCallPopUpVisibility(false));
          HangupCall(callDetail);
          dispatch(setIsOpenWhiteBoard(false));
          const NewCallDetail = { ...callDetail };
          if (callDetail.user_added === true) {
            NewCallDetail["user_added"] = false;
            _updateCall(NewCallDetail);
          }
          dispatch(setCallInvitationShow(false));
        } else {
          const tmpCallUserDetail = participantID.find(
            (v) => v.id === user_unique_key,
          );
          if (
            tmpCallUserDetail?.status === "decline" ||
            tmpCallUserDetail?.status === "ended"
          ) {
            incoming_tone.pause();
            updateLoggedInUserCallStatus();
            closeModal();
            dispatch(setInBoundCallPopUpVisibility(false));
            HangupCall(callDetail);
            dispatch(setCallInvitationShow(false));
            dispatch(setIsOpenWhiteBoard(false));
          }

          if (tmpCallUserDetail?.status === "accepted") {
            incoming_tone?.pause();
            setCallStarted(true);
            dispatch(setCallInvitationShow(false));
            whiteBoardElemets({
              documentID: callDetail?.documentID,
              company_id: company_unique_key,
            });
          }
        }
      }
    }
  }, [callDetail]);

  useEffect(() => {
    if (width < 99 || height < 99) {
      setFullScreen(false);
    } else {
      setFullScreen(true);
    }
  }, [width, height]);

  const AcceptCall = async () => {
    if (callDetail) {
      incoming_tone.pause();
      const { type, documentID, user_added } = callDetail;
      const updatedCallDetails = { ...callDetail };
      if (type === "private") {
        if (user_added === true) {
          GroupAcceptCall(user_unique_key);
        } else {
          let UserData = allAttendeeList.find(
            (obj) => obj.user_id === user_unique_key,
          );
          const UpdatedUserData = { ...UserData };
          UpdatedUserData["onCall"] = true;
          UpdatedUserData["roomId"] = documentID;
          _userUpdate(UpdatedUserData);
          updatedCallDetails["calling_status"] = "accepted";
          const { participantID } = callDetail;
          const tmpParticipantID = [...participantID];
          let index = participantID.findIndex((v) => v.id === user_unique_key);
          const particularPartcipiant = { ...participantID[index] };
          particularPartcipiant.audio = true;
          particularPartcipiant.status = "accepted";
          tmpParticipantID[index] = particularPartcipiant;
          updatedCallDetails["participantID"] = tmpParticipantID;
          _updateCall(updatedCallDetails);
        }
      } else {
        GroupAcceptCall(user_unique_key);
      }
      setCallStarted(true);
      whiteBoardElemets({
        documentID: callDetail?.documentID,
        company_id: company_unique_key,
      });
    }
  };

  const EndCall = () => {
    hangupInboundCall("decline");
    incoming_tone.pause();
  };

  const VideoContent = () => {
    if (!callStarted) {
      const name =
        callDetail?.type === "private"
          ? allAttendeeList?.find((v) => v?.user_id === callDetail?.senderID)
              ?.name
          : `${callDetail?.groupName} Group`;
      return (
        <>
          <h6 className="mt-0 mb-4">{`${name} ${globalStrings[selectedLanguage]?.$callingYou}`}</h6>
          <div className="d-flex">
            <button className="btn btn-orange" onClick={() => AcceptCall()}>
              {globalStrings[selectedLanguage]?.$acceptCall}
            </button>
            {!callInvitationShow && (
              <button
                className="btn btn-white border-radius ml-2"
                onClick={() => {
                  EndCall();
                }}
              >
                {globalStrings[selectedLanguage]?.$endCall}
              </button>
            )}
          </div>
        </>
      );
    } else {
      return (
        // eslint-disable-next-line jsx-a11y/iframe-has-title
        <IframeCall
          url={url}
          room={callRoom}
          companyID={company_unique_key}
          user_unique_key={user_unique_key}
          login_token={login_token}
          callModalType="inBound"
          callDetails={callDetail}
          groupList={groupList}
          fullScreen={fullScreen}
        />
      );
    }
  };

  const MinimizePopUpHandler = async () => {
    const name =
      callDetail?.type === "private"
        ? allAttendeeList?.find((v) => v?.user_id === callDetail?.senderID)
            ?.name
        : `${callDetail?.groupName} Group`;
    await dispatch(
      setMinimizePopUpInnerData({ callerName: name, callType: "inbound" }),
    );
    dispatch(setInBoundCallPopUpVisibility(true));
    dispatch(setMinimizePopUpShow(true));
  };

  const fullScreenHandler = () => {
    setWidth(100);
    setHeight(100);
  };

  const showRecordingTimer = useMemo(() => {
    return showRecordingButton({ callDetails: callDetail, user_unique_key });
  }, [callDetail]);
  return (
    <>
      <Modal
        show={true}
        className={`modal-full width-modal align-items-center justify-content-center call-modal ${
          inBoundCallPopUpVisibility ? "visibility-hidden" : ""
        }`}
        backdrop="static"
        keyboard="False"
        id="inbound-modal"
      >
        <Resizable
          className={`${
            width > 98 && height > 98 ? "" : "margin-auto"
          } modal-resizable`}
          id="modal-resizable-id"
          size={{ width: `${width}%`, height: `${height}%` }}
          onResizeStop={(e, direction, ref, d) => {
            let ModalWidth =
              document.getElementById("inbound-modal").offsetWidth;
            let ModalHeight =
              document.getElementById("inbound-modal").offsetHeight;
            const resizeWidth = (100 / ModalWidth) * d.width;
            const resizeHeight = (100 / ModalHeight) * d.height;
            setWidth(width + resizeWidth);
            setHeight(height + resizeHeight);
          }}
          maxWidth="100%"
          maxHeight="100%"
          minWidth="60%"
          minHeight="75%"
        >
          <Modal.Header>
            <Modal.Title></Modal.Title>
            <div className="w-100">
              {isActiveRecording && (
                <h5 className="record-duration  position-absolute text-white">
                  {showRecordingTimer ? (
                    <Timer />
                  ) : (
                    "This meeting is being recorded…"
                  )}
                </h5>
              )}
            </div>
            <div className="d-flex">
              {!fullScreen ? (
                <button
                  className="maximize-restore-icons minimizePopupButton icon_container"
                  onClick={() => fullScreenHandler()}
                >
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className="feather feather-x"
                  >
                    <path
                      stroke="#b8c0c9"
                      fill="none"
                      d="M8 3H5a2 2 0 0 0-2 2v3m18 0V5a2 2 0 0 0-2-2h-3m0 18h3a2 2 0 0 0 2-2v-3M3 16v3a2 2 0 0 0 2 2h3"
                    />
                  </svg>
                </button>
              ) : (
                <button
                  className="maximize-restore-icons minimizePopupButton icon_container"
                  onClick={() => {
                    setWidth(60);
                    setHeight(75);
                  }}
                >
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className="feather feather-x"
                  >
                    <path
                      stroke="#b8c0c9"
                      fill="none"
                      d="M8 3v3a2 2 0 0 1-2 2H3m18 0h-3a2 2 0 0 1-2-2V3m0 18v-3a2 2 0 0 1 2-2h3M3 16h3a2 2 0 0 1 2 2v3"
                    />
                  </svg>
                </button>
              )}
              <button
                className="maximize-restore-icons minimizePopupButton icon_container"
                onClick={() => MinimizePopUpHandler()}
              >
                <svg
                  viewBox="0 0 24 24"
                  width="24"
                  height="24"
                  stroke="currentColor"
                  strokeWidth="2"
                  fill="none"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  className="css-i6dzq1"
                >
                  <polyline
                    points="4 14 10 14 10 20"
                    stroke="#b8c0c9"
                    fill="none"
                  ></polyline>
                  <polyline
                    points="20 10 14 10 14 4"
                    stroke="#b8c0c9"
                    fill="none"
                  ></polyline>
                  <line
                    x1="14"
                    y1="10"
                    x2="21"
                    y2="3"
                    stroke="#b8c0c9"
                    fill="none"
                  ></line>
                  <line
                    x1="3"
                    y1="21"
                    x2="10"
                    y2="14"
                    stroke="#b8c0c9"
                    fill="none"
                  ></line>
                </svg>
              </button>
            </div>
          </Modal.Header>
          <Modal.Body
            className={`d-flex align-items-center justify-content-center flex-column ${
              user_unique_key != callDetail?.senderID ? "pinUserArea" : ""
            }`}
          >
            {VideoContent()}
          </Modal.Body>
        </Resizable>
      </Modal>

      {recordingConsentPopUpEnable && (
        <RecordingConsent selectedLanguage={selectedLanguage} />
      )}
    </>
  );
};

export default InBoundCall;
