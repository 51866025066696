import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  room: null,
  roomName: null,
  connection: null,
  numpartcipient: 1,
  tracks: [],
  localTracks: [],
  videoContainerVisible: "hidden",
  iframeLoading: true,
  localAudioTrackMuted: false,
  localVideoTrackMuted: false,
  isAvailableMic: false,
  isActiveMic: false,
  isActiveCamera: false,
  isAvailableCamera: false,
  isActiveSpeaker: true,
  isActiveScreenSharing: false,
  isActiveRecording: false,
  recordingLoader: false,
  cameraDeviceId: null,
  micDeviceId: null,
  speakerDeviceId: null,
  wasActiveCamera: false,
  desktopScreenShareShow: false,
  recorderActiveSessionid: null,
  recordingTime: 0,
  recordingInterval: null,
  cameraDevice: "",
  microphoneDevice: "",
  speakerDevice: "",
  hasAudioPermission: false,
  hasVideoPermission: false,
  microphoneDeviceList: [],
  speakerDeviceList: [],
  cameraDeviceList: [],
  participants: [],
  localDesktopVideoTrack: null,
  activeSpeaker: null,
  isActivePenDraw: false,
  virtualBackgroundOptions: {},
  localVideoTrack: {},
  localAudioTrack: {},
  sharedScreenType: "",
};
const libSlice = createSlice({
  name: "lib",
  initialState,
  reducers: {
    setroom: (state, action) => {
      state.room = action.payload;
    },
    setconnection: (state, action) => {
      state.connection = action.payload;
    },
    setroomName: (state, action) => {
      state.roomName = action.payload;
    },
    setiframeLoading: (state, action) => {
      state.iframeLoading = action.payload;
    },
    setisActiveMic: (state, action) => {
      state.isActiveMic = action.payload;
    },
    setisAvailableMic: (state, action) => {
      state.isAvailableMic = action.payload;
    },
    setisActiveCamera: (state, action) => {
      state.isActiveCamera = action.payload;
    },
    setisAvailableCamera: (state, action) => {
      state.isAvailableCamera = action.payload;
    },
    setisActiveSpeaker: (state, action) => {
      state.isActiveSpeaker = action.payload;
    },
    setvideoContainer: (state, action) => {
      state.videoContainer = action.payload;
    },
    setremoteTracks: (state, action) => {
      state.remoteTracks = action.payload;
    },
    addnumpartcipient: (state) => {
      state.numpartcipient = state.numpartcipient + 1;
    },
    delnumpartcipient: (state) => {
      state.numpartcipient = state.numpartcipient - 1;
    },
    setnumpartcipient: (state, action) => {
      state.numpartcipient = action.payload;
    },
    setvideoContainerVisible: (state, action) => {
      state.videoContainerVisible = action.payload;
    },
    setcameraDeviceId: (state, action) => {
      state.cameraDeviceId = action.payload;
    },
    setmicDeviceId: (state, action) => {
      state.micDeviceId = action.payload;
    },
    setspeakerDeviceId: (state, action) => {
      state.speakerDeviceId = action.payload;
    },
    setisActiveScreenSharing: (state, action) => {
      state.isActiveScreenSharing = action.payload;
    },
    setrecordingLoader: (state, action) => {
      state.recordingLoader = action.payload;
    },
    setisActiveRecording: (state, action) => {
      state.isActiveRecording = action.payload;
    },
    setwasActiveCamera: (state, action) => {
      state.wasActiveCamera = action.payload;
    },
    setDesktopScreenShareShow: (state, action) => {
      state.desktopScreenShareShow = action.payload;
    },
    setrecorderActiveSessionid: (state, action) => {
      state.recorderActiveSessionid = action.payload;
    },
    setrecordingTime: (state, action) => {
      state.recordingTime = action.payload;
    },
    setRecordingInterval: (state, action) => {
      state.recordingInterval = action.payload;
    },
    setMicrophoneDevice: (state, action) => {
      state.microphoneDevice = action.payload;
    },
    setCameraDevice: (state, action) => {
      state.cameraDevice = action.payload;
    },
    setSpeakerDevice: (state, action) => {
      state.speakerDevice = action.payload;
    },
    setHasAudioPermission: (state, action) => {
      state.hasAudioPermission = action.payload;
    },
    setHasVideoPermission: (state, action) => {
      state.hasVideoPermission = action.payload;
    },
    setMicrophoneDeviceList: (state, action) => {
      state.microphoneDeviceList = action.payload;
    },
    setCameraDeviceList: (state, action) => {
      state.cameraDeviceList = action.payload;
    },
    setSpeakerDeviceList: (state, action) => {
      state.speakerDeviceList = action.payload;
    },
    trackAdd: (state, action) => {
      let track = action.payload;
      let isReceivingData = true;
      let noDataFromSourceNotificationInfo;
      let trackdata = {
        trackID: track.getId(),
        isReceivingData,
        mediaType: track.videoType,
        muted: track.isMuted(),
        noDataFromSourceNotificationInfo,
        participantId: track.getParticipantId(),
        videoStarted: false,
        type: track.type,
        jitsiTrack: track,
      };
      // console.log('new track add', trackdata);
      state.tracks.push(trackdata);
    },
    trackRemove: (state, action) => {
      let track = action.payload;
      // let trackID = track.getId();
      // console.log('track remove', track);
      let tracks = state.tracks.filter((t) => t.jitsiTrack !== track);
      state.tracks = tracks;
    },
    trackAddLocal: (state, action) => {
      let track = action.payload;
      let isReceivingData = true;
      let noDataFromSourceNotificationInfo;
      let trackdata = {
        trackID: track.getId(),
        isReceivingData,
        mediaType: track.videoType,
        muted: track.isMuted(),
        noDataFromSourceNotificationInfo,
        participantId: "local",
        videoStarted: false,
        type: track.type,
        jitsiTrack: track,
      };
      // console.log('new track add', trackdata);
      state.tracks.push(trackdata);
    },
    trackRemoveLocal: (state, action) => {
      let track = action.payload;
      // let trackID = track.getId();
      // console.log('track remove', track);
      let tracks = state.tracks.filter((t) => t.jitsiTrack !== track);
      state.tracks = tracks;
    },
    trackMediaTypeUpdate: (state, action) => {
      let track = action.payload;
      let trackID = track.getId();
      state.tracks.forEach((t, i) => {
        if (t.trackID === trackID) {
          state.tracks[i].mediaType = track.videoType;
        }
      });
    },
    trackMuteUpdate: (state, action) => {
      let track = action.payload;
      let trackID = track.getId();
      state.tracks.forEach((t, i) => {
        if (t.trackID === trackID) {
          state.tracks[i].muted = track.isMuted();
        }
      });
    },
    trackMuteUpdateLocal: (state, action) => {
      let track = action.payload;
      let trackID = track.getId();
      state.tracks.map((t, i) => {
        if (t.trackID === trackID) {
          state.tracks[i].muted = true;
        }
      });
    },
    partcipantAdd: (state, action) => {
      let participant = action.payload;
      state.participants.push(participant);
    },
    partcipantRemove: (state, action) => {
      let participant = action.payload;
      let participants = state.participants.filter(
        (p) => p._id !== participant._id,
      );
      state.participants = participants;
    },
    partcipantRemoveAll: (state) => {
      state.participants = [];
    },
    addLocalDesktopVideoTrack: (state, action) => {
      let track = action.payload;
      let isReceivingData = true;
      let noDataFromSourceNotificationInfo;
      let trackdata = {
        trackID: track.getId(),
        isReceivingData,
        mediaType: track.videoType,
        muted: track.isMuted(),
        noDataFromSourceNotificationInfo,
        participantId: track.getParticipantId(),
        videoStarted: false,
        type: track.type,
        jitsiTrack: track,
      };
      // console.log('new track add', trackdata);
      state.localDesktopVideoTrack = trackdata;
    },
    muteLocalDesktopVideoTrack: (state, action) => {
      let track = action.payload;
      let isReceivingData = true;
      let noDataFromSourceNotificationInfo;
      let trackdata = {
        trackID: track.getId(),
        isReceivingData,
        mediaType: track.videoType,
        muted: true,
        noDataFromSourceNotificationInfo,
        participantId: track.getParticipantId(),
        videoStarted: false,
        type: track.type,
        jitsiTrack: track,
      };
      // console.log('new track add', trackdata);
      state.localDesktopVideoTrack = trackdata;
    },
    removeLocalDesktopVideoTrack: (state) => {
      state.localDesktopVideoTrack = null;
    },
    activeSpeakerChange: (state, action) => {
      state.activeSpeaker = action.payload;
    },
    setIsActivePenDraw: (state, action) => {
      state.isActivePenDraw = action.payload;
    },
    setVirtualBackgroundOptions: (state, action) => {
      state.virtualBackgroundOptions = action.payload;
    },
    addLocalVideoTrack: (state, action) => {
      const tmpTrack = { ...state.localVideoTrack };
      // console.log("addLocalVideoTrack tmpTrack sainineha ===>", tmpTrack);
      let track = action.payload.track;
      let device = action.payload.device;
      // console.log(
      //   "addLocalVideoTrack action.payload sainineha ===>",
      //   action.payload,
      // );
      if (Object.keys(tmpTrack).length > 0) {
        tmpTrack.jitsiTrack.dispose();
      }
      if (track !== null) {
        let isReceivingData = true;
        let noDataFromSourceNotificationInfo;
        let trackdata = {
          trackID: track.getId(),
          isReceivingData,
          mediaType: track.videoType,
          muted: track.isMuted(),
          noDataFromSourceNotificationInfo,
          participantId: track.getParticipantId(),
          videoStarted: false,
          type: track.type,
          jitsiTrack: track,
          device: device,
        };
        state.localVideoTrack = trackdata;
      } else {
        state.localVideoTrack = {};
      }
    },
    removeLocalVideoTrack: (state) => {
      state.localVideoTrack = {};
    },
    updateLocalVideoTrackMute: (state, action) => {
      state.localVideoTrack.muted = action.payload;
    },

    addLocalAudioTrack: (state, action) => {
      const tmpTrack = { ...state.localAudioTrack };
      console.log("addLocalAudioTrack tmpTrack sainineha ===>", tmpTrack);
      let track = action.payload.track;
      let device = action.payload.device;
      console.log(
        "addLocalAudioTrack action.payload sainineha ===>",
        action.payload,
      );
      if (Object.keys(tmpTrack).length > 0) {
        tmpTrack.jitsiTrack.dispose();
      }

      if (track !== null) {
        let trackdata = {
          muted: track?.isMuted(),
          type: track.type,
          jitsiTrack: track,
          device: device,
        };
        state.localAudioTrack = trackdata;
      } else {
        state.localAudioTrack = {};
      }
    },
    updateLocalAudioTrackMute: (state, action) => {
      state.localAudioTrack.muted = action.payload;
    },
    removeLocalAudioTrack: (state) => {
      state.localAudioTrack = {};
    },
    setSharedScreenType: (state, action) => {
      state.sharedScreenType = action.payload;
    },
  },
});

export default libSlice.reducer;
export const {
  setisActiveScreenSharing,
  setisActiveRecording,
  setrecordingLoader,
  setrecorderActiveSessionid,
  setnumpartcipient,
  setcameraDeviceId,
  setmicDeviceId,
  setspeakerDeviceId,
  setvideoContainerVisible,
  setvideoContainer,
  setroom,
  setconnection,
  setroomName,
  setiframeLoading,
  setisActiveMic,
  setisAvailableMic,
  setisActiveCamera,
  setisAvailableCamera,
  setisActiveSpeaker,
  addnumpartcipient,
  delnumpartcipient,
  setwasActiveCamera,
  setDesktopScreenShareShow,
  setrecordingTime,
  setRecordingInterval,
  setMicrophoneDeviceList,
  setCameraDeviceList,
  setSpeakerDeviceList,
  setCameraDevice,
  setSpeakerDevice,
  setMicrophoneDevice,
  setHasAudioPermission,
  setHasVideoPermission,
  trackAdd,
  trackRemove,
  trackMediaTypeUpdate,
  trackMuteUpdate,
  partcipantAdd,
  partcipantRemove,
  addLocalDesktopVideoTrack,
  muteLocalDesktopVideoTrack,
  removeLocalDesktopVideoTrack,
  trackAddLocal,
  trackMuteUpdateLocal,
  partcipantRemoveAll,
  activeSpeakerChange,
  setIsActivePenDraw,
  setVirtualBackgroundOptions,
  addLocalVideoTrack,
  removeLocalVideoTrack,
  updateLocalVideoTrackMute,
  addLocalAudioTrack,
  updateLocalAudioTrackMute,
  removeLocalAudioTrack,
  setSharedScreenType,
} = libSlice.actions;
