import React from "react";
import Video from "../Helpers/Lib/video";

const VideoInputPreview = ({ tracks }) => {
  let videoTrack = { jitsiTrack: tracks };
  return (
    <Video
      videoTrack={videoTrack}
      className={"video-input-preview-display"}
      autoPlay={true}
    />
  );
};

export default VideoInputPreview;
