/* eslint-disable react/no-unescaped-entities */
/* eslint-disable quotes */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/display-name */
import React, { useEffect, useRef, useState, memo } from "react";
import { debounce } from "lodash";
import EmojiPicker from "emoji-picker-react";
import Select from "react-select";
import { Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { toast, ToastContainer } from "react-toastify";
import { StylesViaJss } from "substyle-jss";
import { v4 as uuidv4 } from "uuid";
import { MentionsInput, Mention } from "react-mentions";

import {
  getAuthSession,
  fileUpload,
  privateChatID,
  validateBadWordList,
  getImagesURL,
  _5gb,
} from "../Helpers/common";
import FileSize from "../models/FileSize";
import {
  _sendChatMessage,
  _sendEvent,
  _editChatMessage,
  _updateRecentlyUsedEmoticons,
} from "../../SocketIo";
import { timestampToDateTime } from "../Helpers/common";
import useAutosizeTextArea from "./useAutosizeTextArea";
import Style from "../../assets/chatboardFooter.module.css";
import {
  setIsUploadingData,
  setRecentlyUsedEmoticons,
  setReplyMessage,
  setSelectedFileName,
  setSelectedFileType,
} from "../redux/chatSlice";
import ChatBadWord from "../models/ChatBadWord";
import { OnClickOutside, parseEmoji } from "../Helpers/chatCommon";
import SelectedFileToDisplay from "./selectedFile";
import { alertMsg } from "../util/alertMsg";
import { emoticon } from "emoticon";

const UserInputMessageArea = (props) => {
  const { componentType, editableData, editMessageHandler } = props;
  const authSession = getAuthSession();
  const [dropDownAttendee, setDropDownAttendee] = useState([]);
  const [dropDownAttendeeMain, setDropDownAttendeeMain] = useState([]);
  const [sizeErrorShow, setSizeErrorShow] = useState(false);
  const [sizeErrorData, setSizeErrorData] = useState("");
  const [chatUser, setChatUser] = useState([]);
  const dispatch = useDispatch();
  const [chatBadWord, setChatBadWord] = useState(false);
  const { allAttendeeList, isNetworkConnected } = useSelector(
    (state) => state.call,
  );
  const {
    selectedFileName,
    selectedFileType,
    attendeeData,
    selectedLanguage,
    replyMessage,
    recentlyUsedEmoticon,
  } = useSelector((state) => state.chat);
  let username = attendeeData.name;
  let type = "private";
  let groupID = false;
  let companyID = attendeeData.company_id;
  let senderID = authSession.user_unique_key;
  let receiverID = attendeeData?.user_id ?? "";

  if (attendeeData.type == "group") {
    username = attendeeData.groupName;
    type = "group";
    groupID = attendeeData.groupId;
    receiverID = attendeeData.groupId;
    companyID = authSession.company_unique_key;
  }

  const [showPicker, setShowPicker] = useState(false);
  const [isTyping, setIsTyping] = useState(false);
  const inputFocus = useRef(null);
  const emojiRef = useRef(null);

  const [showSuggester, setShowSuggester] = useState(false);
  const [userTypeInput, setUserTypeInput] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [menuOpen, setMenuOpen] = useState(false);
  const [attachmentShow, setAttachmentShow] = useState(false);
  const [selectedFile, setSelectedFile] = useState([]);
  const [displayURL, setDisplayURL] = useState([]);
  const [sendMessageButton, setSendMessageButton] = useState(false);
  const [isFocus, setIsFocus] = useState(false);

  const selectFocus = useRef();
  const contentEdit = useRef(null);
  const receiverIdRef = useRef(receiverID);
  const attachmentIdRef = useRef(selectedFile);
  const displayUrlIdRef = useRef(displayURL);
  const [value, setValue] = useState({ [`chat_message_${receiverID}`]: "" });
  const sessionThemeData = useSelector(
    (state) => state.authUserDetail.sessionThemeData,
  );

  useEffect(() => {
    if (attendeeData.type != "group") {
      setChatUser([]);
    }
  }, [attendeeData.type]);

  useEffect(() => {
    if (componentType === "editMessage") {
      let newComment = editableData.message;
      newComment = newComment
        ?.split(`<a  onmouseenter="window.getMentionUserProfile(event,'`)
        ?.join("@@@__");

      newComment = newComment
        ?.split(
          `','${editableData.msgUniqueID}')" onmouseleave="window.removeMentionUserProfile(event)"><b>`,
        )
        ?.join("^^__");
      newComment = newComment?.split("</b></a>")?.join("@@@^^^");
      newComment = newComment?.split(`<span class="emoji-style">`)?.join("");
      newComment = newComment?.split(`</span>`)?.join("");
      setValue({
        ...value,
        [`chat_message_${receiverID}`]: newComment,
      });
    }
  }, [componentType]);

  useEffect(async () => {
    receiverIdRef.current = receiverID;
  }, [receiverID]);

  useEffect(async () => {
    attachmentIdRef.current = selectedFile;
  }, [selectedFile]);

  useEffect(async () => {
    displayUrlIdRef.current = displayURL;
  }, [displayURL]);

  useEffect(async () => {
    if (groupID) {
      if (contentEdit?.current != "") {
        contentEdit?.current?.focus();
      }
    } else {
      inputFocus?.current?.focus();
    }
  }, [attendeeData]);

  const [removeTyping] = useState(() =>
    debounce(() => setIsTyping(false), 1000),
  );

  useEffect(() => {
    if (componentType === "footer") {
      userTyping(isTyping);
    }
  }, [isTyping]);

  useEffect(() => {
    window.$("#selectUsers").click();
  }, [showSuggester]);

  useEffect(async () => {
    if (groupID) {
      setShowSuggester(false);
    }
  }, [groupID]);

  useEffect(async () => {
    if (groupID) {
      setUserTypeInput(true);
    } else {
      setUserTypeInput(false);
    }
    setSelectedFile([]);
    setAttachmentShow(false);
    dispatch(setSelectedFileName([]));
    setSendMessageButton(false);
    setDisplayURL([]);
    dispatch(setSelectedFileType([]));
  }, [attendeeData]);

  const submitChatForm = async (chatValue = null) => {
    event?.preventDefault();
    const { [`chat_message_${receiverID}`]: msgData } = value;
    const chat_message = chatValue === null ? msgData : chatValue;

    if (chat_message) {
      /////////validateBadWordList
      if (validateBadWordList(chat_message)) {
        setValue({ ...value, [`chat_message_${receiverID}`]: "" });
        const finalMsg = await parseEmoji(chat_message);

        let chatObj = {
          senderID: senderID,
          message: finalMsg,
          timestamp: Date.now(),
          receiverID:
            Object.keys(replyMessage).length === 0
              ? type === "private"
                ? receiverID
                : groupID
              : replyMessage.senderID === senderID
              ? replyMessage.receiverID
              : replyMessage.senderID,
          company_id: companyID,
          documentID:
            type === "private"
              ? privateChatID([senderID, receiverID])
              : groupID,
          type,
          earlyMessage:
            Object.keys(replyMessage).length === 0
              ? undefined
              : replyMessage.message,
          isReply: Object.keys(replyMessage).length === 0 ? false : true,
          earlyMsgSenderID:
            Object.keys(replyMessage).length === 0
              ? undefined
              : replyMessage.senderID,
          earlyMsgTimestamp:
            Object.keys(replyMessage).length === 0
              ? undefined
              : replyMessage.timestamp,
          deleted: false,
        };

        if (Object.keys(replyMessage).length === 0) {
          const ObjPayload = {
            senderID: senderID,
            event_type: "msg-notification", // typing-start / typing-end / call-notification / msg-notification
            receiverID: type == "private" ? receiverID : groupID, // receiverID or groupID
            type: type == "private" ? "private" : "group", // private or group
            message: chat_message,
            company_id: companyID,
            timestamp: Date.now(),
            documentID:
              type === "private"
                ? privateChatID([senderID, receiverID])
                : groupID,
          };

          _sendEvent(ObjPayload);
        }

        await _sendChatMessage(chatObj);
        inputFocus?.current?.focus();
        setSendMessageButton(false);
        dispatch(setReplyMessage({}));
      } else {
        setChatBadWord(true);
      }
    }
  };

  const submitChatForGroup = async (chatValue = null) => {
    const { [`chat_message_${receiverID}`]: msgData } = value;
    const chat_message = chatValue === null ? msgData : chatValue;

    if (chat_message || contentEdit?.current?.innerHTML) {
      /////////validateBadWordList
      if (validateBadWordList(chat_message)) {
        let message = chat_message
          ? chat_message
          : contentEdit.current.innerHTML;
        let receiverID = receiverIdRef.current
          ? receiverIdRef.current
          : receiverID;
        let groupID = receiverIdRef.current ? receiverIdRef.current : groupID;

        const msgUniqueID = uuidv4();
        let newComment = chat_message;
        newComment = newComment
          ?.split("@@@__")
          ?.join(`<a  onmouseenter="window.getMentionUserProfile(event,'`);

        newComment = newComment
          ?.split("^^__")
          ?.join(
            `','${msgUniqueID}')" onmouseleave="window.removeMentionUserProfile(event)"><b>`,
          );
        newComment = newComment?.split("@@@^^^")?.join("</b></a>");

        let notificationMsgData = chat_message;
        notificationMsgData = notificationMsgData
          ?.split(" ")
          ?.join("__")
          ?.split("__");
        let isJoined = true;
        let notificationMsg = "";
        notificationMsgData.forEach((item) => {
          if (item === "@@@") {
            isJoined = false;
          }
          if (isJoined === true) {
            notificationMsg = notificationMsg.concat(" ", item);
          }
          if (item.includes("@@@^^^")) {
            isJoined = true;
          }
        });

        if (newComment != "") {
          message = newComment.trim();
          const finalMsg = await parseEmoji(message);
          setValue({ ...value, [`chat_message_${receiverID}`]: "" });
          let chatObj = {
            msgUniqueID,
            senderID: senderID,
            message: finalMsg,
            timestamp: Date.now(),
            receiverID:
              Object.keys(replyMessage).length === 0
                ? type === "private"
                  ? receiverID
                  : groupID
                : replyMessage.receiverID,
            company_id: companyID,
            documentID:
              type === "private"
                ? privateChatID([senderID, receiverID])
                : groupID,
            type,
            earlyMessage:
              Object.keys(replyMessage).length === 0
                ? undefined
                : replyMessage.message,
            isReply: Object.keys(replyMessage).length === 0 ? false : true,
            earlyMsgSenderID:
              Object.keys(replyMessage).length === 0
                ? undefined
                : replyMessage.senderID,
            earlyMsgTimestamp:
              Object.keys(replyMessage).length === 0
                ? undefined
                : replyMessage.timestamp,
            deleted: false,
          };

          if (Object.keys(replyMessage).length === 0) {
            const ObjPayload = {
              senderID: senderID,
              event_type: "msg-notification", // typing-start / typing-end / call-notification / msg-notification
              receiverID: type == "private" ? receiverID : groupID, // receiverID or groupID
              type: type == "private" ? "private" : "group", // private or group
              message: notificationMsg,
              original_message: message,
              company_id: companyID,
              timestamp: Date.now(),
              documentID:
                type === "private"
                  ? privateChatID([senderID, receiverID])
                  : groupID,
            };
            _sendEvent(ObjPayload);
          }

          await _sendChatMessage(chatObj);
          setSendMessageButton(false);
          setChatUser([]);
          dispatch(setReplyMessage({}));
        }
      } else {
        setChatBadWord(true);
      }
    }
  };

  const userTyping = async (isTyping) => {
    const updatedTypingData = { ...attendeeData };
    updatedTypingData["is_typing"] = isTyping ? senderID : "";
    const ObjPayload = {
      senderID: senderID,
      event_type: isTyping ? "typing-start" : "typing-end", // typing-start / typing-end / call-notification / msg-notification
      receiverID:
        type == "private" ? attendeeData?.user_id : attendeeData.groupId, // receiverID or groupID
      type: type == "private" ? "private" : "group", // private or group
      message: "typing",
      company_id: companyID,
      timestamp: Date.now(),
      documentID:
        type === "private"
          ? privateChatID([senderID, attendeeData?.user_id])
          : attendeeData.groupId,
    };
    _sendEvent(ObjPayload);
  };

  const handleChange = (e) => {
    if (!e.target.value || e.target.value.length <= 1000) {
      let msg = e.target.value;

      setValue({
        ...value,
        [`chat_message_${receiverID}`]: msg,
      });
      setIsTyping(true);
      removeTyping();
    } else {
      alert(alertMsg.maxLimit);
    }
    if (contentEdit?.current != "") {
      contentEdit?.current?.focus();
    }
  };

  const onEmojiClick = (emojiObject) => {
    const { [`chat_message_${receiverID}`]: msg } = value;
    let newMsg = "";
    let finalCursorPosition = contentEdit?.current?.selectionStart;
    if (groupID) {
      if (chatUser.length > 0) {
        const InputChatMessage = msg
          ?.split("@@@__")
          ?.join("C2-P@")
          ?.split("^^__")
          ?.join("C2-P@")
          ?.split("@@@^^^")
          ?.join("C2-P@")
          ?.split("C2-P@");
        let UpdatedInputChatMessage = [...InputChatMessage];
        chatUser.forEach((item) => {
          const isExist = UpdatedInputChatMessage.filter(
            (filterItem) => filterItem === item.id,
          );
          if (isExist.length > 0) {
            const NewData = UpdatedInputChatMessage.filter(
              (filterItem) => filterItem !== item.id,
            );
            UpdatedInputChatMessage = NewData;
          }
        });
        const NewInputMessage = UpdatedInputChatMessage.join("");
        const HalfValue = NewInputMessage?.slice(
          0,
          contentEdit?.current?.selectionStart,
        );
        chatUser.forEach((item) => {
          if (HalfValue.includes(item.display)) {
            finalCursorPosition =
              finalCursorPosition + (5 + item.id.length + 4 + 6);
          }
        });
      }
      newMsg = [
        msg?.slice(0, finalCursorPosition),
        emojiObject.emoji,
        msg?.slice(finalCursorPosition),
      ].join("");
    } else {
      newMsg = [
        msg?.slice(0, inputFocus.current.selectionStart),
        emojiObject.emoji,
        msg?.slice(inputFocus.current.selectionStart),
      ].join("");
    }

    setValue({
      ...value,
      [`chat_message_${receiverID}`]: newMsg,
    });
    setShowPicker(false);
    setIsTyping(true);
    removeTyping();
    if (groupID) {
      contentEdit.current.selectionStart =
        contentEdit.current.selectionStart + 1;
      contentEdit.current.selectionEnd = contentEdit.current.selectionEnd + 1;
      contentEdit?.current?.focus();
    } else {
      inputFocus.current.selectionStart = inputFocus.current.selectionStart + 1;
      inputFocus.current.selectionEnd = inputFocus.current.selectionEnd + 1;
      inputFocus?.current?.focus();
    }
  };

  // const selectRecentEmoji = (emoji) =>{
  //  const { [`chat_message_${receiverID}`]: msg } = value;
  // }

  const userDetails = () => {
    let list = [];
    const user = attendeeData.attendeeId.map((u) => ({
      u,
      ...allAttendeeList.find((v) => v?.user_id == u),
    }));
    user.forEach((v) => {
      let data = { value: v?.user_id, label: v.name };
      list.push(data);
    });
    setDropDownAttendee(list);
  };

  const userMainDetails = () => {
    let list = [
      {
        id: "all_c2p_tagged",
        display: "all",
        infoText: "Notify every online member in this group",
      },
    ];
    // let list = [];
    attendeeData.attendeeId.map((u) => {
      const isUserExist = allAttendeeList.findIndex(
        (item) => item.user_id === u,
      );
      if (isUserExist > -1) {
        const UserData = allAttendeeList[isUserExist];
        let data = {
          id: UserData.user_id,
          display: UserData.name,
          online: UserData.online,
        };
        list.push(data);
      }
    });
    setDropDownAttendeeMain(list);
  };

  useEffect(() => {
    if (
      allAttendeeList.length > 0 &&
      Object.keys(attendeeData).length > 0 &&
      attendeeData?.attendeeId
    ) {
      userDetails();
      userMainDetails();
    }
  }, [allAttendeeList, attendeeData]);

  const handleListChange = (option) => {
    setShowSuggester(false);
    let mess = option.label;
    let message = "<strong>" + mess + "</strong>";
    setValue({
      ...value,
      [`chat_message_${receiverID}`]: msg ? msg + message : message,
    });
    contentEdit?.current?.focus();
  };

  const submitChatOnEnter = (e) => {
    if (e.keyCode === 13 && !e.shiftKey) {
      if (isNetworkConnected) {
        if (componentType === "editMessage") {
          updateMessage();
        } else {
          attachmentUpload(e);
          checkEmoticons("private");
        }
      } else {
        networkToastHandler();
      }
    }
  };

  const UpdateEmoticons = (emoji) => {
    const tmpRecentlyUsedEmoticon = [...recentlyUsedEmoticon];
    tmpRecentlyUsedEmoticon.unshift(emoji);
    if (tmpRecentlyUsedEmoticon.length > 5) {
      tmpRecentlyUsedEmoticon.pop();
    }
    dispatch(setRecentlyUsedEmoticons(tmpRecentlyUsedEmoticon));
    const payload = {
      user_id: senderID,
      company_id: companyID,
      recentlyUsedEmoticon: tmpRecentlyUsedEmoticon,
    };
    _updateRecentlyUsedEmoticons(payload);
  };

  const checkEmoticons = (type) => {
    const { [`chat_message_${receiverID}`]: chat_message } = value;
    let partcilaurEmoji = emoticon.find((u) =>
      u.emoticons.includes(chat_message),
    );
    if (partcilaurEmoji?.emoji) {
      UpdateEmoticons(partcilaurEmoji?.emoji);
      if (type === "private") {
        submitChatForm(partcilaurEmoji?.emoji);
      } else {
        submitChatForGroup(partcilaurEmoji?.emoji);
      }
    } else {
      if (type === "private") {
        submitChatForm();
      } else {
        submitChatForGroup();
      }
    }
  };

  const handleKeyDown = (e) => {
    if (e.keyCode === 13 && !e.shiftKey) {
      if (isNetworkConnected) {
        // submit chat on enter
        if (componentType === "editMessage") {
          updateMessage();
        } else {
          checkEmoticons("group");
          attachmentUpload(e);
        }
        e.preventDefault();
      } else {
        networkToastHandler();
      }
    }
    if (event.key === "Backspace") {
      if (groupID) {
        const { [`chat_message_${receiverID}`]: msg } = value;
        const UserTaggedData = msg
          ?.split("@@@^^^")
          ?.join("@@@^^^C2-P@")
          ?.split("C2-P@"); //split more than two tagged user
        const newChatUser = [...chatUser];
        chatUser.forEach((item, index) => {
          const checkData = `@@@__${item.id}^^__${item.display}@@@^^^`;
          const isExist = UserTaggedData.filter(
            (filterItem) => filterItem === checkData,
          );
          if (isExist.length === 0) {
            newChatUser.splice(index, 1);
          }
        });
        setChatUser(newChatUser);
      }
    }
  };

  const addAttachment = () => {
    setAttachmentShow(true);
  };

  const attachmentUpload = async () => {
    let selectedFile = attachmentIdRef.current
      ? attachmentIdRef.current
      : selectedFile;
    let displayURL = displayUrlIdRef.current
      ? displayUrlIdRef.current
      : displayURL;
    if (selectedFile.length > 0) {
      dispatch(setIsUploadingData(displayURL));
      setSendMessageButton(false);
      setAttachmentShow(false);
      let Obj = {
        companyID,
        message: "c2-attach-files",
        timestamp: Date.now(),
        senderID,
        receiverID: type === "private" ? receiverID : groupID,
        documentID:
          type === "private" ? privateChatID([senderID, receiverID]) : groupID,
        type: type,
        selectedFile,
      };

      await fileUpload(Obj);
      setSelectedFile([]);
      dispatch(setSelectedFileName([]));
      setDisplayURL([]);
      dispatch(setSelectedFileType([]));
    }
  };

  const selectedFileStore = (files) => {
    let selectedFileData = selectedFile;
    let selectedFileNameData = [...selectedFileName];
    let selectedFileTypeData = [...selectedFileType];
    let fileUnSupportData = "";
    for (var i = 0; i < files.length; i++) {
      if (files[i].size <= _5gb) {
        selectedFileData.push(files[i]);
        selectedFileNameData.push(files[i].name);
        const filename = files[i].type.split("/");
        let filetype = filename[1];
        selectedFileTypeData.push(filetype);
      } else {
        fileUnSupportData = `${fileUnSupportData} ${files[i].name}`;
      }
    }
    if (fileUnSupportData != "") {
      setSizeErrorData(fileUnSupportData);
    }
    setSelectedFile(selectedFileData);
    dispatch(setSelectedFileName(selectedFileNameData));
    dispatch(setSelectedFileType(selectedFileTypeData));
    if (selectedFileData.length > 0) {
      setSendMessageButton(true);
    }
  };

  const onFileChange = (e) => {
    const files = e.target.files;
    selectedFileStore(files);
  };

  const hideFileSizeError = () => {
    setSizeErrorShow(false);
    setSizeErrorData("");
  };

  useEffect(() => {
    if (sizeErrorData != "") {
      setSizeErrorShow(true);
    }
  }, [sizeErrorData]);

  const cancelUpload = (index) => {
    let updatedSelectedFileType = [...selectedFileType];
    updatedSelectedFileType.splice(index, 1);
    dispatch(setSelectedFileType([...updatedSelectedFileType]));
    let updatedSelectedFile = selectedFile;
    updatedSelectedFile.splice(index, 1);
    setSelectedFile([...updatedSelectedFile]);
    if (updatedSelectedFileType.length === 0) {
      setAttachmentShow(false);
      setSendMessageButton(false);
    }
    let updatedDisplayURL = displayURL;
    updatedDisplayURL.splice(index, 1);
    setDisplayURL([...updatedDisplayURL]);
    let updatedSelectedFileName = [...selectedFileName];
    updatedSelectedFileName.splice(index, 1);

    dispatch(setSelectedFileName([...updatedSelectedFileName]));
  };

  const previewFile = (type, files) => {
    const filesData =
      type === "paste"
        ? files
        : document.querySelector("input[type=file]").files;
    getImagesURL(filesData).then((res) => {
      setDisplayURL([...displayURL, ...res]);
    });
  };

  const { [`chat_message_${receiverID}`]: msg } = value;

  useEffect(() => {
    if (msg == "" || msg == undefined || msg == "undefined") {
      setSendMessageButton(false);
    } else {
      setSendMessageButton(true);
    }
  }, [msg]);

  const checkSelection = () => {
    document.onmouseup = () => {};
  };

  OnClickOutside(emojiRef, () => setShowPicker(!showPicker));
  useAutosizeTextArea(
    groupID ? contentEdit?.current : inputFocus?.current,
    value,
  );

  const renderSuggestion = (entry) => {
    return (
      <>
        <span className="d-flex align-items-center">
          <span
            className={`status marginRight-5 ${entry.online ? "online" : ""} `}
          ></span>{" "}
          {entry.display}
          <span className="marginLeft-5 fw--500">{entry?.infoText}</span>
        </span>
      </>
    );
  };

  const onAdd = (id, display) => {
    const newUser = { id: id, display: display };
    setChatUser([...chatUser, newUser]);
  };

  const cancelUpdateMessageArea = async () => {
    editMessageHandler("");
  };
  const updateMessage = async () => {
    const { [`chat_message_${receiverID}`]: chat_message } = value;
    //////validateBadWordList
    if (validateBadWordList(chat_message)) {
      let newComment = chat_message;
      newComment = newComment
        ?.split("@@@__")
        ?.join(`<a  onmouseenter="window.getMentionUserProfile(event,'`);

      newComment = newComment
        ?.split("^^__")
        ?.join(
          `','${editableData.msgUniqueID}')" onmouseleave="window.removeMentionUserProfile(event)"><b>`,
        );
      newComment = newComment?.split("@@@^^^")?.join("</b></a>");
      newComment = newComment?.trim();
      const updatedEditableData = { ...editableData };
      const finalMsg = await parseEmoji(newComment);
      updatedEditableData["message"] = finalMsg;
      updatedEditableData["messageID"] = editableData.id;

      await _editChatMessage(updatedEditableData);
      editMessageHandler("");
    } else {
      setChatBadWord(true);
    }
  };
  const sendChatHandler = (e) => {
    attachmentUpload(e);
    checkEmoticons("group");
  };

  const networkToastHandler = () => {
    toast.error(alertMsg.connectionLost, {
      position: toast.POSITION.TOP_CENTER,
    });
  };

  const keyDownHandler = (e) => {
    handleKeyDown(e);
    checkSelection();
  };

  const pasteHandler = (event) => {
    const files = event.clipboardData.files;
    if (files.length > 0) {
      selectedFileStore(files);
      previewFile("paste", files);
      addAttachment();
    }
  };
  return (
    <>
      <ToastContainer />
      <StylesViaJss>
        <div className={`foot ${isFocus ? "foot_focus" : ""}`}>
          {Object.keys(replyMessage).length > 0 && (
            <h3 className="d-flex align-items-start mb-1 mt-0 btm-border">
              <div className="foot-inner">
                <i className="text-grey">{replyMessage?.message}</i>
                <div className="time ml-0 mt-1 pb-2">{`${
                  allAttendeeList.find(
                    (findItem) => findItem.user_id === replyMessage.senderID,
                  )?.name ?? ""
                }, ${timestampToDateTime(replyMessage?.timestamp)}`}</div>
              </div>
              <a
                href="javascript:void(0);"
                className="foot-close-svg"
                onClick={() => dispatch(setReplyMessage({}))}
              >
                <svg width="10px" viewBox="6.29 6.34 11.31 11.31">
                  <path
                    fill="#B8C0C9"
                    d="m16.192 6.344-4.243 4.242-4.242-4.242-1.414 1.414L10.535 12l-4.242 4.242 1.414 1.414 4.242-4.242 4.243 4.242 1.414-1.414L13.364 12l4.242-4.242z"
                  ></path>
                </svg>
              </a>
            </h3>
          )}

          {userTypeInput ? (
            <div className="msg__wrapper">
              <MentionsInput
                spellCheck="false"
                type="text"
                id="text"
                min="1"
                max="10"
                className="msg"
                classNames={Style}
                name="chat_message"
                inputRef={contentEdit}
                value={msg ? msg : ""}
                onChange={handleChange}
                markup="@@@____id__^^____display__@@@^^^"
                displayTransform={(id) => `${id}`}
                onKeyDown={(e) => keyDownHandler(e)}
                placeholder={`Message ${username}...`}
                onPaste={(e) => pasteHandler(e)}
              >
                <Mention
                  trigger="@"
                  className={`${Style.mentions__mention} name_mentions`}
                  data={dropDownAttendeeMain}
                  renderSuggestion={renderSuggestion}
                  markup="@@@____id__^^____display__@@@^^^"
                  onAdd={onAdd}
                />
              </MentionsInput>
            </div>
          ) : (
            <div className="text-area-div">
              <textarea
                spellCheck="false"
                type="text"
                ref={inputFocus}
                id="text"
                min="1"
                max="10"
                className="msg"
                name="chat_message"
                value={msg ? msg : ""}
                placeholder={`Message ${username}...`}
                onChange={(e) => handleChange(e)}
                onKeyDown={(e) => submitChatOnEnter(e)}
                autoComplete="off"
                onFocus={() => setIsFocus(true)}
                onBlur={() => setIsFocus(false)}
                onPaste={(e) => pasteHandler(e)}
              />
            </div>
          )}
          {attachmentShow ? (
            <AttachmentData
              cancelUpload={cancelUpload}
              selectedFileName={selectedFileName}
              selectedFileType={selectedFileType}
              displayURL={displayURL}
              sessionThemeData={sessionThemeData}
            />
          ) : null}
          {showSuggester ? (
            <Select
              ref={selectFocus}
              id="selectUsers"
              className="form-control select_userlist"
              onChange={(e) => handleListChange(e)}
              options={dropDownAttendee}
              menuIsOpen={menuOpen}
            />
          ) : null}
          <div className="text-area-actions picker-container position-relative">
            <div className="d-flex align-items-center">
              <button type="submit" className="btn-icon">
                <svg
                  onClick={() => setShowPicker((val) => !val)}
                  height="19"
                  viewBox="2 2 20 20"
                >
                  <path
                    fill="#b8c0c9"
                    d="M12 2C6.486 2 2 6.486 2 12s4.486 10 10 10 10-4.486 10-10S17.514 2 12 2zm0 18c-4.411 0-8-3.589-8-8s3.589-8 8-8 8 3.589 8 8-3.589 8-8 8z"
                  ></path>
                  <path
                    fill="#b8c0c9"
                    d="M14.829 14.828a4.055 4.055 0 0 1-1.272.858 4.002 4.002 0 0 1-4.875-1.45l-1.658 1.119a6.063 6.063 0 0 0 1.621 1.62 5.963 5.963 0 0 0 2.148.903 6.042 6.042 0 0 0 2.415 0 5.972 5.972 0 0 0 2.148-.903c.313-.212.612-.458.886-.731.272-.271.52-.571.734-.889l-1.658-1.119a4.017 4.017 0 0 1-.489.592z"
                  ></path>
                  <circle fill="#b8c0c9" cx="8.5" cy="10.5" r="1.5"></circle>
                  <circle
                    fill="#b8c0c9"
                    cx="15.493"
                    cy="10.493"
                    r="1.493"
                  ></circle>
                </svg>
              </button>
              {componentType === "footer" && (
                <button
                  type="submit"
                  onClick={(e) => addAttachment(e)}
                  className="btn-icon"
                >
                  <svg className="rotate--320" height="14" viewBox="2 5 20 14">
                    <path
                      fill="#b8c0c9"
                      d="M17.004 5H9c-1.838 0-3.586.737-4.924 2.076C2.737 8.415 2 10.163 2 12c0 1.838.737 3.586 2.076 4.924C5.414 18.263 7.162 19 9 19h8v-2H9c-1.303 0-2.55-.529-3.51-1.49C4.529 14.55 4 13.303 4 12c0-1.302.529-2.549 1.49-3.51C6.45 7.529 7.697 7 9 7h8V6l.001 1h.003c.79 0 1.539.314 2.109.886.571.571.886 1.322.887 2.116a2.966 2.966 0 0 1-.884 2.11A2.988 2.988 0 0 1 17 13H9a.99.99 0 0 1-.698-.3A.991.991 0 0 1 8 12c0-.252.11-.507.301-.698A.987.987 0 0 1 9 11h8V9H9c-.79 0-1.541.315-2.114.889C6.314 10.461 6 11.211 6 12s.314 1.54.888 2.114A2.974 2.974 0 0 0 9 15h8.001a4.97 4.97 0 0 0 3.528-1.473 4.967 4.967 0 0 0-.001-7.055A4.95 4.95 0 0 0 17.004 5z"
                    ></path>
                  </svg>
                  <input
                    spellCheck="false"
                    type="file"
                    multiple
                    onChange={(e) => {
                      onFileChange(e);
                      previewFile("select");
                    }}
                    accept=".jpg, .jpeg, .png, .doc, .pdf, .gif, .csv, .mp4, .jfif, .mp3"
                    onClick={(e) => {
                      e.target.value = null;
                    }}
                    onKeyDown={(e) => {
                      attachmentUpload(e);
                      submitChatForGroup();
                    }}
                    onKeyPress={(e) => {
                      e.key === "Enter" && e.preventDefault();
                    }}
                  />
                </button>
              )}
              {recentlyUsedEmoticon?.map((emojiItem, emojiIndex) => (
                <button
                  type="submit"
                  className="btn-icon"
                  key={emojiIndex}
                  onClick={() => onEmojiClick({ emoji: emojiItem })}
                >
                  <span className="recent-emoji">{emojiItem}</span>
                </button>
              ))}
            </div>
            <span className="d-flex order-3">
              {componentType === "editMessage" ? (
                <>
                  <Button
                    className="btn btn-light"
                    type="submit"
                    id="submit"
                    onClick={() => cancelUpdateMessageArea()}
                  >
                    Cancel
                  </Button>
                  <Button
                    className="btn btn-orange"
                    type="submit"
                    id="submit"
                    onClick={() => updateMessage()}
                  >
                    Save
                  </Button>
                </>
              ) : (
                <button
                  className={` ${
                    sendMessageButton
                      ? "active btn-icon"
                      : "btn-icon btn-disabled"
                  }`}
                  type="submit"
                  id="submit"
                  onClick={(e) =>
                    isNetworkConnected
                      ? sendChatHandler(e)
                      : networkToastHandler(e)
                  }
                  disabled={!sendMessageButton}
                >
                  <svg height="15" viewBox="6 5.29 13.41 13.41">
                    <path
                      fill="#b8c0c9"
                      d="m11.293 17.293 1.414 1.414L19.414 12l-6.707-6.707-1.414 1.414L15.586 11H6v2h9.586z"
                    ></path>
                  </svg>
                </button>
              )}
            </span>

            {showPicker && (
              <div ref={emojiRef} className="emoji-main">
                <EmojiPicker
                  height={480}
                  // width={350}
                  onEmojiClick={onEmojiClick}
                  skinTonePickerLocation="SEARCH"
                />
              </div>
            )}
          </div>

          {chatBadWord && (
            <ChatBadWord
              showBadWord={chatBadWord}
              handleClose={() => setChatBadWord(false)}
              selectedLanguage={selectedLanguage}
            />
          )}
        </div>
        {!isNetworkConnected && (
          <div className="trying-connect">
            <p className="network-message">Trying to connect </p>
            <div className="trying-connect-loader">
              <div className="loader_spinner__box__sm">
                <div className="spin"></div>
              </div>
            </div>
          </div>
        )}

        <FileSize
          fileSizeShow={sizeErrorShow}
          handleClose={hideFileSizeError}
          fileName={sizeErrorData}
          selectedLanguage={selectedLanguage}
        />
      </StylesViaJss>
    </>
  );
};

export default memo(UserInputMessageArea);

// eslint-disable-next-line react/display-name
const AttachmentData = React.memo(
  ({
    cancelUpload,
    selectedFileName,
    selectedFileType,
    displayURL,
    onDocumentLoadSuccess,
    pageNumber,
    sessionThemeData,
  }) => {
    return (
      <div className="d-flex overflow-x-auto pt-2 pb-2">
        {selectedFileName && selectedFileName.length > 0
          ? selectedFileName.map((fileName, index) => (
              <div className="attachment_box" key={index}>
                <div className="attachment__container attached_file">
                  <div
                    className={`attached__icon ${
                      selectedFileType[index] == "png" ||
                      selectedFileType[index] == "jpg" ||
                      selectedFileType[index] == "jpeg" ||
                      selectedFileType[index] == "jfif"
                        ? "chat-img"
                        : ""
                    }`}
                  >
                    <SelectedFileToDisplay
                      selectedFileType={selectedFileType[index]}
                      displayURL={displayURL[index]}
                      selectedFileName={fileName}
                      onDocumentLoadSuccess={onDocumentLoadSuccess}
                      pageNumber={pageNumber}
                      sessionThemeData={sessionThemeData}
                    />
                    <button
                      onClick={() => cancelUpload(index)}
                      className="icon_container btn-icon"
                    >
                      <svg width="82%" viewBox="6.29 6.34 11.31 11.31">
                        <path
                          fill="#19171D"
                          d="m16.192 6.344-4.243 4.242-4.242-4.242-1.414 1.414L10.535 12l-4.242 4.242 1.414 1.414 4.242-4.242 4.243 4.242 1.414-1.414L13.364 12l4.242-4.242z"
                        ></path>
                      </svg>
                    </button>
                  </div>
                </div>
              </div>
            ))
          : null}
      </div>
    );
  },
);
