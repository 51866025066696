import { API_BASE_URL, API_EVENT_BASE_URL } from "../../constants";
import React from "react";
import axios from "axios";
import moment from "moment";
import { components } from "react-select";
import { v4 as uuidv4 } from "uuid";
import Resizer from "react-image-file-resizer";
import { toast } from "react-toastify";
import { Howl } from "howler";

import {
  _sendChatMessage,
  _sendEvent,
  _unreadMessageCountUpdate,
  _userUpdate,
} from "../../SocketIo";
import store from "../../store";
import { setUnreadChatMessage } from "../redux/chatSlice";
import { getTransport } from "../transport";
import { UPLOAD_API_URL } from "../../constants";
import {
  setNetworkConnected,
  setSharedFileProgressBar,
} from "../redux/callSlice";
import { createAudioTracks, createVideoTracks } from "./Lib";
import {
  setCameraDevice,
  setHasAudioPermission,
  setHasVideoPermission,
  setMicrophoneDevice,
  setSpeakerDevice,
} from "./Lib/libSlice";
import {
  setImagePreviewData,
  setImagePreviewOpen,
  setProgress,
} from "../redux/chatSlice";
import {
  fetchWasabiImageUploadURL,
  uploadAttachmentFileWasabi,
  uploadWasabiFile,
} from "./wasabiFileUpload";
import { alertMsg } from "../util/alertMsg";
import swal from "sweetalert";

import img5MB from "../../assets/images/img5MB.jpg";

var my_window = "";
var API_UploadFile_URL = UPLOAD_API_URL;

const transport = getTransport();
export const notifyDesktopApp = (title, message, sender, type, useravtar) => {
  transport.sendEvent({
    name: "chat-updated",
    title,
    message,
    sender,
    useravtar,
    type,
  });
};
export const notifyAppToggleSketchBoard = () => {
  transport.sendEvent({
    name: "sketch-Board-clicked",
  });
};
export const privateChatID = (obj) => {
  const sortAlphaNum = (a, b) => a.localeCompare(b, "en", { numeric: true });
  return obj.sort(sortAlphaNum).join("_");
};

export const _5gb = 5368709120;

export const timestampToDateTimeDelete = (timeString) => {
  let date = "";
  let todayDate = moment(Date.now()).format("MMM DD YYYY");
  let yesterdayDate = moment(Date.now() - 1).format("MMM DD YYYY");
  let timestamp = moment(timeString).format("MMM DD YYYY");

  todayDate == timestamp
    ? (date = "Today at " + moment(timeString).format("h:mm A"))
    : yesterdayDate == timestamp
    ? (date = "Yesterday at " + moment(timeString).format("h:mm A"))
    : (date =
        moment(timeString).format("MMM DD") +
        " at " +
        moment(timeString).format("h:mm A"));

  return date;
};

export const timestampToDateTime = (timeString) => {
  let date = "";
  let todayDate = moment(Date.now()).format("MMM DD YYYY");
  let yesterdayDate = moment(Date.now() - 1).format("MMM DD YYYY");
  let timestamp = moment(timeString).format("MMM DD YYYY");

  todayDate == timestamp
    ? (date = moment(timeString).format("h:mm A"))
    : yesterdayDate == timestamp
    ? (date = "Yesterday, " + moment(timeString).format("h:mm A"))
    : (date = moment(timeString).format("MMM DD, h:mm A"));
  return date;
};

export const timestampToDateTimeFilter = (timeString) => {
  return moment(timeString).format("MMM DD YYYY");
};

export const apiClient = axios.create({
  baseURL: API_BASE_URL,
  headers: {
    Authorization: "Basic NTBkNzcwY2VhNTU=",
    "Content-Type": "application/json",
  },
});

apiClient.interceptors.request.use(
  (config) => {
    return config;
  },
  (error) => Promise.reject(error),
);

apiClient.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    return error.response;
  },
);

export const apiClientEvent = axios.create({
  baseURL: API_EVENT_BASE_URL,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json; charset=utf-8",
  },
});

apiClient.interceptors.request.use(
  (config) => {
    return config;
  },
  (error) => Promise.reject(error),
);

apiClient.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    return error.response;
  },
);

export const logoutAllDevices = async (paramObj) => {
  try {
    const res = await apiClientEvent("logout", {
      method: "POST",
      data: paramObj,
    });
    if (res?.status === 200) {
      return { res: res, status: true };
    } else {
      return { res: res, status: false };
    }
  } catch (e) {
    return { res: {}, status: false };
  }
};

export const startRecordingApi = async (paramObj) => {
  try {
    const res = await apiClient("v1/vhosts/default/apps/app:startRecord", {
      method: "POST",
      data: paramObj,
    });
    if (res?.status === 200) {
      return { res: res, status: true };
    } else {
      return { res: res, status: false };
    }
  } catch (e) {
    return { res: {}, status: false };
  }
};

export const stopRecordingApi = async (paramObj) => {
  try {
    const res = await apiClient("v1/vhosts/default/apps/app:stopRecord", {
      method: "POST",
      data: paramObj,
    });
    if (res?.status === 200) {
      return { res: res, status: true };
    } else {
      return { res: res, status: false };
    }
  } catch (e) {
    return { res: {}, status: false };
  }
};

export const manageLoginPopup = (
  action,
  url = "",
  windowName = "",
  windowFeatures = "",
) => {
  if (action === "open") {
    my_window = window.open(url, windowName, windowFeatures);
  } else if (action === "close") {
    if (false == my_window.closed) {
      my_window.close();
    }
  }
};

export const validateBadWordList = (chatMessage) => {
  chatMessage = chatMessage.toLowerCase().replace(/[^a-zA-Z0-9]/g, " ");
  const state = store.getState();
  const { badWordList } = state.chat;
  let chatMessageArr = chatMessage?.split(" ");
  let foundBadWords = chatMessageArr.filter((el) => badWordList.includes(el));

  return foundBadWords.length > 0 ? false : true;
};

export const getAuthSession = () => {
  return JSON.parse(localStorage.getItem("auth_session"));
};

// ---------------------- Upload File -----------------//
export const getImagesURL = (filesData) => {
  var filePromises = [];
  for (var i = 0; i < filesData.length; i++) {
    var promise = new Promise((resolve) => {
      let file = filesData[i];
      const reader = new FileReader();
      if (file) {
        reader.readAsDataURL(file);
      }
      reader.onload = () => {
        resolve(reader.result);
      };
    });
    filePromises.push(promise);
  }
  return Promise.all(filePromises);
};

export const getImageUrlFromDownloadUrl = async (url) => {
  const res = await fetch(url);
  let blobData = await res.blob();
  const file = new File([blobData], "image", { type: blobData.type });
  let files = [];
  files.push(file);
  const UrlRes = await getImagesURL(files);
  return UrlRes;
};
const uploadFileInWasabi = (selectedFile) => {
  let attachments = [];
  const uploadFileUrl = `${API_UploadFile_URL}upload`;
  const promises = selectedFile.map(async (item) => {
    const _file = item;
    const file_name = uuidv4();
    const fileSize = _file.size;
    if (fileSize <= _5gb) {
      const { type: fileExt } = _file;
      let _extension = fileExt?.split("/")[1];
      let base64Image = "";
      if (
        _extension === "jpg" ||
        _extension === "png" ||
        _extension === "jpeg" ||
        _extension === "jfif"
      ) {
        base64Image = await resizeFile(item);
      }

      const _response = await uploadWasabiFile(uploadFileUrl, {
        file: file_name + "." + _extension,
      });
      if (_response?.status === 200) {
        let _uploadUrl = _response?.data?.url;
        const _newResponse = await uploadAttachmentFileWasabi({
          _uploadUrl,
          _file,
        });
        if (_newResponse?.status === 200) {
          const attachmentName = file_name + "." + _extension;
          const attachment_array = attachmentName?.split(".");
          let attachment_type = attachment_array[1];
          const originalFileName = _file.name;
          let attachment = {
            attachmentName,
            attachment_type,
            originalFileName,
            imagePreview: base64Image !== "" ? base64Image : undefined,
          };
          attachments.push({ ...attachment });
          return attachments;
        }
      }
    } else {
      alert(alertMsg.fileSizeMsg);
    }
  });
  return Promise.all(promises);
};

const resizeFile = (file) =>
  new Promise((resolve) => {
    Resizer.imageFileResizer(
      file,
      80,
      80,
      "",
      10,
      0,
      (uri) => {
        resolve(uri);
      },
      "base64",
    );
  });

export const fileUpload = async (obj) => {
  const state = store.getState();
  const { companyDetail } = state.authUserDetail;
  store.dispatch(setProgress(0));
  const {
    companyID,
    senderID,
    receiverID,
    timestamp,
    message,
    type,
    documentID,
    selectedFile,
  } = obj;

  try {
    const [attachments] = await uploadFileInWasabi(selectedFile);
    if (attachments && attachments.length > 0) {
      const ObjPayload = {
        senderID: senderID,
        event_type: "msg-notification", // typing-start / typing-end / call-notification / msg-notification
        receiverID: receiverID, // receiverID or groupID
        type: type, // private or group
        message:
          attachments.length > 1
            ? "shared this file"
            : attachments[0].attachment_type === "png" ||
              attachments[0].attachment_type === "jpg"
            ? "shared this image"
            : "shared this file",
        company_id: companyID,
        timestamp: Date.now(),
        documentID:
          type === "private"
            ? privateChatID([senderID, receiverID])
            : receiverID,
      };
      _sendEvent(ObjPayload);
      _sendChatMessage({
        senderID,
        message,
        timestamp,
        receiverID,
        company_id: companyID,
        documentID,
        type,
        attachments,
        attachment_expire_seconds: companyDetail?.attachment_expire_seconds,
        deleted: false,
      });
      store.dispatch(setProgress(""));
    }
  } catch (e) {
    console.log("Error", e);
  }
};

export const attachmentsDownload = async (
  id,
  filename,
  originalName,
  setDownloadFile,
  downloadFile,
  type,
  messageDetail,
) => {
  try {
    const downloadUrl = `${API_UploadFile_URL}download`;
    const state = store.getState();
    const { authSessionUser } = state.authUserDetail;
    const getWasabiUrl = await fetchWasabiImageUploadURL(downloadUrl, {
      file: filename,
      attachment_expire_seconds: messageDetail?.attachment_expire_seconds ?? "",
      timestamp: messageDetail?.timestamp ?? "",
      company_id: authSessionUser?.company_id,
    });
    if (getWasabiUrl?.status === 200) {
      const url = getWasabiUrl?.data?.url;
      fetch(url)
        .then((res) => {
          return res.blob();
        })
        .then((blob) => {
          if (isElectron) {
            transport.sendEvent({
              name: "file-download",
              url: url,
            });
            let downloadFileData = downloadFile;
            const fileIndex = downloadFileData.indexOf(id);
            downloadFileData.splice(fileIndex, 1);
            setDownloadFile([...downloadFileData]);
          } else {
            let downloadFileData = downloadFile;
            const fileIndex = downloadFileData.indexOf(id);
            downloadFileData.splice(fileIndex, 1);
            setDownloadFile([...downloadFileData]);
            const href = window.URL.createObjectURL(blob);
            var a = document.createElement("a");
            a.download = type === "recording" ? filename : filename;
            a.href = href;
            a.style.display = "none";
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
          }
        });
    } else {
      let downloadFileData = downloadFile;
      const fileIndex = downloadFileData.indexOf(id);
      downloadFileData.splice(fileIndex, 1);
      setDownloadFile([...downloadFileData]);
      toast.error(getWasabiUrl?.data?.msg, {
        position: toast.POSITION.TOP_CENTER,
      });
    }
  } catch (e) {
    console.log("Error", e);
  }
};

export const downloadApp = async (url, setAlertDownload) => {
  let urlArr = url?.split("/");
  let filename = urlArr[urlArr.length - 1];

  fetch(url, {
    method: "GET",
  })
    .then((response) => {
      response.blob();
    })
    .then((blob) => {
      // Create blob link to download
      setAlertDownload(false);
      var link = document.createElement("a");
      var url = window.URL.createObjectURL(new Blob([blob]));
      link.setAttribute("href", url);
      link.setAttribute("download", filename);
      link.style.visibility = "hidden";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    });
};

export const getLocalStream = () => {
  navigator.mediaDevices
    .getUserMedia({ video: true, audio: true })
    .then((stream) => {
      window.localStream = stream;
      window.localAudio.srcObject = stream;
      window.localAudio.autoplay = true;
    })
    .catch((err) => {
      console.log("u got an error:" + err);
    });
};

export const outgoing_tone = new Howl({
  src: ["/assets/sound/outgoing.mp3"],
  loop: true,
});

export const call_accepted_tone = new Howl({
  src: ["/assets/sound/accepted.mp3"],
  loop: true,
});

export const getUpdateUnreadChatMessage = (senderID, type) => {
  const { user_unique_key, company_unique_key } = getAuthSession();
  const prevUnreadChatMessageData = store.getState().chat.unreadChatMessage;
  const prevUnreadChatMessage = [...prevUnreadChatMessageData];
  const msgIndex = prevUnreadChatMessage.findIndex(
    (obj) => obj.user_id === senderID,
  );

  if (msgIndex !== -1) {
    const msgElement = prevUnreadChatMessage[msgIndex];
    const updatedMsgElement = { ...msgElement };
    updatedMsgElement["count"] =
      type === "lastUserActivity" ||
      type === "notificationSound" ||
      type === "groupMute"
        ? msgElement.count
        : msgElement.count + 1;
    updatedMsgElement["timestamp"] =
      type === "notificationSound" || type === "groupMute"
        ? msgElement.timestamp
        : Date.now();
    updatedMsgElement["notification_sound"] =
      type === "notificationSound"
        ? msgElement.notification_sound === true
          ? false
          : true
        : msgElement.notification_sound;
    updatedMsgElement["group_mute"] =
      type === "groupMute"
        ? msgElement.group_mute === false
          ? true
          : false
        : msgElement.group_mute;

    prevUnreadChatMessage[msgIndex] = updatedMsgElement;
    _unreadMessageCountUpdate(updatedMsgElement);
    store.dispatch(setUnreadChatMessage(prevUnreadChatMessage));
  } else {
    const ObjData = {
      company_id: company_unique_key,
      logged_user_id: user_unique_key,
      user_id: senderID,
      count:
        type === "lastUserActivity" ||
        type === "notificationSound" ||
        type === "groupMute"
          ? 0
          : 1,
      timestamp:
        type === "notificationSound" || type === "groupMute"
          ? undefined
          : Date.now(),
      notification_sound: type === "notificationSound" ? false : true,
      group_mute: type === "groupMute" ? true : false,
    };
    prevUnreadChatMessage.push(ObjData);
    _unreadMessageCountUpdate(ObjData);
    store.dispatch(setUnreadChatMessage(prevUnreadChatMessage));
  }
};

export const NetWorkCheckInit = () => {
  store.dispatch(setNetworkConnected(window.navigator.onLine));
  window.addEventListener("offline", () => {
    store.dispatch(setNetworkConnected(false));
  });

  window.addEventListener("online", () => {
    store.dispatch(setNetworkConnected(true));
  });
};

export const updateDataToLocalStorage = (key, value, localItem) => {
  if (
    localStorage.getItem(`${localItem}`) !== undefined &&
    localStorage.getItem(`${localItem}`) !== null
  ) {
    let data = JSON.parse(localStorage.getItem(`${localItem}`));
    data[key] = value;
    localStorage.setItem(`${localItem}`, JSON.stringify(data));
  }
};

export const showMic = (micDeviceId) => {
  store.dispatch(setMicrophoneDevice(micDeviceId));
  updateDataToLocalStorage("microphoneDevice", micDeviceId, "avsetting");
};

export const saveCamera = (cameraDeviceId) => {
  store.dispatch(setCameraDevice(cameraDeviceId));
  updateDataToLocalStorage("cameraDevice", cameraDeviceId, "avsetting");
};

export const grantMicPermission = () => {
  createAudioTracks()
    .then((track) => {
      store.dispatch(setHasAudioPermission(true));
      track[0].dispose();
    })
    .catch((err) => {
      console.log("error", err);
    });
};

export const grantCameraPermission = () => {
  createVideoTracks()
    .then((track) => {
      store.dispatch(setHasVideoPermission(true));
      track[0].dispose();
    })
    .catch((err) => {
      console.log("error", err);
    });
};
export const setSpeakerHandler = (deviceId) => {
  store.dispatch(setSpeakerDevice(deviceId));
  updateDataToLocalStorage("speakerDevice", deviceId, "avsetting");
};

export const checkAvSettingExist = () => {
  if (
    localStorage.getItem("avsetting") !== undefined ||
    localStorage.getItem("avsetting") !== null
  ) {
    let data = JSON.parse(localStorage.getItem("avsetting"));
    if (Object.keys(data).length > 0) {
      data?.microphoneDevice !== "" && showMic(data?.microphoneDevice);
      data?.cameraDevice !== "" && saveCamera(data?.cameraDevice);
      data?.speakerDevice !== "" && setSpeakerHandler(data?.speakerDevice);
    }
  }
};

export const DropdownIndicator = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <svg width="7.383" height="9.811" viewBox="286.736 9.094 7.383 9.811">
        <path
          d="m286.944 15.634 2.98 3.072c.13.133.305.2.499.2h.009c.193 0 .369-.067.498-.2l2.98-3.072a.658.658 0 0 0 0-.96.736.736 0 0 0-1.005 0l-1.766 1.845V9.775c0-.376-.318-.68-.712-.68-.4 0-.712.304-.712.68v6.744l-1.766-1.846a.736.736 0 0 0-1.005 0 .658.658 0 0 0 0 .961Z"
          fill="#2D343F"
        />
      </svg>
    </components.DropdownIndicator>
  );
};

export const ClearIndicator = (props) => {
  return (
    <components.ClearIndicator {...props}>
      <svg
        width="15.556"
        height="15.556"
        viewBox="2240.448 1762.444 15.556 15.556"
      >
        <path
          d="m2250.727 1766.055-2.5 2.5-2.5-2.5a.786.786 0 0 0-1.112 0l-.555.556a.786.786 0 0 0 0 1.11l2.5 2.5-2.5 2.501a.786.786 0 0 0 0 1.111l.555.556a.786.786 0 0 0 1.111 0l2.5-2.5 2.5 2.5a.786.786 0 0 0 1.112 0l.555-.556a.786.786 0 0 0 0-1.11l-2.5-2.501 2.5-2.5a.786.786 0 0 0 0-1.111l-.555-.556a.786.786 0 0 0-1.111 0Z"
          fill="#2d343f"
        />
      </svg>
    </components.ClearIndicator>
  );
};

export const openImagePreviewHandler = (data) => {
  store.dispatch(setImagePreviewData(data));
  store.dispatch(setImagePreviewOpen(true));
};

export const updateLoggedInUserCallStatus = () => {
  const state = store.getState();
  const { allAttendeeList } = state.call;
  const { user_unique_key } = getAuthSession();
  let loggedInUserData = allAttendeeList.find(
    (obj) => obj.user_id === user_unique_key,
  );
  if (loggedInUserData?.onCall) {
    const updatedLoggedInUserData = { ...loggedInUserData };
    updatedLoggedInUserData["onCall"] = false;
    updatedLoggedInUserData["roomId"] = "";
    _userUpdate(updatedLoggedInUserData);
  }
};

export const hrefPattern = /<a\s+(?:[^>]*?\s+)?href=(["'])(.*?)\1/gi;
export const hrefValue = "javascript:void(0)";

export const languageOptions = [
  { name: "english", value: "en" },
  { name: "french", value: "fr" },
];

// export const _canvas_credentials = () => {
//   const _element = document.getElementById("outerStyleId");
//   return _element ? _element.getBoundingClientRect() : null;
// };

export const getMyUserId = () => {
  const { user_unique_key } = getAuthSession();
  return user_unique_key;
};
export const isElectron = navigator.userAgent.includes("Electron");

export const blobToBase64 = (blob) => {
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.onloadend = () => resolve(reader.result);
    reader.readAsDataURL(blob);
  });
};

export const SCHEDULE_CALL_TITLE = "Scheduled Call Reminder";
export const CALL_TITLE = "New incoming call";
export const uploadFileMimeType = () => {
  return [
    "audio/mpeg",
    "audio/*",
    "audio/wav",
    "audio/mp3",
    "video/mp4",
    "video/mpeg",
    "video/quicktime",
  ];
};
export const TenMegaBytes = 10 * 1024 * 1024;
export const _1GB = 1 * 1024 * 1024 * 1024;
export const uploadAudioVideoFile = (selectedFile) => {
  let attachments = [];
  const uploadFileUrl = `${API_UploadFile_URL}upload`;
  const promises = selectedFile.map(async (item) => {
    const _file = item;
    const file_name = uuidv4();
    const { name: fileExt } = _file;
    let _extension = fileExt?.split(".")[1];
    const _response = await uploadWasabiFile(uploadFileUrl, {
      file: file_name + "." + _extension,
    });
    if (_response?.status === 200) {
      let _uploadUrl = _response?.data?.url;
      const _newResponse = await uploadAttachmentFileWasabi(
        {
          _uploadUrl,
          _file,
        },
        true,
      );
      store.dispatch(setSharedFileProgressBar(""));
      if (_newResponse?.status === 200) {
        const attachmentName = file_name + "." + _extension;
        let attachment = {
          attachmentName,
        };
        attachments.push(attachment);
        return attachments;
      }
    }
  });
  return Promise.all(promises);
};

export const alertMsgDialog = ({ msg, type }, cb) => {
  swal({
    title: msg,
    closeOnClickOutside: false,
    buttons: {
      cancel: false,
      confirm: type === "hideOk" ? false : true,
    },
    dangerMode: true,
  }).then((willDelete) => {
    if (willDelete) {
      // eslint-disable-next-line no-empty
      if (type === "info") {
      } else {
        if (cb && typeof cb === "function") {
          cb();
          // eslint-disable-next-line no-empty
        } else {
        }
      }
      // eslint-disable-next-line no-empty
    } else {
    }
  });
};

export const isPlatform = () => {
  const { userAgent, maxTouchPoints, platform } = navigator;
  let OS;

  if (userAgent?.match(/Android/i)) {
    OS = "android";
  } else if (
    userAgent?.match(/iP(ad|hone|od)/i) ||
    (maxTouchPoints && maxTouchPoints > 2 && /MacIntel/.test(platform))
  ) {
    OS = "ios";
  } else if (userAgent?.match(/Mac(intosh| OS X)/i)) {
    OS = "macos";
  } else if (userAgent?.match(/Windows/i)) {
    OS = "windows";
  }

  return OS;
};

export const getRandomColor = () => {
  var letters = "0123456789ABCDEF";
  var color = "#";
  for (var i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
};

export const toDataURL = async (url) => {
  return new Promise((resolve, reject) => {
    fetch(url + "?not-from-cache-please")
      .then((response) => response.blob())
      .then(async (blob) => {
        const resData = await blobToData(blob);
        resolve(resData);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
export const blobToData = (blob) =>
  new Promise((resolve) => {
    const reader = new FileReader();
    reader.onloadend = () => resolve(reader.result?.toString());
    reader.readAsDataURL(blob);
  });

export function compareVersions(a, b) {
  const partsA = a?.split(".");
  const partsB = b?.split(".");
  for (let i = 0; i < Math.max(partsA.length, partsB.length); i++) {
    const numA = parseInt(partsA[i]) || 0;
    const numB = parseInt(partsB[i]) || 0;
    if (numA < numB) {
      return -1; // -1 if version a is less than version b.
    } else if (numA > numB) {
      return 1; // 1 if version a is greater than version b.
    }
  }
  return 0; // versions are equal
}

export const randomString = (length) => {
  var chars = "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ";
  var result = "";
  for (var i = length; i > 0; --i) {
    result += chars[Math.floor(Math.random() * chars.length)];
  }
  return result;
};

export const testDownloadSpeed = async () => {
  // const fileUrl = `${TEST_FILE_URL_5MB}?id=${randomString(8)}`; // Replace with a URL of a large file
  // const fileUrl = `https://download.meetn.com/media/4vnkOWsbBP.jpg?d=${Math.round(Math.random())}`; // Replace with a URL of a large file
  const fileSizeInBytes = 1019000; // Define the size of the data  (e.g., 1MB)
  //const fileSizeInBytes = 5144000; // Define the size of the data (e.g., 5MB)
  const downloadUrl = `${API_UploadFile_URL}download`;
  const getWasabiUrl = await fetchWasabiImageUploadURL(downloadUrl, {
    file: "e5740e37-443e-44d2-b4ec-5b68792fab2a.png", // 5MB image
  });
  if (getWasabiUrl?.status === 200) {
    const fileUrl = getWasabiUrl?.data?.url;

    const controller = new AbortController();
    const timeoutId = setTimeout(() => controller.abort(), 30 * 1000);
    const signal = controller.signal;

    const startTime = new Date().getTime();
    const response = await fetch(fileUrl, {
      method: "get",
      signal: signal, // <------ This is our AbortSignal
    });
    // eslint-disable-next-line no-unused-vars
    const blob = await response.blob();
    const endTime = new Date().getTime();
    clearTimeout(timeoutId);

    const durationInSeconds = (endTime - startTime) / 1000;
    const fileSizeInBits = fileSizeInBytes * 8;
    const speedInBps = fileSizeInBits / durationInSeconds;
    const speedInKbps = speedInBps / 1024;
    const speedInMbps = speedInKbps / 1024;

    console.log(`Download speed: ${speedInMbps.toFixed(2)} Mbps`);

    return speedInMbps.toFixed(2);
  } else {
    return 0;
  }
};

export const testUploadSpeed = async () => {
  // const uploadUrl = `${apiBaseUrl}/api/speed/test/upload`; // Replace with the URL for uploading
  const uploadUrl = `${API_UploadFile_URL}upload`; // Replace with the URL for uploading
  const fileSizeInBytes = 1019000; // Define the size of the data to upload (e.g., 1MB)
  // const fileSizeInBytes = 5144000; // Define the size of the data to upload (e.g., 5MB)

  const imageResponse = await fetch(img5MB);
  // const imageBlob = await imageResponse.blob();
  // const imageData = await imageResponse.arrayBuffer();

  // Create a File object from the Blob if needed
  // const file = new File([imageBlob], 'image.jpg', { type: imageBlob.type });

  const imageData = await imageResponse.blob();

  // Step 2: Create FormData and append image
  // const fileName = `image-${Math.round(Math.random())}.png`;
  const fileName = uuidv4();
  // const formData = new FormData();
  // formData.append("file", imageData, fileName);
  // formData.append("name", fileName);
  // const data = new Blob([new ArrayBuffer(fileSizeInBytes)]);

  const _response = await uploadWasabiFile(uploadUrl, {
    file: fileName + "." + "png",
  });
  if (_response?.status === 200) {
    let _uploadUrl = _response?.data?.url;
    const controller = new AbortController();
    const timeoutId = setTimeout(() => controller.abort(), 30 * 1000);
    const signal = controller.signal;

    const startTime = new Date().getTime();
    const response = await axios
      .put(_uploadUrl, imageData, { signal })
      .then((response) => {
        return response;
      });

    const endTime = new Date().getTime();
    clearTimeout(timeoutId);

    if (response?.status === 200) {
      const durationInSeconds = (endTime - startTime) / 1000;
      const fileSizeInBits = fileSizeInBytes * 8;
      const speedInBps = fileSizeInBits / durationInSeconds;
      const speedInKbps = speedInBps / 1024;
      const speedInMbps = speedInKbps / 1024;

      console.log(`Upload speed: ${speedInMbps.toFixed(2)} Mbps`);

      return speedInMbps.toFixed(2);
    } else {
      console.log(`Upload failed with status: ${response?.status}`);
      return 0;
    }
  } else {
    console.log(`Upload failed with status: ${_response?.status}`);
    return 0;
  }
};

export const resizedataURL = (datas, wantedWidth, wantedHeight) => {
  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async function (resolve) {
    // We create an image to receive the Data URI
    var img = document.createElement("img");

    // When the event "onload" is triggered we can resize the image.
    img.onload = function () {
      // We create a canvas and get its context.
      var canvas = document.createElement("canvas");
      var ctx = canvas.getContext("2d");

      // We set the dimensions at the wanted size.
      canvas.width = wantedWidth;
      canvas.height = wantedHeight;

      // We resize the image with the canvas method drawImage();
      ctx?.drawImage(this, 0, 0, wantedWidth, wantedHeight);

      var dataURI = canvas.toDataURL();

      // This is the return of the Promise
      resolve(dataURI);
    };

    // We put the Data URI in the image's src attribute
    img.src = datas;
  });
}; //
