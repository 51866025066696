/* eslint-disable quotes */
import React, { useEffect } from "react";
import JitsiMeetJS, {
  JitsiConnectionEvents,
  JitsiConferenceEvents,
  createDesktopTrack,
} from "./index";
import { config } from "./config";
import store from "../../../store";
import { setScreenSpyStatus } from "../../redux/callSlice";
import { _userUpdate } from "../../../SocketIo";

let localDesktopTrack = null;
let room = null;
let jitsiConnection = null;

window.JitsiMeetScreenObtainer = {
  openDesktopPicker(options, onSourceChoose) {
    showDesktopPickerScreen(options, onSourceChoose);
  },
};

function showDesktopPickerScreen(options, onSourceChoose) {
  const state = store.getState();
  const { callDetail } = state.call;
  console.log("callDetail onSourceChoose", callDetail);
  onSourceChoose(
    callDetail?.selectedMonitorScreen?.id,
    callDetail?.selectedMonitorScreen?.type,
    false,
  );
}

const Screenspy = (props) => {
  useEffect(async () => {
    const roomName = props.room.toLowerCase();
    const { user_unique_key, allAttendeeList } = props;
    const userName =
      allAttendeeList.find(
        (filterItem) => filterItem.user_id === user_unique_key,
      )?.name ?? "";
    init(roomName, userName, user_unique_key);
  }, []);
  const state = store.getState();
  const { callDetail } = state.call;
  const { authSessionUser } = state.authUserDetail;

  useEffect(() => {
    if (callDetail?.calling_status === "ended") {
      if (authSessionUser?.monitor_status) {
        const ReciverUserData = { ...authSessionUser };
        ReciverUserData["monitor_status"] = false;
        ReciverUserData["roomId"] = "";

        _userUpdate(ReciverUserData);
        HangupScreenSpyCall();
      }
    }
  }, [callDetail]);

  return <></>;
};

function init(roomName, userName, userId) {
  const initOptions = {
    disableAudioLevels: true,
  };
  JitsiMeetJS.init(initOptions);
  createDesktopTrack().then((desktoptrack) => {
    desktoptrack = desktoptrack[0];
    localDesktopTrack = desktoptrack;
    connect(roomName).then((connection) => {
      joinRoom(connection, roomName, userName, userId).then(() => {});
    });
  });
}

async function connect(roomName) {
  JitsiMeetJS.setLogLevel(JitsiMeetJS.logLevels.ERROR);

  let serviceUrl = config.websocket || config.bosh;

  serviceUrl += `?room=${roomName}`;

  config.serviceUrl = serviceUrl;

  if (config.websocketKeepAliveUrl) {
    config.websocketKeepAliveUrl += `?room=${roomName}`;
  }

  const initOptions = {
    disableAudioLevels: true,
    disableSimulcast: false,
  };

  JitsiMeetJS.init(initOptions);

  const connection = new JitsiMeetJS.JitsiConnection(null, null, config);
  jitsiConnection = connection;

  return new Promise((resolve, reject) => {
    connection.addEventListener(
      JitsiConnectionEvents.CONNECTION_ESTABLISHED,
      handleConnectionEstablished,
    );
    connection.addEventListener(
      JitsiConnectionEvents.CONNECTION_FAILED,
      handleConnectionFailed,
    );
    // connection.addEventListener(
    // JitsiConnectionEvents.CONNECTION_FAILED,
    // connectionFailedHandler);

    connection.connect();

    // function connectionFailedHandler(error, message, credentials, details) {
    // /* eslint-enable max-params */
    //     // APP.store.dispatch(
    //     //     connectionFailed(
    //     //         connection, {
    //     //             credentials,
    //     //             details,
    //     //             message,
    //     //             name: error
    //     //         }));

    //     if (isFatalJitsiConnectionError(error)) {
    //         connection.removeEventListener(
    //             JitsiConnectionEvents.CONNECTION_FAILED,
    //             connectionFailedHandler);
    //     }
    // }

    function unsubscribe() {
      connection.removeEventListener(
        JitsiConnectionEvents.CONNECTION_ESTABLISHED,
        handleConnectionEstablished,
      );
      connection.removeEventListener(
        JitsiConnectionEvents.CONNECTION_FAILED,
        handleConnectionFailed,
      );
    }

    function handleConnectionEstablished() {
      unsubscribe();
      resolve(connection);
    }

    function handleConnectionFailed(err) {
      unsubscribe();
      console.err("CONNECTION FAILED:", err);
      reject(err);
    }
  });
}

async function joinRoom(connection, roomName, userName, userID) {
  room = connection.initJitsiConference(roomName, config);

  return new Promise((resolve) => {
    roomevents(room, userName, userID);
    resolve(room);
  });
}

const roomevents = (room, userName, userID) => {
  room.on(JitsiConferenceEvents.CONFERENCE_JOINED, () => {
    if (localDesktopTrack !== null) {
      room?.addTrack(localDesktopTrack);
    }
  });

  room.setReceiverVideoConstraint("1080");
  room.setSenderVideoConstraint("1080");
  room.join();
  room.setDisplayName(`${userName}_${userID}`);
};

export default Screenspy;

async function disposelocaltracks() {
  if (localDesktopTrack !== null) {
    localDesktopTrack.dispose();
    localDesktopTrack = null;
  }
}
export async function HangupScreenSpyCall() {
  disposelocaltracks().then(() => {
    if (room !== null) {
      room.leave().then(() => {
        room = null;
        store.dispatch(setScreenSpyStatus(false));
      });
    }
    if (jitsiConnection !== null) {
      jitsiConnection.disconnect().then(() => {
        jitsiConnection = null;
      });
    }
  });
}
