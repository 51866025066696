import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer } from "react-toastify";

import {
  getAuthSession,
  isElectron,
  testDownloadSpeed,
  testUploadSpeed,
  // eslint-disable-next-line import/namespace
} from "./component/Helpers/common";
import { CLIENT_URL, LOGOUT_CODE_PARAM } from "./constants";
import { getTransport } from "./component/transport";
// eslint-disable-next-line import/namespace
import AlertModal from "./component/models/AlertModal";
import {
  setIsLoggedInAccountActive,
  setErrorCode,
} from "./component/redux/authUserDetail";
import BelowHeader from "./component/BelowHeader";
import {
  socketAllEventsOpen,
  userAccountExist,
  userGetVerifiedHandler,
} from "./component/Helpers/chatCommon";
import { globalStrings } from "./component/util/translation/languages";
import { setElectronAppVersion } from "./component/redux/callSlice";
import { setDesktopSystemStats } from "./component/redux/chatSlice";
import { _desktopSystemStats } from "./SocketIo";

const transport = getTransport();

export default function Dashboard() {
  const dispatch = useDispatch();
  let userOnlineIntervalID = null;
  let token = useParams();
  let loginToken = token.id;
  // eslint-disable-next-line no-unused-vars
  const [sessionTheme, setSessionTheme] = useState(false);
  const { authSuccess, authSession } = useSelector(
    (state) => state.authUserDetail,
  );
  const IsLoggedInAccountActive = useSelector(
    (state) => state.authUserDetail.IsLoggedInAccountActive,
  );
  const errorCode = useSelector((state) => state.authUserDetail.errorCode);
  const logout = useSelector((state) => state.authUserDetail.logout);
  const { socketData, isSocketConnected, selectedLanguage } = useSelector(
    (state) => state.chat,
  );

  const userStaticInfo = useSelector((state) => state.chat.userStaticInfo);
  const desktopSystemStats = useSelector(
    (state) => state.chat.desktopSystemStats,
  );
  const [downloadSpeed, setDownloadSpeed] = useState(0);
  const [uploadSpeed, setUploadSpeed] = useState(0);
  console.log("uploadSpeed", uploadSpeed);

  useEffect(() => {
    if (loginToken) {
      window
        .matchMedia("(prefers-color-scheme: dark)")
        .addEventListener("change", checkSessionTheme);
      checkDownloadSpeed();
      checkUploadSpeed();
      userAccountExist(loginToken);

      transport.on("event", (event) => {
        console.log("dashboard event", event);
        if (event.name === "send-app-version") {
          const electronAppVersionData =
            event?.data && event?.data.length > 0 && event?.data[0];
          console.log("event dashboard", electronAppVersionData);
          dispatch(setElectronAppVersion(electronAppVersionData));
        }
        if (event.name === "system-info") {
          const systemInfoData =
            event?.data && event?.data.length > 0 && event?.data[0];
          console.log("system-info", systemInfoData);
          dispatch(setDesktopSystemStats(systemInfoData));
        }
      });
    }
  }, []);

  const checkDownloadSpeed = async () => {
    let downloadSpeed = await testDownloadSpeed();
    console.log("downloadSpeed", downloadSpeed);
    setDownloadSpeed(downloadSpeed);
  };

  const checkUploadSpeed = async () => {
    let uploadSpeed = await testUploadSpeed();
    setUploadSpeed(uploadSpeed);
  };

  useEffect(() => {
    if (
      Object.keys(userStaticInfo).length > 0 &&
      Object.keys(desktopSystemStats).length > 0 &&
      downloadSpeed > 0 &&
      uploadSpeed > 0
    ) {
      console.log("desktopSystemStats", desktopSystemStats);
      const system_stats = { ...desktopSystemStats };
      system_stats["downloadSpeed"] = `${downloadSpeed} Mbps`;
      system_stats["uploadSpeed"] = `${uploadSpeed} Mbps`;
      const payload = {
        user_id: userStaticInfo?.user_id,
        company_id: userStaticInfo?.company_id,
        system_stats: system_stats,
      };
      _desktopSystemStats(payload);
    }
  }, [userStaticInfo, desktopSystemStats, downloadSpeed]);

  useEffect(() => {
    checkSessionTheme();
  });

  const checkSessionTheme = () => {
    if (window.matchMedia) {
      if (window.matchMedia("(prefers-color-scheme: dark)")?.matches) {
        setSessionTheme(true);
      } else {
        setSessionTheme(false);
      }
    }
  };

  useEffect(() => {
    if (sessionTheme === true) {
      document.body.classList.add("theme-dark");
    } else {
      document.body.classList.remove("theme-dark");
    }
  }, [sessionTheme]);

  useEffect(() => {
    if (loginToken) {
      if (isSocketConnected && socketData !== null) {
        socketAllEventsOpen(loginToken);
      }
    } else {
      let getSession = getAuthSession();
      if (!getSession) {
        if (isElectron) {
          transport.sendEvent({
            name: "video-conference-left",
          });
        } else {
          window.location = CLIENT_URL;
        }
      }
    }
  }, [isSocketConnected, socketData]);

  useEffect(() => {
    if (Object.keys(authSession).length > 0) {
      if (authSession?.status == 200) {
        userGetVerifiedHandler(authSession, loginToken, navigate);
      } else {
        dispatch(setIsLoggedInAccountActive(true));
        dispatch(setErrorCode(authSession.status));
      }
    }
  }, [authSession]);

  useEffect(() => {
    return () => {
      if (userOnlineIntervalID !== null) {
        clearInterval(userOnlineIntervalID);
      }
    };
  }, []);

  let navigate = useNavigate();

  useEffect(() => {
    if (logout) {
      dispatch(setIsLoggedInAccountActive(true));
      dispatch(setErrorCode(LOGOUT_CODE_PARAM));
    }
  }, [logout]);

  // useEffect(() => {
  //   if (authSessionUser) {
  //     const virtual_background = authSessionUser?.virtual_background ?? {};
  //     dispatch(setVirtualBackgroundOptions(virtual_background));
  //   }
  // }, [authSessionUser]);

  return (
    <>
      <ToastContainer />
      {authSuccess && isSocketConnected && IsLoggedInAccountActive === false ? (
        <>
          <div className="bg--dark border-radius h-100">
            <BelowHeader />
          </div>
        </>
      ) : (
        <>
          <div className="loader_spinner">
            <div className="loader_spinner__box">
              <div className="spin"></div>
            </div>
          </div>
        </>
      )}

      {IsLoggedInAccountActive && (
        <AlertModal
          show={IsLoggedInAccountActive}
          closeModal={() => dispatch(setIsLoggedInAccountActive(true))}
          msg={
            errorCode == 100
              ? globalStrings[selectedLanguage]?.$dashboardError
              : `${globalStrings[selectedLanguage]?.$dashboardError1} ${errorCode})`
          }
          alertType={"accountInactive"}
          logOutButtonEnable={errorCode == "405" ? true : false}
          selectedLanguage={selectedLanguage}
        />
      )}
    </>
  );
}
