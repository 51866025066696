import React, { useEffect } from "react";
import { Route, Routes } from "react-router-dom";

import Dashboard from "./Dashboard";
import TestConference from "./TestConference";
import TestScreenShare from "./TestScreenShare";
import { ENVIRONMENT } from "./constants";
import MainIframe from "./component/mainIframe";
import { NetWorkCheckInit } from "./component/Helpers/common";
import {
  removeMentionUserProfile,
  getMentionUserProfile,
} from "./component/Helpers/chatCommon";
import CanvasUI from "./component/customWhiteBoard/CanvasUI";
import CongratsModal from "./component/models/Congrats";
// eslint-disable-next-line import/no-unresolved
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";

function App() {
  useEffect(() => {
    if (ENVIRONMENT !== "dev") {
      Sentry.init({
        dsn: "https://09ef05b49a59f9bf2a847f76f096685f@o1347523.ingest.us.sentry.io/4506897441423360",
        integrations: [
          // Sentry.browserTracingIntegration(),
          // Sentry.replayIntegration({
          //   maskAllText: false,
          //   blockAllMedia: false,
          // }),
          new Integrations.BrowserTracing(),
        ],
        // Performance Monitoring
        tracesSampleRate: 1.0, //  Capture 100% of the transactions
        // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
        // tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
        // Session Replay
        replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
        replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
      });
    }

    // if (ENVIRONMENT !== "dev") {
    //   console.log = function () {};
    // }
    NetWorkCheckInit();
    window.getMentionUserProfile = getMentionUserProfile;
    window.removeMentionUserProfile = removeMentionUserProfile;
  }, []);

  return (
    <>
      <Routes>
        <Route path="/" element={<Dashboard />} />
        <Route path="dashboard" element={<Dashboard />} />
        <Route path="dashboard/:id" element={<Dashboard />} />
      </Routes>
      {ENVIRONMENT === "dev" ? (
        <Routes>
          <Route path="conference" element={<TestConference />} />
          <Route path="screen" element={<TestScreenShare />} />
          <Route path="testIframe/:id" element={<MainIframe />} />
          <Route path="customWhiteBoard" element={<CanvasUI />} />
          <Route path="congrats" element={<CongratsModal />} />
        </Routes>
      ) : null}
    </>
  );
}

export default App;
