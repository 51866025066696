/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/display-name */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState, memo } from "react";
import { useDispatch, useSelector } from "react-redux";

import GroupSetting from "../Groups/GroupSetting";
import { _searchChatMessage, _sendChatRoomDetail } from "../../SocketIo";
import { getAuthSession, privateChatID } from "../Helpers/common";
import { setSearchChatData } from "../redux/chatSlice";
import UserProfileModal from "../models/UserProfileModal";

const ChatBoardHeader = () => {
  const dispatch = useDispatch();
  const authSession = getAuthSession();
  const sessionThemeData = useSelector(
    (state) => state.authUserDetail.sessionThemeData,
  );
  const { attendeeData, selectedLanguage } = useSelector((state) => state.chat);
  const [chatSearch, setChatSearch] = useState("");
  const [showSetting, setShowSetting] = useState(false);
  const [showUserSetting, setShowUserSetting] = useState(false);
  const [isOpenModal, setIsOpenModal] = useState(false);
  let username = attendeeData?.name;
  let role = attendeeData?.role_name;
  let shows = false;
  let groupId = "";
  let showUser = false;
  let useravtar = attendeeData?.user_profile_image;
  if (attendeeData.type == "group") {
    groupId = attendeeData?.groupId;
    username = attendeeData?.groupName;
    role = attendeeData?.description;
    // eslint-disable-next-line no-unused-vars
    shows = true;
    showUser = true;
    useravtar = "";
  }

  useEffect(async () => {
    setShowUserSetting(showUser);
  }, [groupId]);

  // useEffect(() => {
  //   ClearSearchChat();
  // }, [attendeeData.name]);

  const attendeeSearchText = (e) => {
    setChatSearch(e.target.value);
  };

  // useEffect(() => {
  //   if (chatSearch === "") {
  //     ClearSearchChat();
  //   }
  // }, [chatSearch]);

  let GroupSettingProps = {
    show: showSetting,
  };

  const SearchChat = (e) => {
    if (e.keyCode === 13 && !e.shifyKey) {
      e.preventDefault();
      const { user_unique_key, company_unique_key } = authSession;
      dispatch(setSearchChatData(chatSearch));
      const documentID =
        attendeeData.type == "group"
          ? groupId
          : privateChatID([user_unique_key, attendeeData?.user_id]);
      if (chatSearch == "") {
        const chatRoomPayload = {
          company_id: company_unique_key,
          documentID: documentID,
          user_id: user_unique_key,
        };
        _sendChatRoomDetail(chatRoomPayload, "SearchChat");
      } else {
        const Payload = {
          user_id: authSession.user_unique_key,
          company_id: authSession.company_unique_key,
          documentID: documentID,
          filter: chatSearch,
        };
        _searchChatMessage(Payload);
      }
    }

    if (e.key === "Backspace") {
      if (e.target.value.length === 1) {
        ClearSearchChat();
      }
    }
  };

  const ClearSearchChat = () => {
    const { user_unique_key, company_unique_key } = authSession;
    const documentID =
      attendeeData.type == "group"
        ? groupId
        : privateChatID([user_unique_key, attendeeData?.user_id]);
    const chatRoomPayload = {
      company_id: company_unique_key,
      documentID: documentID,
      user_id: user_unique_key,
    };
    setChatSearch("");
    _sendChatRoomDetail(chatRoomPayload, "ClearSearchChat");
    dispatch(setSearchChatData(""));
  };

  const openProfileModal = () => {
    setIsOpenModal(true);
  };

  const closeModal = () => {
    setIsOpenModal(false);
  };

  return (
    <>
      <div className="head">
        <div className="user position-relative">
          <div className="dropdown-container">
            <div
              className="avatar cursor-pointer"
              onClick={(e) =>
                attendeeData.type == "group" ? "" : openProfileModal(e)
              }
            >
              {!useravtar ? (
                sessionThemeData ? (
                  <img src="./assets/images/group-dark.png" alt="Group" />
                ) : (
                  <img src="./assets/images/group-light.png" alt="Group" />
                )
              ) : (
                // eslint-disable-next-line jsx-a11y/alt-text
                <img className="h-100" src={`${useravtar}`}></img>
              )}
            </div>
          </div>
          <div className="d-flex flex-column contact_info">
            <div className="name">{username}</div>
            <span className="designation">{role}</span>
          </div>
        </div>
        <div className="bar_tool">
          <div className="chat_search">
            <input
              spellCheck="false"
              value={chatSearch}
              type="text"
              className="form-control"
              placeholder=""
              aria-label="Name"
              aria-describedby="basic-addon1"
              onKeyDown={(e) => SearchChat(e)}
              onChange={(e) => attendeeSearchText(e)}
            />
            {chatSearch ? (
              // eslint-disable-next-line jsx-a11y/click-events-have-key-events
              // eslint-disable-next-line jsx-a11y/no-static-element-interactions
              // eslint-disable-next-line jsx-a11y/click-events-have-key-events
              // eslint-disable-next-line jsx-a11y/no-static-element-interactions
              <a
                className="btn_search icon_container"
                onClick={(e) => ClearSearchChat(e)}
              >
                <svg
                  width="11.13"
                  height="11.13"
                  viewBox="0 0 9.13 9.13"
                  className="rotate-45 cross"
                >
                  <path
                    fill="#b8c0c9"
                    id="Icon_awesome-plus"
                    data-name="Icon awesome-plus"
                    d="M7.621,5.051H4.329V1.759A.509.509,0,0,0,3.82,1.25H3.311a.509.509,0,0,0-.509.509V5.051H-.491A.509.509,0,0,0-1,5.561V6.07a.509.509,0,0,0,.509.509H2.8V9.871a.509.509,0,0,0,.509.509H3.82a.509.509,0,0,0,.509-.509V6.579H7.621A.509.509,0,0,0,8.13,6.07V5.561A.509.509,0,0,0,7.621,5.051Z"
                    transform="translate(1 -1.25)"
                  ></path>
                </svg>
              </a>
            ) : (
              <a className="btn_search icon_container">
                <svg width="13.807" height="13.807" viewBox="0 0 13.807 13.807">
                  <g
                    id="Icon_feather-search"
                    data-name="Icon feather-search"
                    transform="translate(-3.75 -3.75)"
                  >
                    <path
                      id="Path_1034"
                      data-name="Path 1034"
                      fill="none"
                      stroke="#b8c0c9"
                      d="M15.163,9.832A5.332,5.332,0,1,1,9.832,4.5a5.332,5.332,0,0,1,5.332,5.332Z"
                    />
                    <path
                      id="Path_1035"
                      data-name="Path 1035"
                      fill="none"
                      stroke="#b8c0c9"
                      d="M27.874,27.874l-2.9-2.9"
                      transform="translate(-11.378 -11.378)"
                    />
                  </g>
                </svg>
              </a>
            )}
          </div>
          <div className="d-flex">
            {showUserSetting ? (
              <UserSettingDisplay
                key={groupId}
                setShowSetting={setShowSetting}
              />
            ) : null}
          </div>
        </div>
      </div>
      {showSetting && (
        <GroupSetting
          {...GroupSettingProps}
          handleClose={() => setShowSetting(false)}
        />
      )}

      {isOpenModal && (
        <UserProfileModal
          show={true}
          userInfo={attendeeData}
          closeModal={closeModal}
          isOpenModal={isOpenModal}
          selectedLanguage={selectedLanguage}
        />
      )}
    </>
  );
};

export default memo(ChatBoardHeader);

const UserSettingDisplay = React.memo(({ setShowSetting }) => {
  return (
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions
    <span
      className="alink three-dot icon_container"
      onClick={() => setShowSetting(true)}
    >
      <svg width="3.5px" viewBox="10 4 4 16">
        <path
          fill="#b8c0c9"
          d="M12 10c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm0-6c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm0 12c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2z"
        ></path>
      </svg>
    </span>
  );
});
