/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import { Button, Form } from "react-bootstrap";
import Select from "react-select";
import { useSelector } from "react-redux";
import { useDetectClickOutside } from "react-detect-click-outside";

import { _updateCall } from "../../SocketIo";
import { globalStrings } from "../util/translation/languages";
import { alertMsg } from "../util/alertMsg";

const AddPeople = (props) => {
  const { handleClose, selectedLanguage } = props;
  const [AttendeeListForGroup, setAttendeeListForGroup] = useState([]);
  const [selectedOption, setSelectedOption] = useState(null);
  const { allAttendeeList, callDetail } = useSelector((state) => state.call);

  const submitGroupUserForm = async (e) => {
    e.preventDefault();
    if (selectedOption) {
      let attendeeUser = [];
      selectedOption.forEach((so) => {
        let data = so.value;
        attendeeUser.push(data);
      });
      let allUser = [...attendeeUser];
      const removedDuplicateUsers = [...new Set(allUser)];
      let NewCallDetail = { ...callDetail };
      let participant = [];
      if (callDetail.type === "private") {
        if (callDetail?.participantID?.length === 0) {
          participant.push(
            {
              id: callDetail.senderID,
              status: callDetail.calling_status,
              hand_raise: false,
            },
            {
              id: callDetail.receiverID,
              status: callDetail.calling_status,
              hand_raise: callDetail.hand_raise,
            },
          );
          removedDuplicateUsers.forEach((item) => {
            let particularAttendeeData = allAttendeeList?.find(
              (filterItem) => filterItem.user_id === item,
            );
            const onCallCheck = particularAttendeeData?.onCall ?? false;
            if (!onCallCheck) {
              participant.push({
                id: item,
                status: "initiated",
                hand_raise: false,
              });
            }
          });
        } else {
          participant = [...callDetail.participantID];
          removedDuplicateUsers.forEach((item) => {
            const isUserAlreadyExist = participant.findIndex(
              (filterItem) => filterItem.id === item,
            );
            if (isUserAlreadyExist === -1) {
              let particularAttendeeData = allAttendeeList?.find(
                (filterItem) => filterItem.user_id === item,
              );
              const onCallCheck = particularAttendeeData?.onCall ?? false;
              if (!onCallCheck) {
                participant.push({
                  id: item,
                  status: "initiated",
                  hand_raise: false,
                });
              }
            } else {
              const prevParticipantItem = participant[isUserAlreadyExist];
              participant[isUserAlreadyExist] = {
                id: prevParticipantItem.id,
                status: "initiated",
                hand_raise: false,
              };
            }
          });
        }
        NewCallDetail["participantID"] = participant;
      } else {
        const prevParticipant = callDetail.participantID;
        let groupParticipant = [...prevParticipant];
        removedDuplicateUsers.forEach((item) => {
          const isUserAlreadyExist = groupParticipant.findIndex(
            (filterItem) => filterItem.id === item,
          );
          if (isUserAlreadyExist === -1) {
            let particularAttendeeData = allAttendeeList?.find(
              (filterItem) => filterItem.user_id === item,
            );
            const onCallCheck = particularAttendeeData?.onCall ?? false;
            if (!onCallCheck) {
              groupParticipant.push({
                id: item,
                status: "initiated",
                hand_raise: false,
              });
            }
          } else {
            const prevParticipantItem = groupParticipant[isUserAlreadyExist];
            groupParticipant[isUserAlreadyExist] = {
              id: prevParticipantItem.id,
              status: "initiated",
              hand_raise: false,
            };
          }
        });

        NewCallDetail["participantID"] = groupParticipant;
      }
      NewCallDetail["user_added"] = true;
      _updateCall(NewCallDetail);
      setSelectedOption(null);
      handleClose();
    } else {
      alert(alertMsg.emptyField);
    }
  };

  const handleChange = (option) => {
    setSelectedOption(option);
  };

  useEffect(async () => {
    setArray(allAttendeeList);
  }, [allAttendeeList]);

  const setArray = (obj) => {
    let list = [];
    const prevParticipant = callDetail?.participantID ?? [];
    obj.forEach((v) => {
      const UserAlreadyExist = prevParticipant.filter(
        (filterItem) =>
          filterItem.id === v?.user_id && filterItem.status === "accepted",
      );
      if (UserAlreadyExist.length === 0) {
        let data = {
          value: v?.user_id,
          label: v.name,
          online: v.online,
          custom: true,
        };
        list.push(data);
      }
    });
    setAttendeeListForGroup(list);
    return list;
  };

  const ref = useDetectClickOutside({ onTriggered: handleClose });

  const DropClose = (e) => {
    e.stopPropagation();
  };

  const CustomOption = (props) => {
    const { data, innerRef, innerProps } = props;
    return data.custom ? (
      <div ref={innerRef} {...innerProps}>
        <span className="d-flex align-items-center Invite-People-drop">
          <span
            className={`status marginRight-5 ${data.online ? "online" : ""} `}
          ></span>
          {data.label}
        </span>
      </div>
    ) : (
      ""
    );
  };

  return (
    <>
      <div
        className="bg-white border-radius shadow index-3 position-absolute p-3 mt-2 w-300 top-100"
        ref={ref}
      >
        <Form>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>
              {globalStrings[selectedLanguage]?.$invitePeopleToCall}
            </Form.Label>
            <div onClick={DropClose}>
              <Select
                className="form-control p-0 whiteboard-form-control"
                isMulti={true}
                value={selectedOption}
                onChange={(e) => handleChange(e)}
                components={{ Option: CustomOption }}
                options={AttendeeListForGroup}
              />
            </div>
          </Form.Group>
          <Button
            type="submit"
            id="submit"
            variant="orange"
            className="ml-auto"
            onClick={(e) => {
              submitGroupUserForm(e);
            }}
          >
            {globalStrings[selectedLanguage]?.$add}
          </Button>
        </Form>
      </div>
    </>
  );
};

export default AddPeople;
