import { _sendScreenInfo } from "../../../SocketIo";
import { resizedataURL } from "../common";

const THUMBNAIL_SIZE = {
  height: 300,
  width: 300,
};

function _seperateSourcesByType(sources = []) {
  const sourcesByType = {
    screen: [],
    window: [],
  };

  sources.forEach((source) => {
    const idParts = source?.id?.split(":");
    const type = idParts[0];

    if (sourcesByType[type]) {
      sourcesByType[type].push(source);
    }
  });

  return sourcesByType;
}

export function obtainDesktopSources(types, options = {}) {
  const capturerOptions = {
    types,
  };

  if (options.thumbnailSize) {
    capturerOptions.thumbnailSize = options.thumbnailSize;
  }

  return new Promise((resolve, reject) => {
    const { JitsiMeetElectron } = window;

    if (JitsiMeetElectron && JitsiMeetElectron.obtainDesktopStreams) {
      JitsiMeetElectron.obtainDesktopStreams(
        (sources) => resolve(_seperateSourcesByType(sources)),
        (error) => {
          console.error(`Error while obtaining desktop sources: ${error}`);
          reject(error);
        },
        capturerOptions,
      );
    } else {
      const reason =
        "Called JitsiMeetElectron.obtainDesktopStreams" +
        " but it is not defined";

      console.error(reason);

      return Promise.reject(new Error(reason));
    }
  });
}

export const getScreensInfo = (data) => {
  const types = ["screen"];

  if (types.length > 0) {
    obtainDesktopSources(types, { thumbnailSize: THUMBNAIL_SIZE })
      .then(async (sources) => {
        console.log(
          "getScreensInfo showDesktopPickerScreen sources",
          sources,
          sources.screen[0].thumbnail.toDataURL(),
          sources.screen[1].thumbnail.toDataURL(),
        );
        const tmpScreens = [];
        const tmpSource = { ...sources };
        for (var i = 0; i < sources.screen.length; i++) {
          const item = sources.screen[i];
          const imgThumbnail = await resizedataURL(
            item.thumbnail.toDataURL(),
            70,
            70,
          );
          const tmpItem = { ...sources.screen[i] };
          tmpItem["imgUrl"] = imgThumbnail;
          tmpScreens.push(tmpItem);
        }
        tmpSource["screen"] = tmpScreens;
        const { company_id, sender_id } = data;
        _sendScreenInfo({ company_id, user_id: sender_id, screens: tmpSource });
      })
      .catch(() => {
        /* ignore */
      });
  }
};
