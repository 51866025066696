import React, { useEffect, useMemo, useState } from "react";
import { Modal } from "react-bootstrap";
import { Resizable } from "re-resizable";

import {
  getAuthSession,
  call_accepted_tone,
  updateLoggedInUserCallStatus,
} from "../Helpers/common";
import { MEDIA_URL } from "../../constants";
import Timer from "../Helpers/Lib/recordingTimer";
import {
  _canvas_credentials,
  hangupOutBoundCall,
  showRecordingButton,
} from "../Helpers/CallCommon";
import IframeCall from "../sidebar/Iframe";
// eslint-disable-next-line import/no-unresolved
import { HangupCall } from "../Helpers/Lib";
import { _updateCall } from "../../SocketIo";
import { useDispatch, useSelector } from "react-redux";
import {
  setIsOpenWhiteBoard,
  setMinimizePopUpInnerData,
  setMinimizePopUpShow,
  setOutBoundCallPopUpShow,
  setOutBoundCallPopUpVisibility,
} from "../redux/callSlice";
import { globalStrings } from "../util/translation/languages";
import { alertMsg } from "../util/alertMsg";
import RecordingConsent from "./RecordingConsent";

const OutBoundCall = (props) => {
  const { selectedLanguage } = props;
  const dispatch = useDispatch();
  const [callAccepted, setCallAccepted] = useState(false);
  const { user_unique_key, login_token, company_unique_key } = getAuthSession();
  const url = MEDIA_URL;
  const [intervalID, setIntervalID] = useState(null);
  const [width, setWidth] = useState(60);
  const [height, setHeight] = useState(75);
  const [fullScreen, setFullScreen] = useState(true);
  const {
    allAttendeeList,
    outBoundCallPopUpVisibility,
    callDetail,
    outBoundCallPopUpShow,
    callRoom,
    isOpenWhiteBoard,
    recordingConsentPopUpEnable,
  } = useSelector((state) => state.call);
  const { groupList } = useSelector((state) => state.chat);
  const { isActiveRecording } = useSelector((state) => state.lib);

  const handleClose = () => {
    dispatch(setOutBoundCallPopUpShow(false));
  };

  const DeclineCall = async (type, intervalIDData) => {
    clearInterval(intervalIDData);
    setIntervalID(null);
    await hangupOutBoundCall(
      callDetail,
      type,
      allAttendeeList,
      user_unique_key,
      groupList,
    );
  };

  useEffect(() => {
    if (isOpenWhiteBoard === true) {
      setWidth(100);
      setHeight(100);
    }
  }, [isOpenWhiteBoard]);

  useEffect(() => {
    if (callDetail?.type === "private") {
      if (callDetail?.calling_status === "initiated") {
        AutoDisconnect();
      } else {
        if (intervalID !== null) {
          clearInterval(intervalID);
          setIntervalID(null);
        }
      }
    }

    return () => {
      clearInterval(intervalID);
    };
  }, [callDetail?.calling_status]);

  const AutoDisconnect = () => {
    let seconds = 0;
    if (intervalID === null) {
      let intervalIDData = setInterval(() => {
        seconds++;
        if (seconds === 60) {
          DeclineCall("ended", intervalIDData);
          handleClose();
          dispatch(setIsOpenWhiteBoard(false));
          dispatch(setMinimizePopUpShow(false));
          HangupCall(callDetail);
        }
      }, 1000);
      setIntervalID(intervalIDData);
    }
  };

  useEffect(async () => {
    if (callDetail) {
      const {
        calling_status,
        participantID,
        type,
        user_added,
        monitor_status,
      } = callDetail;
      if (
        calling_status === "ended" &&
        (monitor_status === false || monitor_status === undefined)
      ) {
        handleClose();
        HangupCall(callDetail);
        dispatch(setIsOpenWhiteBoard(false));
        const NewCallDetail = { ...callDetail };
        if (callDetail.user_added === true) {
          NewCallDetail["user_added"] = false;
          _updateCall(NewCallDetail);
        }
      }
      if (type !== "group") {
        if (calling_status === "accepted" && !callAccepted) {
          if (call_accepted_tone) {
            call_accepted_tone?.play();
          }
          setTimeout(() => {
            call_accepted_tone.pause();
            setCallAccepted(true);
          }, 200);
        }

        if (calling_status === "decline") {
          updateLoggedInUserCallStatus();
          HangupCall(callDetail);
        }

        if (user_added === true) {
          const tmpCallUserDetail = participantID.find(
            (v) => v.id === user_unique_key,
          );
          if (
            tmpCallUserDetail?.status === "decline" ||
            tmpCallUserDetail?.status === "ended"
          ) {
            updateLoggedInUserCallStatus();
            handleClose();
            HangupCall(callDetail);
            dispatch(setIsOpenWhiteBoard(false));
          }
        }
        if (monitor_status === true) {
          const tmpCallUser = participantID.find(
            (v) => v.id === user_unique_key,
          );
          if (
            tmpCallUser?.status === "decline" ||
            tmpCallUser?.status === "ended"
          ) {
            updateLoggedInUserCallStatus();
            handleClose();
            dispatch(setIsOpenWhiteBoard(false));
            await HangupCall();
          }

          const isMonitorEnded = callDetail?.participantID.filter(
            (filterItem) => filterItem.status === "accepted",
          );
          if (isMonitorEnded.length === 0) {
            const UpdatedCallDetail = { ...callDetail };
            UpdatedCallDetail["monitor_status"] = false;
            _updateCall(UpdatedCallDetail);
          }
        }
      }

      if (type === "group") {
        const groupUserData = participantID.find(
          (v) => v.id === user_unique_key,
        );
        if (
          groupUserData?.status === "decline" ||
          groupUserData?.status === "ended"
        ) {
          updateLoggedInUserCallStatus();
          handleClose();
          HangupCall(callDetail);
          dispatch(setIsOpenWhiteBoard(false));
        }

        if (groupUserData?.status === "accepted") {
          if (!callAccepted) {
            if (call_accepted_tone) {
              call_accepted_tone?.play();
            }
            setTimeout(() => {
              call_accepted_tone.pause();
              setCallAccepted(true);
            }, 200);
          }
        }
      }
    }
  }, [callDetail]);

  useEffect(() => {
    if (width < 99 || height < 99) {
      setFullScreen(false);
    } else {
      setFullScreen(true);
    }
  }, [width, height]);

  useEffect(() => {
    if (isOpenWhiteBoard === true) {
      _canvas_credentials();
    }
  }, [fullScreen]);

  const VideoContent = () => {
    if (callDetail) {
      const name =
        callDetail?.type === "private"
          ? allAttendeeList?.find((v) => v?.user_id === callDetail?.receiverID)
              ?.name
          : `${callDetail?.groupName} ${globalStrings[selectedLanguage]?.$group}`;
      return (
        <h6 className="mt-0">
          {name
            ? `${name} ${globalStrings[selectedLanguage]?.$declineCall}`
            : ""}
        </h6>
      );
    }
  };

  const MinimizePopUpHandler = async () => {
    const { type } = callDetail;
    let callerName = globalStrings[selectedLanguage]?.$groupCall;
    if (type !== "group") {
      const name =
        allAttendeeList?.find((v) => v?.user_id === callDetail?.receiverID)
          ?.name ?? globalStrings[selectedLanguage]?.$groupCall;
      callerName = name;
    } else {
      callerName = `${callDetail?.groupName} ${globalStrings[selectedLanguage]?.$group}`;
    }
    await dispatch(
      setMinimizePopUpInnerData({
        callerName: callerName,
        callType: "outbound",
      }),
    );
    dispatch(setOutBoundCallPopUpVisibility(true));
    dispatch(setMinimizePopUpShow(true));
  };

  const fullScreenHandler = () => {
    setWidth(100);
    setHeight(100);
  };

  const showRecordingTimer = useMemo(() => {
    return showRecordingButton({ callDetails: callDetail, user_unique_key });
  }, [callDetail]);
  return (
    <>
      <Modal
        show={outBoundCallPopUpShow}
        className={`modal-full width-modal align-items-center justify-content-center call-modal ${
          outBoundCallPopUpVisibility ? "visibility-hidden" : ""
        }`}
        aria-labelledby="example-modal-sizes-title-lg"
        backdrop="static"
        keyboard="False"
        id="outbound-modal"
      >
        <Resizable
          className={`${
            width > 98 && height > 98 ? "" : "margin-auto"
          } modal-resizable`}
          id="modal-resizable-id"
          size={{ width: `${width}%`, height: `${height}%` }}
          onResizeStop={(e, direction, ref, d) => {
            let ModalWidth =
              document.getElementById("outbound-modal").offsetWidth;
            let ModalHeight =
              document.getElementById("outbound-modal").offsetHeight;
            const resizeWidth = (100 / ModalWidth) * d.width;
            const resizeHeight = (100 / ModalHeight) * d.height;
            setWidth(width + resizeWidth);
            setHeight(height + resizeHeight);
          }}
          maxWidth="100%"
          maxHeight="100%"
          minWidth="60%"
          minHeight="75%"
        >
          <Modal.Header closed>
            <Modal.Title></Modal.Title>

            {callDetail?.calling_status !== "decline" ? (
              <>
                <div className="w-100">
                  {isActiveRecording && (
                    <h5 className="record-duration  position-absolute text-white">
                      {showRecordingTimer ? <Timer /> : alertMsg.recordingMsg}
                    </h5>
                  )}
                </div>
                <div className="maximize-restore-icons d-flex">
                  {!fullScreen ? (
                    <button
                      className="maximize-restore-icons minimizePopupButton icon_container"
                      onClick={() => fullScreenHandler()}
                    >
                      <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        className="feather feather-x"
                      >
                        <path
                          stroke="#b8c0c9"
                          fill="none"
                          d="M8 3H5a2 2 0 0 0-2 2v3m18 0V5a2 2 0 0 0-2-2h-3m0 18h3a2 2 0 0 0 2-2v-3M3 16v3a2 2 0 0 0 2 2h3"
                        />
                      </svg>
                    </button>
                  ) : (
                    <button
                      className="maximize-restore-icons minimizePopupButton icon_container"
                      onClick={() => {
                        setWidth(60);
                        setHeight(75);
                      }}
                    >
                      <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        className="feather feather-x"
                      >
                        <path
                          stroke="#b8c0c9"
                          fill="none"
                          d="M8 3v3a2 2 0 0 1-2 2H3m18 0h-3a2 2 0 0 1-2-2V3m0 18v-3a2 2 0 0 1 2-2h3M3 16h3a2 2 0 0 1 2 2v3"
                        />
                      </svg>
                    </button>
                  )}
                  <button
                    className="maximize-restore-icons minimizePopupButton icon_container"
                    onClick={() => MinimizePopUpHandler()}
                  >
                    <svg
                      viewBox="0 0 24 24"
                      width="24"
                      height="24"
                      stroke="currentColor"
                      strokeWidth="2"
                      fill="none"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      className="css-i6dzq1"
                    >
                      <polyline
                        stroke="#b8c0c9"
                        fill="none"
                        points="4 14 10 14 10 20"
                      ></polyline>
                      <polyline
                        stroke="#b8c0c9"
                        fill="none"
                        points="20 10 14 10 14 4"
                      ></polyline>
                      <line
                        stroke="#b8c0c9"
                        fill="none"
                        x1="14"
                        y1="10"
                        x2="21"
                        y2="3"
                      ></line>
                      <line
                        stroke="#b8c0c9"
                        fill="none"
                        x1="3"
                        y1="21"
                        x2="10"
                        y2="14"
                      ></line>
                    </svg>
                  </button>
                </div>
              </>
            ) : (
              <button
                className="maximize-restore-icons minimizePopupButton icon_container"
                onClick={() => {
                  handleClose();
                  dispatch(setOutBoundCallPopUpVisibility(false));
                  dispatch(setMinimizePopUpShow(false));
                }}
              >
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  className="feather feather-x"
                >
                  <line
                    stroke="#b8c0c9"
                    fill="none"
                    x1="18"
                    y1="6"
                    x2="6"
                    y2="18"
                  />
                  <line
                    stroke="#b8c0c9"
                    fill="none"
                    x1="6"
                    y1="6"
                    x2="18"
                    y2="18"
                  />
                </svg>
              </button>
            )}
          </Modal.Header>
          <Modal.Body
            className={`d-flex align-items-center justify-content-center flex-column ${
              user_unique_key != callDetail?.senderID ? "pinUserArea" : ""
            }`}
          >
            {callDetail?.calling_status !== "decline" ? (
              <IframeCall
                url={url}
                room={callRoom}
                companyID={company_unique_key}
                user_unique_key={user_unique_key}
                login_token={login_token}
                callModalType="outBound"
                callDetails={callDetail}
                groupList={groupList}
                fullScreen={fullScreen}
              />
            ) : (
              VideoContent()
            )}
          </Modal.Body>
        </Resizable>
      </Modal>
      {recordingConsentPopUpEnable && (
        <RecordingConsent selectedLanguage={selectedLanguage} />
      )}
    </>
  );
};

// eslint-disable-next-line react/display-name
export default React.memo(OutBoundCall);
