/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect, useState } from "react";
import { useSpring, animated } from "@react-spring/web";
import { createUseGesture, dragAction, pinchAction } from "@use-gesture/react";
import { useDispatch, useSelector } from "react-redux";

import { getAuthSession } from "../Helpers/common";
import {
  hangupInboundCall,
  hangupOutBoundCall,
  hangupOutBoundMonitorCall,
} from "../Helpers/CallCommon";
import {
  setInBoundCallPopUpShow,
  setInBoundCallPopUpVisibility,
  setMinimizePopUpShow,
  setOutBoundCallPopUpShow,
  setOutBoundCallPopUpVisibility,
} from "../redux/callSlice";

const useGesture = createUseGesture([dragAction, pinchAction]);
const MinimizePopUp = () => {
  const [callerName, setCallerName] = useState("");
  const { user_unique_key } = getAuthSession();

  const { allAttendeeList, minimizePopUpInnerData, callDetail } = useSelector(
    (state) => state.call,
  );
  const { groupList, minimizePopUpShow } = useSelector((state) => state.chat);
  const { callType } = minimizePopUpInnerData;
  const dispatch = useDispatch();

  useEffect(() => {
    const { callerName } = minimizePopUpInnerData;
    setCallerName(callerName);
  });

  useEffect(() => {
    const handler = (e) => e.preventDefault();
    document.addEventListener("gesturestart", handler);
    document.addEventListener("gesturechange", handler);
    return () => {
      document.removeEventListener("gesturestart", handler);
      document.removeEventListener("gesturechange", handler);
    };
  }, []);

  const [style, api] = useSpring(() => ({
    x: 0,
    y: 0,
    scale: 1,
    rotateZ: 0,
  }));
  const ref = React.useRef(null);

  useGesture(
    {
      onDrag: ({ pinching, cancel, offset: [x, y] }) => {
        if (pinching) return cancel();
        api.start({ x, y });
      },
      onPinch: ({
        origin: [ox, oy],
        first,
        movement: [ms],
        offset: [s, a],
        memo,
      }) => {
        if (first) {
          const { width, height, x, y } = ref.current.getBoundingClientRect();
          const tx = ox - (x + width / 2);
          const ty = oy - (y + height / 2);
          memo = [style.x.get(), style.y.get(), tx, ty];
        }

        const x = memo[0] - ms * memo[2];
        const y = memo[1] - ms * memo[3];
        api.start({ scale: s, rotateZ: a, x, y });
        return memo;
      },
    },
    {
      target: ref,
      drag: { from: () => [style.x.get(), style.y.get()] },
      pinch: { scaleBounds: { min: 0.5, max: 2 }, rubberband: true },
    },
  );

  const MainCallPopUpHandler = () => {
    if (callType === "inbound") {
      dispatch(setInBoundCallPopUpVisibility(false));
    } else {
      dispatch(setOutBoundCallPopUpVisibility(false));
    }
  };

  const DeclineCall = async () => {
    const { type, calling_status, participantID } = callDetail;
    if (callType === "inbound") {
      let callingType = "decline";
      if (type === "private") {
        callingType = calling_status === "initiated" ? "decline" : "ended";
      } else {
        let index = participantID.findIndex((v) => v.id === user_unique_key);
        let participant = [...participantID];
        callingType =
          participant[index].status === "initiated" ? "decline" : "ended";
      }
      await hangupInboundCall(callingType);
      dispatch(setInBoundCallPopUpShow(false));
    } else {
      if (
        callDetail?.monitor_status === undefined ||
        callDetail?.monitor_status === false
      ) {
        await hangupOutBoundCall(
          callDetail,
          "ended",
          allAttendeeList,
          user_unique_key,
          groupList,
        );
      } else {
        await hangupOutBoundMonitorCall(callDetail, allAttendeeList);
      }
      dispatch(setOutBoundCallPopUpShow(false));
    }
    dispatch(setMinimizePopUpShow(false));
  };

  useEffect(() => {
    if (callDetail && Object.keys(callDetail).length > 0) {
      const { calling_status, monitor_status } = callDetail;
      if (
        calling_status &&
        calling_status == "decline" &&
        minimizePopUpShow &&
        monitor_status === false
      ) {
        if (callType === "outbound") {
          dispatch(setOutBoundCallPopUpVisibility(false));
          dispatch(setOutBoundCallPopUpShow(true));
        } else {
          dispatch(setInBoundCallPopUpShow(false));
          dispatch(setInBoundCallPopUpVisibility(false));
        }
        dispatch(setMinimizePopUpShow(false));
      }

      if (
        calling_status &&
        calling_status == "ended" &&
        minimizePopUpShow &&
        monitor_status === false
      ) {
        dispatch(setMinimizePopUpShow(false));
        if (callType === "outbound") {
          dispatch(setOutBoundCallPopUpShow(false));
          dispatch(setOutBoundCallPopUpVisibility(false));
        } else {
          dispatch(setInBoundCallPopUpShow(false));
          dispatch(setInBoundCallPopUpVisibility(false));
        }
      }
    }
  }, [callDetail]);

  return (
    <>
      <animated.div className="call-cut-div" ref={ref} style={style}>
        <span className="caller-name">{callerName}</span>
        <a className="call-cut-div-icons bg-red" onClick={() => DeclineCall()}>
          <svg height="22" width="22" viewBox="0 0 32 32">
            {" "}
            <path
              fill="#ffffff"
              d="M16 12c-2.125 0-4.188.313-6.125.938v4.125c0 .5-.313 1.063-.75 1.25a13.87 13.87 0 00-3.563 2.438c-.25.25-.563.375-.938.375s-.688-.125-.938-.375L.373 17.438c-.25-.25-.375-.563-.375-.938s.125-.688.375-.938c4.063-3.875 9.563-6.25 15.625-6.25s11.563 2.375 15.625 6.25c.25.25.375.563.375.938s-.125.688-.375.938l-3.313 3.313c-.25.25-.563.375-.938.375s-.688-.125-.938-.375a13.87 13.87 0 00-3.563-2.438c-.438-.188-.75-.625-.75-1.188V13c-1.938-.625-4-1-6.125-1z"
            ></path>
          </svg>
        </a>
        <div
          className="minimize-restore-icons"
          onClick={() => {
            dispatch(setMinimizePopUpShow(false));
            MainCallPopUpHandler();
          }}
        >
          <svg
            viewBox="0 0 24 24"
            width="24"
            height="24"
            stroke="currentColor"
            strokeWidth="2"
            fill="none"
            strokeLinecap="round"
            strokeLinejoin="round"
            className="css-i6dzq1"
          >
            <polyline points="15 3 21 3 21 9"></polyline>
            <polyline points="9 21 3 21 3 15"></polyline>
            <line x1="21" y1="3" x2="14" y2="10"></line>
            <line x1="3" y1="21" x2="10" y2="14"></line>
          </svg>
        </div>
      </animated.div>
    </>
  );
};

// eslint-disable-next-line react/display-name
export default React.memo(MinimizePopUp);
