/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState, useRef } from "react";
import { Modal, Button } from "react-bootstrap";
import SimpleReactValidator from "simple-react-validator";
import ReactTooltip from "react-tooltip";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import { v4 as uuidv4 } from "uuid";

import { _groupAdd } from "../../SocketIo";
import { getAuthSession, getUpdateUnreadChatMessage } from "../Helpers/common";
import { DropdownIndicator, ClearIndicator } from "../Helpers/common";
import { DYNAMIC_GROUP_URL } from "../../constants";
import { setAttendeeData } from "../redux/chatSlice";
import { globalStrings } from "../util/translation/languages";
const initialState = { group: "", description: "" };

const GroupAdd = (props) => {
  const { show, handleClose, chooseGroupTypeShow, setChooseGroupTypeShow } =
    props;
  const dispatch = useDispatch();
  const { user_unique_key: senderID, company_unique_key } = getAuthSession();
  const { attendeeList, selectedLanguage } = useSelector((state) => state.chat);
  const [state, setState] = React.useState(initialState);
  // eslint-disable-next-line no-unused-vars
  const [AttendeeListForGroup, setAttendeeListForGroup] = useState([]);
  const [isDisable, setIsDisable] = useState(false);
  const [selectedOption, setSelectedOption] = useState([]);
  const [hostOption, setHostOption] = useState(null);
  const [isDisabledGroupTypeButtons, setIsDisableGroupTypeButtons] =
    useState(false);
  const [, forceUpdate] = useState();
  const [attendeeError, setAttendeeError] = useState(false);
  const [groupNameError, setGroupNameError] = useState(false);
  const validator = useRef(
    new SimpleReactValidator({ autoForceUpdate: { forceUpdate: forceUpdate } }),
  );

  useEffect(() => {
    let list = [];
    attendeeList?.forEach((v) => {
      let data = {
        value: v.user_id,
        label: v.name,
        online: v.online,
        custom: true,
      };
      list.push(data);
    });
    setAttendeeListForGroup(list);
  }, [attendeeList]);

  const submitGroupForm = async (e) => {
    e.preventDefault();
    setIsDisable(true);
    const { group, description } = state;
    if (validator.current.allValid()) {
      if (selectedOption.length == 0) {
        setAttendeeError(true);
        setGroupNameError(true);
        setIsDisable(false);
      } else {
        const groupID = uuidv4();
        let attendeeUser = selectedOption
          ? selectedOption.map((v) => v.value)
          : [];
        let hostUser = hostOption ? hostOption.map((v) => v.value) : [];
        const combineAttendeeUser = [...attendeeUser, ...hostUser];
        const removedDuplicateUsers = [...new Set(combineAttendeeUser)];
        let Obj = {
          groupId: groupID,
          adminId: senderID,
          groupName: group,
          description,
          timestamp: Date.now(),
          attendeeId: [...removedDuplicateUsers, senderID],
          hostId: [...hostUser, senderID],
          lastactivity: Date.now(),
          company_id: company_unique_key,
          group_active: true,
        };
        _groupAdd(Obj);
        getUpdateUnreadChatMessage(groupID, "lastUserActivity");
        setSelectedOption([]);
        setHostOption([]);
        handleClose();
        setIsDisable(false);
        dispatch(setAttendeeData({}));
        setState(initialState);
        setAttendeeError(false);
        setGroupNameError(false);
      }
    } else {
      if (selectedOption.length === 0) {
        setAttendeeError(true);
      }
      validator.current.showMessages();
      forceUpdate(1);
      setIsDisable(false);
    }
  };

  const handleChange = (option) => {
    setSelectedOption(option);
    if (option.length === 0) {
      setAttendeeError(true);
    } else {
      setAttendeeError(false);
    }
  };

  const handleChangeGroupName = (e) => {
    if (!e.target.value) {
      setGroupNameError(true);
    } else {
      setState({ ...state, group: e.target.value });
      setGroupNameError(false);
    }
  };

  const hostHandleChange = (option) => {
    setHostOption(option);
  };

  const CustomOption = (props) => {
    const { data, innerRef, innerProps } = props;
    return data.custom ? (
      <div ref={innerRef} {...innerProps}>
        <span className="d-flex align-items-center Invite-People-drop">
          <span
            className={`status marginRight-5 ${data.online ? "online" : ""} `}
          ></span>{" "}
          {data.label}
        </span>
      </div>
    ) : (
      ""
    );
  };

  const dynamicGroupCreate = () => {
    setIsDisableGroupTypeButtons(true);
    handleClose();
    window.open(DYNAMIC_GROUP_URL, "_blank", "noreferrer");
  };

  return (
    <>
      <Modal
        show={show}
        onHide={() => {
          validator?.current?.hideMessages();
          setState(initialState);
          setSelectedOption([]);
          setHostOption(null);
          setIsDisable(false);
          setAttendeeError(false);
          setGroupNameError(false);
          handleClose();
        }}
      >
        <Modal.Header
          className={`${
            chooseGroupTypeShow ? "group-message-popup-header" : ""
          }`}
        >
          <Modal.Title>
            <span className="title-span">
              {chooseGroupTypeShow
                ? globalStrings[selectedLanguage]?.$createAGroup
                : globalStrings[selectedLanguage]?.$createStandardGroup}
            </span>
          </Modal.Title>

          <a
            className="icon-close"
            onClick={() => {
              validator?.current?.hideMessages();
              setState(initialState);
              setSelectedOption([]);
              setHostOption(null);
              setIsDisable(false);
              setAttendeeError(false);
              setGroupNameError(false);
              handleClose();
            }}
          >
            <svg
              width="15.556"
              height="15.556"
              viewBox="2240.448 1762.444 15.556 15.556"
            >
              <path
                d="m2250.727 1766.055-2.5 2.5-2.5-2.5a.786.786 0 0 0-1.112 0l-.555.556a.786.786 0 0 0 0 1.11l2.5 2.5-2.5 2.501a.786.786 0 0 0 0 1.111l.555.556a.786.786 0 0 0 1.111 0l2.5-2.5 2.5 2.5a.786.786 0 0 0 1.112 0l.555-.556a.786.786 0 0 0 0-1.11l-2.5-2.501 2.5-2.5a.786.786 0 0 0 0-1.111l-.555-.556a.786.786 0 0 0-1.111 0Z"
                fill="#2d343f"
              />
            </svg>
          </a>
        </Modal.Header>
        <Modal.Body
          className={`${
            chooseGroupTypeShow
              ? "group-message-popup-body"
              : "create-standard-group"
          }`}
        >
          {chooseGroupTypeShow ? (
            <>
              <p className="fw--500">
                {globalStrings[selectedLanguage]?.$pTagGroupAdd}
              </p>
              <div className=" d-flex justify-content-center mt-5 gap--20">
                <Button
                  className="btn btn-green"
                  type="submit"
                  id="submit"
                  onClick={() => {
                    setIsDisableGroupTypeButtons(true);
                    setChooseGroupTypeShow(false);
                  }}
                  disabled={isDisabledGroupTypeButtons}
                >
                  <span className="tooltip" data-tip data-for="standardGroup">
                    <a
                      href="javascript:void(0)"
                      className="icon-tooltip d-flex align-items-center"
                    >
                      <img
                        src="./assets/images/icon.svg"
                        alt="Tooltip"
                        className="w-100"
                      />
                    </a>
                    <ReactTooltip
                      id={"standardGroup"}
                      place="bottom"
                      effect="solid"
                      className="group-tooltip"
                    >
                      {globalStrings[selectedLanguage]?.$reactToolTipLine1}
                      <br />
                      {globalStrings[selectedLanguage]?.$reactToolTipLine2}
                    </ReactTooltip>
                  </span>
                  <span className="flex-1 btn-span">
                    {globalStrings[selectedLanguage]?.$standardGroup}
                  </span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                  >
                    <path
                      fill="#fff"
                      d="m11.293 17.293 1.414 1.414L19.414 12l-6.707-6.707-1.414 1.414L15.586 11H6v2h9.586z"
                    ></path>
                  </svg>
                </Button>
                {DYNAMIC_GROUP_URL && (
                  <Button
                    className="btn btn-green"
                    type="submit"
                    id="submit"
                    onClick={() => dynamicGroupCreate()}
                    disabled={isDisabledGroupTypeButtons}
                  >
                    <span className="tooltip" data-tip data-for="dynamicGroup">
                      <a
                        href="javascript:void(0)"
                        className="icon-tooltip d-flex align-items-center"
                      >
                        <img
                          src="./assets/images/icon.svg"
                          alt="Tooltip"
                          className="w-100"
                        />
                      </a>
                      <ReactTooltip
                        id={"dynamicGroup"}
                        place="bottom"
                        effect="solid"
                        className="group-tooltip"
                      >
                        {globalStrings[selectedLanguage]?.$secondToolTipLine1}
                        <br />
                        {globalStrings[selectedLanguage]?.$secondToolTipLine2}
                        <br />
                        {globalStrings[selectedLanguage]?.$secondToolTipLine3}
                        <br />
                        {globalStrings[selectedLanguage]?.$secondToolTipLine4}
                      </ReactTooltip>
                    </span>
                    <span className="flex-1 btn-span">
                      {globalStrings[selectedLanguage]?.$dynamicGroup}
                    </span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                    >
                      <path
                        fill="#fff"
                        d="m11.293 17.293 1.414 1.414L19.414 12l-6.707-6.707-1.414 1.414L15.586 11H6v2h9.586z"
                      ></path>
                    </svg>
                  </Button>
                )}
              </div>
            </>
          ) : (
            <>
              <p className="fw--500">
                {globalStrings[selectedLanguage]?.$pTagStandardGroup}
              </p>
              <form>
                <div className="form-group form-floating align-items-center">
                  <input
                    spellCheck="false"
                    type="text"
                    onChange={(e) => handleChangeGroupName(e)}
                    name="group"
                    placeholder="Group Name"
                    className="form-control"
                    list="channels"
                    id="channel"
                  />
                  {groupNameError && (
                    <div className="srv-validation-message">
                      {globalStrings[selectedLanguage]?.$groupNameErrorMsg}
                    </div>
                  )}
                  <label htmlFor="username">
                    {globalStrings[selectedLanguage]?.$groupName}
                  </label>
                </div>

                <div className="form-group form-floating align-items-center">
                  <input
                    spellCheck="false"
                    type="text"
                    onChange={(e) =>
                      setState({ ...state, description: e.target.value })
                    }
                    name="description"
                    placeholder="Description"
                    className="form-control"
                    list="channels"
                    id="channel"
                  />
                  <label htmlFor="username">
                    {globalStrings[selectedLanguage]?.$description}
                  </label>
                </div>
                <div className="form-group menu-upside form-floating">
                  <Select
                    className="form-control"
                    isMulti={true}
                    value={selectedOption}
                    onChange={(e) => handleChange(e)}
                    options={AttendeeListForGroup}
                    components={{
                      Option: CustomOption,
                      DropdownIndicator,
                      ClearIndicator,
                    }}
                    placeholder={
                      globalStrings[selectedLanguage]?.$selectedAttendee
                    }
                    classNamePrefix="select-menu-custom"
                  />
                  {attendeeError && (
                    <div className="srv-validation-message">
                      {
                        globalStrings[selectedLanguage]
                          .$selectAttendeesIsMandatory
                      }
                    </div>
                  )}
                </div>
                <div className="form-group menu-upside form-floating">
                  <Select
                    className="form-control"
                    isMulti={true}
                    value={hostOption}
                    onChange={(e) => hostHandleChange(e)}
                    options={AttendeeListForGroup}
                    components={{
                      Option: CustomOption,
                      DropdownIndicator,
                      ClearIndicator,
                    }}
                    placeholder={
                      globalStrings[selectedLanguage]?.$selectAdditionalHosts
                    }
                    classNamePrefix="select-menu-custom"
                  />
                </div>
              </form>
            </>
          )}
        </Modal.Body>
        {!chooseGroupTypeShow && (
          <Modal.Footer className="create-standard-group-footer">
            <Button
              className="btn btn-light btn-sm"
              type="submit"
              id="submit"
              onClick={() => {
                setIsDisableGroupTypeButtons(false);
                setChooseGroupTypeShow(true);
                handleClose();
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                className="mr-1"
              >
                <path
                  fill="#b8c0c9"
                  d="M12.707 17.293 8.414 13H18v-2H8.414l4.293-4.293-1.414-1.414L4.586 12l6.707 6.707z"
                ></path>
              </svg>
              <span className="flex-1 btn-span">
                {globalStrings[selectedLanguage]?.$back}
              </span>
            </Button>
            <Button
              className="btn btn-green"
              type="submit"
              id="submit"
              onClick={submitGroupForm}
              disabled={isDisable}
            >
              <span className="flex-1 btn-span">
                {globalStrings[selectedLanguage]?.$createGroup}
              </span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                className="ml-1"
              >
                <path
                  fill="#fff"
                  d="m11.293 17.293 1.414 1.414L19.414 12l-6.707-6.707-1.414 1.414L15.586 11H6v2h9.586z"
                ></path>
              </svg>
            </Button>
          </Modal.Footer>
        )}
      </Modal>
    </>
  );
};

export default GroupAdd;
