/* eslint-disable indent */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import ReactTooltip from "react-tooltip";

import {
  getAuthSession,
  timestampToDateTime,
  getUpdateUnreadChatMessage,
} from "../Helpers/common";
import AlertModal from "../models/AlertModal";
// eslint-disable-next-line import/namespace
import GroupAddUser from "./GroupAddUser";
import { _groupRemove, _groupUsersRemove } from "../../SocketIo";
import GroupEditTitleAndDescription from "./GroupEditTitleAndDescription";
import { setAttendeeData } from "../redux/chatSlice";
import { SortBasedOnNames } from "../Helpers/chatCommon";
import { globalStrings } from "../util/translation/languages";
import { alertMsg } from "../util/alertMsg";

const GroupSetting = (props) => {
  const { show, handleClose } = props;
  const dispatch = useDispatch();
  const { allAttendeeList } = useSelector((state) => state.call);
  const notificationSoundData = useSelector(
    (state) => state.chat.unreadChatMessage,
  );
  const { attendeeData, selectedLanguage } = useSelector((state) => state.chat);
  const { user_unique_key: senderID } = getAuthSession();
  // eslint-disable-next-line no-unused-vars
  const [createdBy, setCreatedBy] = useState(null);
  const [createdOn, setCreatedOn] = useState(null);
  const [showUser, setShowUser] = useState(false);
  const [attendeeListForGroup, setAttendeeListForGroup] = useState([]);
  const [userGroupDelete, setUserGroupDelete] = useState(false);
  const [userGroupLeave, setUserGroupLeave] = useState(false);
  const [userAddedData, setUserAddedData] = useState("");
  const [openAlert, setOpenAlert] = useState(false);
  const [alertType, setAlertType] = useState("");
  const [msg, setMsg] = useState("");
  const [memberID, setMemberID] = useState("");
  const [groupMute, setGroupMute] = useState(false);
  const [editTitleAndDescription, setEditTitleAndDescription] = useState(false);
  const [editType, setEditType] = useState("");
  const [groupaddType, setGroupaddType] = useState("");
  let username = attendeeData.name;
  let role = attendeeData.role_name;
  let groupId = "";
  let groupAdmin = "";
  let host = "";
  let groupSettingDisabled = false;

  if (attendeeData.type == "group") {
    groupId = attendeeData.groupId;
    username = attendeeData.groupName;
    role = attendeeData.description;
    groupAdmin = attendeeData.adminId;
    groupSettingDisabled = attendeeData?.managed === "Y" ? true : false;
  }

  useEffect(() => {
    if (groupId != "") {
      const notificationSoundElement = notificationSoundData.filter(
        (filterItem) =>
          filterItem.id === groupId || filterItem.user_id === groupId,
      );

      if (notificationSoundElement.length > 0) {
        const groupMuteValue = notificationSoundElement[0]?.group_mute ?? false;
        setGroupMute(groupMuteValue);
      }
    }
  }, [notificationSoundData, groupId]);

  useEffect(async () => {
    allAttendeeList.some((v) => {
      let date = timestampToDateTime(attendeeData.timestamp);
      setCreatedOn(date);
      if (v?.user_id == attendeeData.adminId) {
        setCreatedBy(v.name);
      }
      const IsUserExistAsHost =
        attendeeData?.hostId?.findIndex(
          (filterItem) => filterItem === senderID,
        ) ?? -1;
      if (IsUserExistAsHost !== -1 || senderID == attendeeData.adminId) {
        setUserGroupDelete(true);
        setUserGroupLeave(false);
      } else {
        setUserGroupLeave(true);
        setUserGroupDelete(false);
      }
    });
  }, [allAttendeeList, attendeeData]);

  useEffect(async () => {
    if (userAddedData != "" || userAddedData != null) {
      if (groupId) {
        setShowUser(false);
        setUserAddedData("");
      }
    }
  }, [userAddedData, openAlert]);

  useEffect(async () => {
    if (attendeeData && attendeeData.type == "group") {
      setArray(allAttendeeList);
    }
  }, [attendeeData, allAttendeeList]);

  const setArray = (obj) => {
    const hostId = attendeeData?.hostId ?? [];
    let list = [];
    obj.forEach((v) => {
      if (attendeeData?.attendeeId?.includes(v?.user_id)) {
        const isUserExistAsHost = hostId.findIndex(
          (item) => item === v?.user_id,
        );
        if (v?.user_id == groupAdmin || isUserExistAsHost !== -1) {
          host = "Admin";
        } else {
          host = "";
        }

        let data = {
          value: v?.user_id,
          label: v.name,
          userrole: v.role_name,
          host: host,
          last_seen: v.last_seen,
          user_profile_image: v.user_profile_image,
          online: v?.online ? true : false,
        };
        list.push(data);
      }
    });

    setAttendeeListForGroup(list);
    return list;
  };

  const removeGroupMember = async (value, mode) => {
    if (mode === "leave") {
      setMsg(`Are you sure you want to Leave ${username} Group ?`);
      setAlertType("groupLeave");
    } else {
      setMsg(`Are you sure you want to Remove ${value.label} from Group ?`);
      setAlertType("groupMember");
    }

    setOpenAlert(true);
    setMemberID(value.value);
  };

  const Remove = async () => {
    let removedAttendeeIdList = attendeeData?.attendeeId?.filter(
      (item) => item !== memberID,
    );
    let removedHostList = attendeeData?.hostId?.filter(
      (item) => item !== memberID,
    );

    dispatch(
      setAttendeeData({
        ...attendeeData,
        attendeeId: removedAttendeeIdList,
        hostId: removedHostList,
      }),
    );
    const removedGroup = { ...attendeeData };
    removedGroup["attendeeId"] = removedAttendeeIdList;
    removedGroup["hostId"] = removedHostList;
    _groupUsersRemove(removedGroup);
    setOpenAlert(false);
  };

  let Admin = attendeeListForGroup?.find((v) => v.value === senderID) ?? {};
  let AllHost = attendeeListForGroup?.filter((v) => v.host === "Admin") ?? {};

  const deleteGroupConfirmation = async () => {
    setOpenAlert(true);
    setMsg(`Are you sure you want to Delete ${username} Group ?`);
    setAlertType("group");
  };

  const deleteGroup = () => {
    const hostId = attendeeData?.hostId ?? [];
    const isUserExistAsHost = hostId.findIndex((item) => item === senderID);
    if (groupAdmin == senderID || isUserExistAsHost !== -1) {
      _groupRemove(attendeeData);
      setOpenAlert(false);
      setAlertType("");
      handleClose();
      dispatch(setAttendeeData({}));
    } else {
      alert(alertMsg.adminDelete);
    }
  };
  const leaveGroupConfirmation = async () => {
    setOpenAlert(true);
    setMsg(`Are you sure you want to Leave ${username} Group ?`);
    setAlertType("groupLeave");
  };

  const leaveGroup = () => {
    let attendeeUser = attendeeData.attendeeId.filter((v) => v !== senderID);
    let groupDetails = {
      ...attendeeData,
      attendeeId: attendeeUser,
    };

    const updatedGroupData = { ...attendeeData };
    updatedGroupData["attendeeId"] = attendeeUser;
    _groupUsersRemove(updatedGroupData);
    dispatch(setAttendeeData(groupDetails));
    handleClose();
    setOpenAlert(false);
    setAlertType("");
  };

  const groupMuteHandler = () => {
    getUpdateUnreadChatMessage(groupId, "groupMute");
  };

  const addPeopleHandler = () => {
    setGroupaddType("attendeee");
    setShowUser(true);
  };

  const addHostHandler = () => {
    setGroupaddType("host");
    setShowUser(true);
  };

  const editTitleAndDescriptionHandler = (type) => {
    setEditType(type);
    setEditTitleAndDescription(true);
  };
  const closeEditTitleAndDescription = () => {
    setEditTitleAndDescription(false);
    setEditType("");
  };
  let GroupAddUserProps = {
    setUserAddedData,
    show: showUser,
    groupaddType,
  };
  let editTitleAndDescriptionProps = {
    show: editTitleAndDescription,
    handleClose: closeEditTitleAndDescription,
    editType,
  };
  return (
    <>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header>
          <Modal.Title>
            <span className="title-span">{username}</span>
          </Modal.Title>
          <a onClick={handleClose} className="icon-close">
            <svg
              width="15.556"
              height="15.556"
              viewBox="2240.448 1762.444 15.556 15.556"
            >
              <path
                d="m2250.727 1766.055-2.5 2.5-2.5-2.5a.786.786 0 0 0-1.112 0l-.555.556a.786.786 0 0 0 0 1.11l2.5 2.5-2.5 2.501a.786.786 0 0 0 0 1.111l.555.556a.786.786 0 0 0 1.111 0l2.5-2.5 2.5 2.5a.786.786 0 0 0 1.112 0l.555-.556a.786.786 0 0 0 0-1.11l-2.5-2.501 2.5-2.5a.786.786 0 0 0 0-1.111l-.555-.556a.786.786 0 0 0-1.111 0Z"
                fill="#2d343f"
              />
            </svg>
          </a>
        </Modal.Header>
        <Modal.Body>
          <div>
            <ul className="nav nav-tabs" id="myTab" role="tablist">
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link active"
                  id="about-tab"
                  data-toggle="tab"
                  data-target="#about"
                  type="button"
                  role="tab"
                  aria-controls="about"
                  aria-selected="true"
                >
                  {globalStrings[selectedLanguage]?.$about}
                </button>
              </li>

              <>
                {openAlert && (
                  <AlertModal
                    show={openAlert}
                    closeModal={() => setOpenAlert(false)}
                    msg={msg}
                    Remove={
                      alertType === "group"
                        ? deleteGroup
                        : alertType === "groupLeave"
                        ? leaveGroup
                        : Remove
                    }
                    alertType={alertType}
                    selectedLanguage={selectedLanguage}
                  />
                )}
                <li className="nav-item" role="presentation">
                  <button
                    className="nav-link"
                    id="members-tab"
                    data-toggle="tab"
                    data-target="#members"
                    type="button"
                    role="tab"
                    aria-controls="members"
                    aria-selected="false"
                  >
                    {globalStrings[selectedLanguage]?.$members}
                  </button>
                </li>
              </>
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link"
                  id="settings-tab"
                  data-toggle="tab"
                  data-target="#settings"
                  type="button"
                  role="tab"
                  aria-controls="settings"
                  aria-selected="false"
                >
                  {globalStrings[selectedLanguage]?.$settings}
                </button>
              </li>
            </ul>
            <div className="tab-content tab-content-min tab-content-max">
              <div
                className="tab-pane fade showsetting active"
                id="about"
                role="tabpanel"
                aria-labelledby="about-tab"
              >
                <div className="border_block">
                  <div className="border_block__inner">
                    <div className="d-flex justify-content-between w-100 align-items-center">
                      <label className="text-label mb-0">{username}</label>
                      <div>
                        <span
                          className={`d-flex align-items-center icon-lock ${
                            groupSettingDisabled
                              ? "cursor-not-allowed"
                              : "cursor-pointer"
                          }`}
                          onClick={() =>
                            !groupSettingDisabled
                              ? editTitleAndDescriptionHandler("name")
                              : null
                          }
                          data-tip
                          data-for="groupName"
                        >
                          <svg width="14" viewBox="3 2.41 18.59 18.59">
                            <path
                              fill="#425b76"
                              d="M8.707 19.707 18 10.414 13.586 6l-9.293 9.293a1.003 1.003 0 0 0-.263.464L3 21l5.242-1.03c.176-.044.337-.135.465-.263zM21 7.414a2 2 0 0 0 0-2.828L19.414 3a2 2 0 0 0-2.828 0L15 4.586 19.414 9 21 7.414z"
                            ></path>
                          </svg>
                        </span>
                        {groupSettingDisabled && (
                          <ReactTooltip
                            id="groupName"
                            place="top"
                            effect="solid"
                          >
                            {
                              globalStrings[selectedLanguage]
                                .$toolTipForGroupSetting
                            }
                          </ReactTooltip>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="border_block">
                  <div className="border_block__inner btm-border pb-1 mb-1">
                    <div className="d-flex flex-column">
                      <label className="d-flex align-items-center text-label mb-0">
                        {globalStrings[selectedLanguage]?.$groupDescription}
                      </label>

                      <span>{role}</span>
                    </div>

                    <>
                      <span
                        className={`d-flex align-items-center icon-lock ${
                          groupSettingDisabled
                            ? "cursor-not-allowed"
                            : "cursor-pointer"
                        }`}
                        onClick={() =>
                          !groupSettingDisabled
                            ? editTitleAndDescriptionHandler("description")
                            : null
                        }
                        data-tip
                        data-for="groupDescription"
                      >
                        <svg width="14" viewBox="3 2.41 18.59 18.59">
                          <path
                            fill="#425b76"
                            d="M8.707 19.707 18 10.414 13.586 6l-9.293 9.293a1.003 1.003 0 0 0-.263.464L3 21l5.242-1.03c.176-.044.337-.135.465-.263zM21 7.414a2 2 0 0 0 0-2.828L19.414 3a2 2 0 0 0-2.828 0L15 4.586 19.414 9 21 7.414z"
                          ></path>
                        </svg>
                      </span>
                      {groupSettingDisabled && (
                        <ReactTooltip
                          id="groupDescription"
                          place="top"
                          effect="solid"
                        >
                          {
                            globalStrings[selectedLanguage]
                              .$toolTipForGroupSetting
                          }
                        </ReactTooltip>
                      )}
                    </>
                  </div>
                  <div className="border_block__inner">
                    <div className="d-flex flex-column">
                      <label className="text-label mb-0">
                        {globalStrings[selectedLanguage]?.$createdBy}
                      </label>
                      <span>
                        {createdBy} {createdOn}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="tab-pane fade"
                id="members"
                role="tabpanel"
                aria-labelledby="members-tab"
              >
                {userGroupDelete ? (
                  <>
                    <div className="tooltip">
                      <span
                        className="list justify-content-start"
                        onClick={() =>
                          !groupSettingDisabled ? addPeopleHandler() : null
                        }
                        data-tip
                        data-for="addPeople"
                      >
                        <a
                          className={`avatar align-items-center ${
                            groupSettingDisabled ? "cursor-not-allowed" : ""
                          }`}
                        >
                          <svg height="50%" viewBox="2 5.05 20 13.95">
                            <path
                              fill="#425b76"
                              d="M4.5 8.552c0 1.995 1.505 3.5 3.5 3.5s3.5-1.505 3.5-3.5-1.505-3.5-3.5-3.5-3.5 1.505-3.5 3.5zM19 8h-2v3h-3v2h3v3h2v-3h3v-2h-3zM4 19h10v-1c0-2.757-2.243-5-5-5H7c-2.757 0-5 2.243-5 5v1h2z"
                            ></path>
                          </svg>
                        </a>
                        <a
                          className={`d-flex flex-column info w-auto ${
                            groupSettingDisabled ? "cursor-not-allowed" : ""
                          }`}
                        >
                          <div className="name unread-msg-name text-link">
                            {globalStrings[selectedLanguage]?.$addPeople}
                          </div>
                        </a>
                      </span>
                      {groupSettingDisabled && (
                        <ReactTooltip id="addPeople" place="top" effect="solid">
                          {
                            globalStrings[selectedLanguage]
                              .$toolTipForGroupSetting
                          }
                        </ReactTooltip>
                      )}
                    </div>
                    <div className="tooltip">
                      <span
                        className="list justify-content-start"
                        onClick={() =>
                          !groupSettingDisabled ? addHostHandler() : null
                        }
                        data-tip
                        data-for="addHost"
                      >
                        <a
                          className={`avatar align-items-center ${
                            groupSettingDisabled ? "cursor-not-allowed" : ""
                          }`}
                        >
                          <svg height="50%" viewBox="2 5.05 20 13.95">
                            <path
                              fill="#425b76"
                              d="M4.5 8.552c0 1.995 1.505 3.5 3.5 3.5s3.5-1.505 3.5-3.5-1.505-3.5-3.5-3.5-3.5 1.505-3.5 3.5zM19 8h-2v3h-3v2h3v3h2v-3h3v-2h-3zM4 19h10v-1c0-2.757-2.243-5-5-5H7c-2.757 0-5 2.243-5 5v1h2z"
                            ></path>
                          </svg>
                        </a>
                        <a
                          className={`d-flex flex-column info w-auto ${
                            groupSettingDisabled ? "cursor-not-allowed" : ""
                          }`}
                        >
                          <div className="name unread-msg-name text-link">
                            {globalStrings[selectedLanguage]?.$addHost}
                          </div>
                        </a>
                      </span>
                      {groupSettingDisabled && (
                        <ReactTooltip id="addHost" place="top" effect="solid">
                          {
                            globalStrings[selectedLanguage]
                              .$toolTipForGroupSetting
                          }
                        </ReactTooltip>
                      )}
                    </div>
                  </>
                ) : (
                  ""
                )}

                {attendeeListForGroup.sort(SortBasedOnNames).map((v, index) => {
                  let availabilityStatus = v?.online ?? false;
                  return (
                    <span className="list" key={index}>
                      <div className="avatar align-items-center cursor-intials">
                        <img
                          src={`${
                            v.user_profile_image ? (
                              v.user_profile_image
                            ) : (
                              <svg viewBox="3 2 18 19">
                                <path
                                  fill="#425b76"
                                  d="M7.5 6.5C7.5 8.981 9.519 11 12 11s4.5-2.019 4.5-4.5S14.481 2 12 2 7.5 4.019 7.5 6.5zM20 21h1v-1c0-3.859-3.141-7-7-7h-4c-3.86 0-7 3.141-7 7v1h17z"
                                ></path>
                              </svg>
                            )
                          }`}
                          alt=""
                        ></img>
                        <span
                          className={`status ${
                            availabilityStatus === true ? "online" : ""
                          }`}
                        ></span>
                      </div>
                      <div className="d-flex info justify-content-between align-items-center">
                        <div className="d-flex">
                          <div className="name unread-msg-name cursor-intials">
                            {v.label}
                          </div>
                          <a className="text-highlight ml-2 cursor-intials">
                            <small>{v.host === "Admin" ? "Host" : ""}</small>
                          </a>
                        </div>
                        <div className="d-flex align-items-center">
                          <div className="tooltip">
                            <a
                              href={void 0}
                              className={`d-flex align-items-center justify-content-end delete-icon ${
                                groupSettingDisabled ? "cursor-not-allowed" : ""
                              }`}
                              data-tip
                              data-for="deleteUser"
                            >
                              {Admin.host === "Admin" &&
                                (v.value !== Admin.value ? (
                                  <>
                                    <svg
                                      width="16"
                                      onClick={() =>
                                        !groupSettingDisabled
                                          ? removeGroupMember(v, "remove")
                                          : null
                                      }
                                      viewBox="3 2 18 20"
                                    >
                                      <path
                                        fill={`${
                                          groupSettingDisabled
                                            ? "#425b76"
                                            : "#f05a28"
                                        }`}
                                        d="M6 7H5v13a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V7H6zm4 12H8v-9h2v9zm6 0h-2v-9h2v9zm.618-15L15 2H9L7.382 4H3v2h18V4z"
                                      ></path>
                                    </svg>
                                  </>
                                ) : AllHost.length > 1 ? (
                                  <>
                                    <span
                                      onClick={() =>
                                        !groupSettingDisabled
                                          ? removeGroupMember(v, "leave")
                                          : null
                                      }
                                    >
                                      Leave this group
                                    </span>
                                  </>
                                ) : null)}
                            </a>
                            {groupSettingDisabled && (
                              <ReactTooltip
                                id="deleteUser"
                                place="top"
                                effect="solid"
                              >
                                {
                                  globalStrings[selectedLanguage]
                                    .$toolTipForGroupSetting
                                }
                              </ReactTooltip>
                            )}
                          </div>
                        </div>
                      </div>
                    </span>
                  );
                })}
              </div>
              <div
                className="tab-pane fade"
                id="settings"
                role="tabpanel"
                aria-labelledby="settings-tab"
              >
                <div className="border_block">
                  <div className="border_block__inner">
                    <div>
                      <label className="text-label mb-0"> {username}</label>
                      <span className="d-flex align-items-center">{role}</span>
                    </div>
                  </div>
                </div>

                <div className="border_block bg_leave">
                  {userGroupDelete ? (
                    <AdminDeleteGroup
                      deleteGroupConfirmation={deleteGroupConfirmation}
                      groupSettingDisabled={groupSettingDisabled}
                      selectedLanguage={selectedLanguage}
                    />
                  ) : null}
                  {userGroupLeave ? (
                    <UserLeaveGroup
                      leaveGroupConfirmation={leaveGroupConfirmation}
                      selectedLanguage={selectedLanguage}
                    />
                  ) : null}
                </div>
                <div className="w-100 d-flex align-items-center mt-3">
                  <div className="toggle-switch">
                    <input
                      type="checkbox"
                      className="checkbox"
                      id="noti"
                      checked={groupMute}
                      onClick={() => groupMuteHandler()}
                    />
                    <label className="label" htmlFor="noti">
                      <span className="inner" />
                      <span className="switch" />
                    </label>
                  </div>
                  <span className="ml-2 mb-0 d-flex align-items-center">
                    {globalStrings[selectedLanguage]?.$muteGroup}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>
      {userAddedData == "" || userAddedData == null ? (
        <GroupAddUser
          {...GroupAddUserProps}
          handleClose={() => {
            setGroupaddType("");
            setShowUser(false);
          }}
        />
      ) : (
        ""
      )}

      {editTitleAndDescription && (
        <GroupEditTitleAndDescription {...editTitleAndDescriptionProps} />
      )}
    </>
  );
};

export default GroupSetting;

const AdminDeleteGroup = ({
  deleteGroupConfirmation,
  groupSettingDisabled,
  selectedLanguage,
}) => {
  return (
    <div className="border_block__inner ">
      <div className="d-flex flex-column">
        <a
          className={`text-label mb-0 text-highlight d-flex align-items-center ${
            groupSettingDisabled ? "cursor-not-allowed" : null
          }`}
          onClick={(e) =>
            !groupSettingDisabled ? deleteGroupConfirmation(e) : null
          }
          data-tip
          data-for="deleteGroup"
        >
          <svg width="13" viewBox="3 2 18 20">
            <path
              fill="#F85800"
              d="M5 20a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V8h2V6h-4V4a2 2 0 0 0-2-2H9a2 2 0 0 0-2 2v2H3v2h2zM9 4h6v2H9zM8 8h9v12H7V8z"
            ></path>
            <path fill="#F85800" d="M9 10h2v8H9zm4 0h2v8h-2z"></path>
          </svg>
          &nbsp; {globalStrings[selectedLanguage]?.$deleteThisGroup}
        </a>
        {groupSettingDisabled && (
          <ReactTooltip id="deleteGroup" place="top" effect="solid">
            {globalStrings[selectedLanguage]?.$toolTipForGroupSetting}
          </ReactTooltip>
        )}
      </div>
    </div>
  );
};

const UserLeaveGroup = ({ leaveGroupConfirmation, selectedLanguage }) => {
  return (
    <div className="border_block__inner">
      <div className="d-flex flex-column">
        <a
          className="text-label mb-0 text-highlight d-flex align-items-center"
          onClick={(e) => leaveGroupConfirmation(e)}
        >
          <svg width="13" viewBox="3 2 18 20">
            <path
              fill="#F85800"
              d="M5 20a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V8h2V6h-4V4a2 2 0 0 0-2-2H9a2 2 0 0 0-2 2v2H3v2h2zM9 4h6v2H9zM8 8h9v12H7V8z"
            ></path>
            <path fill="#F85800" d="M9 10h2v8H9zm4 0h2v8h-2z"></path>
          </svg>
          &nbsp; {globalStrings[selectedLanguage]?.$leaveThisGroup}
        </a>
      </div>
    </div>
  );
};
