/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/media-has-caption */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/anchor-is-valid */

import React, { useEffect, useRef, useState } from "react";
import { Modal } from "react-bootstrap";
import {
  _1GB,
  alertMsgDialog,
  getAuthSession,
  uploadAudioVideoFile,
  uploadFileMimeType,
} from "../Helpers/common";
import { fetchWasabiImageUploadURL } from "../Helpers/wasabiFileUpload";
import { UPLOAD_API_URL } from "../../constants";
import { useSelector } from "react-redux";
import { _updateCall } from "../../SocketIo";
import ProgressBar from "../util/progressBar";

const UploadFile = ({ openUploadModal, setOpenUploadModal }) => {
  const [selectedFile, setSelectedFile] = useState([]);
  const [loader, setLoader] = useState(false);
  const { callDetail, sharedFileProgressBar } = useSelector(
    (state) => state.call,
  );
  const { user_unique_key, company_unique_key } = getAuthSession();
  const drop = useRef(null);

  const onFileChange = async (e) => {
    const files = e.target.files;
    onUpload(files);
  };

  const onUpload = (files) => {
    let fileType = files[0]?.type;
    let fileSize = files[0]?.size;
    if (uploadFileMimeType().includes(fileType)) {
      if (fileSize <= _1GB) {
        setSelectedFile(files);
      } else {
        alertMsgDialog({
          msg: "File too large. Please upload a file less than 1 GB.",
          type: "info",
        });
      }
    } else {
      document.getElementById("shareFile").value = "";
      alertMsgDialog({
        msg: "Please upload a valid audio file.",
        type: "info",
      });
    }
  };

  const shareFile = async () => {
    setLoader(true);
    const selectedFilesData = [...selectedFile];
    const [fileResponse] = await uploadAudioVideoFile(selectedFilesData);
    let fileName =
      fileResponse && fileResponse.length > 0 && fileResponse[0].attachmentName;
    if (fileName) {
      const downloadUrl = `${UPLOAD_API_URL}download`;
      const getWasabiUrl = await fetchWasabiImageUploadURL(downloadUrl, {
        file: fileName,
        company_id: company_unique_key,
      });
      if (getWasabiUrl?.status === 200) {
        const typeData = selectedFilesData[0]?.type?.split("/");

        const url = getWasabiUrl?.data?.url;
        const payload = {
          fileType: typeData[0],
          fileUrl: url,
          play: true,
          user_id: user_unique_key,
        };
        const updatedCallDetail = { ...callDetail };
        updatedCallDetail["sharedFile"] = payload;

        _updateCall(updatedCallDetail);
        // shareFileData(payload);
      }
    }

    setSelectedFile("");
    setLoader(false);
    setOpenUploadModal(false);
  };

  useEffect(() => {
    drop?.current?.addEventListener("dragover", handleDragOver);
    drop?.current?.addEventListener("drop", handleDrop);

    return () => {
      drop?.current?.removeEventListener("dragover", handleDragOver);
      drop?.current?.removeEventListener("drop", handleDrop);
    };
  }, []);

  const handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const { files } = e.dataTransfer;

    if (files && files.length) {
      onUpload(files);
    }
  };

  return (
    <Modal
      show={openUploadModal}
      className="modal-sm"
      onHide={() => setOpenUploadModal(false)}
    >
      <Modal.Header className="pb-3">
        <Modal.Title className="justify-content-end">
          Share Audio/Video
          <a
            className="icon-close top-10"
            onClick={() => setOpenUploadModal(false)}
          >
            <svg
              width="15.556"
              height="15.556"
              viewBox="2240.448 1762.444 15.556 15.556"
            >
              <path
                d="m2250.727 1766.055-2.5 2.5-2.5-2.5a.786.786 0 0 0-1.112 0l-.555.556a.786.786 0 0 0 0 1.11l2.5 2.5-2.5 2.501a.786.786 0 0 0 0 1.111l.555.556a.786.786 0 0 0 1.111 0l2.5-2.5 2.5 2.5a.786.786 0 0 0 1.112 0l.555-.556a.786.786 0 0 0 0-1.11l-2.5-2.501 2.5-2.5a.786.786 0 0 0 0-1.111l-.555-.556a.786.786 0 0 0-1.111 0Z"
                fill="#2d343f"
              />
            </svg>
          </a>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {
          <>
            <div>
              <div className="form-group" ref={drop}>
                <label className="form-label">Upload File</label>

                <>
                  <div className="position-relative bg-light p-2 d-flex align-items-center border-radius">
                    <input
                      className="form-control w-100 opacity-0 position-absolute z-index-1 p-0 h-100 cursor-pointer"
                      type="file"
                      name="file"
                      accept="video/mp4,video/x-m4v,video/*,audio/x-m4a,audio/*"
                      id="shareFile"
                      onChange={(e) => onFileChange(e)}
                    />
                    <button className="btn btn-white btn-file">
                      Choose file
                    </button>
                    <span className="pl-2">{selectedFile[0]?.name}</span>
                  </div>
                </>
              </div>
              <small>
                Note: Please upload MP3,WAV,MP4 and MOV file (Max size 1GB).
              </small>
            </div>
            {sharedFileProgressBar !== "" ? <ProgressBar /> : null}
          </>
        }
      </Modal.Body>
      <Modal.Footer className="justify-content-end">
        <div className="d-flex justify-content-end">
          <button
            type="button"
            id="submit"
            className="btn btn-red btn mr-2"
            onClick={() => setOpenUploadModal(false)}
          >
            <span className="flex-1">Cancel</span>
          </button>
          <button
            type="button"
            id="submit"
            className="btn btn-green btn"
            onClick={() => shareFile()}
            disabled={loader}
          >
            <span className="flex-1">{loader ? "Please Wait" : "Share"}</span>
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default UploadFile;
